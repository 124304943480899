import Swiper from 'swiper/dist/js/swiper.min.js';

function promoProductSlider() {
    let $promoProductSlider = $('.js-product-promo-slider');

    $promoProductSlider.each(function(i, slider) {
        let $slider = $(slider),
            $promoProductSliderContainer = $slider.children('.swiper-container'),
            $promoProductSliderArrowPrev = $promoProductSliderContainer.children('.swiper-arrows').find('.swiper-button-prev'),
            $promoProductSliderArrowNext = $promoProductSliderContainer.children('.swiper-arrows').find('.swiper-button-next'),
            $slideProduct = $slider.find('.product');

        const promoProductSliderObj = new Swiper($promoProductSliderContainer, {
            slidesPerView: 'auto',
            spaceBetween: 0,
            speed: 700,
            navigation: {
                nextEl: $promoProductSliderArrowNext,
                prevEl: $promoProductSliderArrowPrev,
            },
        });

        // хак для появления превьюшек в слайдере поверх табов из-за отступа
        $slideProduct.hover(function() {
            $(this).closest('.js-product-promo-slider').addClass('is-hover-product');
        }, function() {
            $(this).closest('.js-product-promo-slider').removeClass('is-hover-product');
        });
    });
}

export default promoProductSlider;