<template>
  <div class="picture-preview-slider js-picture-preview-slider" :data-product-offer-id="currentOffer.id">
    <div data-slider="main" class="picture-preview-slider__main">
      <div class="picture-preview-slider__main-container swiper-container">
        <div class="picture-preview-slider__main-wrapper swiper-wrapper">
          <a v-for="image in currentOffer.product_images" :href="'/products/' + currentOffer.slug" class="picture-preview-slider__main-picture swiper-slide" :key="image.id">
            <img :src="image.image.thumb.url" />
          </a>
        </div>

        <div class="picture-preview-slider__main-arrow picture-preview-slider__main-arrow_prev swiper-button-min swiper-button-prev" v-if="!isSingleImage"></div>
        <div class="picture-preview-slider__main-arrow picture-preview-slider__main-arrow_next swiper-button-min swiper-button-next" v-if="!isSingleImage"></div>
      </div>
    </div>


    <div data-slider="thumbs" class="picture-preview-slider__colors is-center" >
      <div class="picture-preview-slider__colors-container swiper-container">
        <div class="picture-preview-slider__colors-wrapper swiper-wrapper" v-if="!isSingleOffer">
          <div class="picture-preview-slider__colors-item"
               v-for="(offer, index) in product.product_offers"
               :key="offer.id"
               @click="changeOffer(index)">
            <span class="color-type color-type_size_xs" 
                  v-bind:style="{ backgroundColor: offer.primary_color }">
              <span class="color-type__color-second"
                    v-if="offer.secondary_color"
                    v-bind:style="{ backgroundColor: offer.secondary_color }">
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['product', 'currentOffer'],

  methods: {
    changeOffer(index) {
      this.$emit('changeOffer', index)
    },
  },

  computed: {
    currentImage() {
      return this.currentOffer.product_images[this.currentImageIndex]
    },

    isSingleImage() {
      if (this.currentOffer && this.currentOffer.product_images) {
        return this.currentOffer.product_images.length == 1
      }
    },

    isSingleOffer() {
      return this.product.product_offers.length == 1
    }
  },
}
</script>