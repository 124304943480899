import Vue from 'vue';

Vue.component('modal-video', {
    data() {
        return {
            modalShow: false,
        };
    },
    mounted() {
        this.$root.$on('openModalVideo', (tab) => {
            this.render(tab);
        });
    },
    methods: {
        render(tab) {
            let html = document.querySelector('html');

            this.modalShow = true;

            html.classList.add('is-open-mobile-panel');
            html.dataset.animation = 'slideOutLeft';
        },
        close() {
            let html = document.querySelector('html');

            this.modalShow = false;

            html.classList.remove('is-open-mobile-panel');
            html.dataset.animation = 'slideInLeft';

            setTimeout(function() {
                html.removeAttribute('data-animation');
            }, 1000);
        },
    },
    template:
    `<transition name="slide-right" enter-active-class='is-animated slideInRight' leave-active-class='is-animated slideOutRight'>
        <div v-if="modalShow" class="popup-wrapper popup-modal">
            <div class="popup-wrapper__container">
                <div class="popup-wrapper__content">
                    <div class="popup-close" @click="close()"><svg class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg></div>  
                    
                    <div class="popup popup-video">
                        <div class="popup-video__body">
                            <div class="popup-video__embed js-video">
                                <div class="popup-video__embed-cover">
                                    <img src="img/temp/video.jpg" alt="" />
                                    <button type="button" class="play-button"></button>
                                </div>
                                <iframe data-src="https://www.youtube.com/embed/lvxC0MzdRfk?autoplay=1" frameborder="0" allowfullscreen></iframe>
                            </div>
                            <div class="popup-video__product">
                                <div class="product">
                                  <div class="product__inner">
                                      <div class="product__badges">
                                          <div class="product__badges-item product-rating-badge">
                                              <span class="product-rating-badge__mark">4.5</span>
                                              <span class="product-rating-badge__reviews">21 отзыв</span>
                                          </div>
                                      </div>

                                      <div class="product__picture">
                                        <div class="picture-preview-slider js-picture-preview-slider">
                                            <div data-slider="main" class="picture-preview-slider__main">
                                                <div class="picture-preview-slider__main-container swiper-container">
                                                    <div class="picture-preview-slider__main-wrapper swiper-wrapper">
                                                          <a href="#" class="picture-preview-slider__main-picture swiper-slide">
                                                              <img src="img/contant/Stokke-Xplory.jpg" alt="" />
                                                          </a>
                                                          <a href="#" class="picture-preview-slider__main-picture swiper-slide">
                                                              <img src="img/contant/IMG_8188-2.jpg" alt="" />
                                                          </a>
                                                          <a href="#" class="picture-preview-slider__main-picture swiper-slide">
                                                              <img src="img/contant/1.jpg" alt="" />
                                                          </a>
                                                          <a href="#" class="picture-preview-slider__main-picture swiper-slide">
                                                              <img src="img/contant/6.jpg" alt="" />
                                                          </a>
                                                          <a href="#" class="picture-preview-slider__main-picture swiper-slide">
                                                              <img src="img/contant/IMG_8188-2.jpg" alt="" />
                                                          </a>
                                                    </div>

                                                    <div class="picture-preview-slider__main-arrow picture-preview-slider__main-arrow_prev swiper-button-min swiper-button-prev"></div>
                                                    <div class="picture-preview-slider__main-arrow picture-preview-slider__main-arrow_next swiper-button-min swiper-button-next"></div>
                                                </div>
                                            </div>

                                            <div data-slider="thumbs" class="picture-preview-slider__colors is-center">
                                                <div class="picture-preview-slider__colors-container swiper-container">
                                                    <div class="picture-preview-slider__colors-wrapper swiper-wrapper">
                                                        <div class="picture-preview-slider__colors-item swiper-slide">
                                                            <span class="color-type color-type_size_xs" style="background-color: #ba1d23"></span>
                                                        </div>
                                                        <div class="picture-preview-slider__colors-item swiper-slide">
                                                            <span class="color-type color-type_size_xs" style="background-color: #1c4fba"></span>
                                                        </div>
                                                        <div class="picture-preview-slider__colors-item swiper-slide">
                                                            <span class="color-type color-type_size_xs" style="background-color: #baaf89"></span>
                                                        </div>
                                                        <div class="picture-preview-slider__colors-item swiper-slide">
                                                            <span class="color-type color-type_size_xs" style="background-image: url(img/contant/7.png)"></span>
                                                        </div>
                                                        <div class="picture-preview-slider__colors-item swiper-slide">
                                                            <span class="color-type color-type_size_xs" style="background-color: #ba1d23">
                                                                <span class="color-type__color-second" style="background-color: #000"></span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                          <a href="#" class="product__button-cart button button_theme_default button_size_m_upper">В корзине</a>
                                      </div>

                                      <div class="product__info">
                                          <div class="product__subcategory">Автокресло 0+ (до 13 кг)</div>
                                          <a href="#" class="product__name">Bugaboo Donkey2 Mono Classic Complete</a>
                                          <div class="product__prices">
                                              <div class="product__price">80&nbsp;480 ₽</div>
                                          </div>
                                      </div>

                                      <div class="product__availability">
                                          <span class="product__availability-color"></span>В наличии 7 цветов
                                      </div>

                                      <div class="product__hidden-info">
                                          <div class="product__controls">
                                              <button type="button" class="button button_theme_default button_size_m">Купить</button>
                                              <button type="button" class="button button_type_text_icon">
                                                  <svg width="14" height="14" class="icon icon-compare"><use xlink:href="#icon-compare"></use></svg>К сравнению
                                              </button>
                                          </div>
                                      </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>`,
});