import Swiper from 'swiper/dist/js/swiper.min.js';

function pictureProductDetailSlider() {
    let $pictureProductDetailSlider = $('.js-picture-product-detail-slider');

    $pictureProductDetailSlider.each(function(i, slider) {
        let $slider = $(slider),
            $sliderMain = $slider.find('[data-slider="main"]'),
            $sliderMainContainer = $sliderMain.find('.swiper-container'),
            $sliderMainPagination = $sliderMain.find('.swiper-pagination'),
            $sliderThumbs = $slider.find('[data-slider="thumbs"]'),
            $sliderThumbsContainer = $sliderThumbs.find('.swiper-container');

        let op_simulateTouch = true,
            op_noSwiping = false;

        if($(window).width() >= 1200) {
            op_simulateTouch = false;
            op_noSwiping = true;
        }


        const sliderThumbsObj = new Swiper($sliderThumbsContainer, {
            spaceBetween: 10,
            slidesPerView: 'auto',
            freeMode: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            direction: 'vertical',
        });

        const sliderMainObj = new Swiper($sliderMainContainer, {
            slidesPerView: 1,
            spaceBetween: 0,
            speed: 700,
            preloadImages: false,
            lazy: {
                loadPrevNext: true,
                loadPrevNextAmount: 1,
            },
            pagination: {
                el: $sliderMainPagination,
                type: 'fraction',
                renderFraction: function (currentClass, totalClass) {
                    return '<span class="' + currentClass + '"></span>' + ' из ' + '<span class="' + totalClass + '"></span>';
                },
            },
            simulateTouch: op_simulateTouch,
            noSwiping: op_noSwiping,
            thumbs: {
                swiper: sliderThumbsObj,
            },
            breakpointsInverse: true,
            breakpoints: {
                1200: {
                    pagination: false,
                },
            },
        });
    });
}

export default pictureProductDetailSlider;