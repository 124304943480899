import Vue from 'vue';
import axios from 'axios';

Vue.component('modal-catalog-navigation', {
    data() {
        return {
            id: null,
            m_showModalCatalogNavigation: false,
            items: [],
            category_id: null,
            parent_category_title: ''
        };
    },
    beforeMount() {
        this.uniqID();
    },
    mounted() {
        this.$root.$on('changeModalCatalog', (category_id, parent_category) => {
            this.category_id = category_id
            this.parent_category = parent_category
            this.render();
        });
    },
    methods: {
        uniqID() {
            this.id = Math.random().toString(36).substr(2, 9);
        },
        render() {
            let _this = this,
                html = document.querySelector('html');

            this.fetchCategories()
            this.m_showModalCatalogNavigation = true;

            _this.$nextTick(function () {
                html.classList.add('is-open-modal-'+_this.id);
                html.dataset.animation = 'slideOutLeft';
            });
        },
        close() {
            this.m_showModalCatalogNavigation = false;

            let html = document.querySelector('html');

            html.classList.remove('is-open-modal-' + this.id);
            html.dataset.animation = 'slideInLeft';

            setTimeout(function() {
                html.removeAttribute('data-animation');
            }, 1000);
        },
        fetchCategories() {
            axios.get('/api/categories', {
                params: {
                    category_id: this.category_id
                }
            }).then((response) => {
                this.items = response.data
            }).catch((error) => {})
        },
    },
    template:
        `<transition name="slide-right" enter-active-class='is-animated slideInRight' leave-active-class='is-animated slideOutRight'>
        <div v-if="m_showModalCatalogNavigation" class="popup-wrapper popup-modal" style="z-index: 400;">
            <div class="popup-wrapper__container">
                <div class="popup-wrapper__content">
                    <div class="popup-close" @click="close()"><svg class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg></div>  
                    <div class="popup-top-panel" @click="close()">
                        <div class="popup-back-link"></div>
                        <div class="popup-top-panel__text">{{ parent_category.title }}</div>
                    </div>
                    <nav class="mobile-panel-navigation">
                        <ul class="mobile-panel-navigation__list">
                            <li v-for="(item, index) in items" :key="index" class="mobile-panel-navigation__item">
                                <a :href="'/categories/' + item.slug" class="mobile-panel-navigation__link mobile-panel-navigation__link_inline">
                                    <span class="mobile-panel-navigation__link-text">{{ item.title }}</span>
                                    <span class="mobile-panel-navigation__link-count">{{ item.products_count }}</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
        </transition>`,
});