import Vue from 'vue';
import axios from 'axios';
import VueRecaptcha from 'vue-recaptcha';

Vue.component('modal-review-form', {
    data() {
        return {
            id: null,
            showModalReviewForm: false,
            captcha_token: '',
            newProductReview: {
                star: '',
                advantages: '',
                disadvantages: '',
                comment: '',
                name: ''
            },
            errors: {}
        };
    },
    components: {
        'vue-recaptcha': VueRecaptcha
    },
    beforeMount() {
        window.addEventListener('keyup', this.onEscapeKeyUp);
        this.uniqID();
    },
    computed: {
        product_name: function() {
            return document.querySelector("h1").textContent
        },
        product_image: function() {
            return document.querySelector(".picture-product-detail__main-slider-picture img").getAttribute("src")
        }
    },
    beforeDestroy () {
        window.removeEventListener('keyup', this.onEscapeKeyUp);
    },
    mounted() {
        this.$root.$on('openReviewForm', () => {
            this.render();
        });
    },
    methods: {
        uniqID() {
            this.id = Math.random().toString(36).substr(2, 9);
        },
        render() {
            let html = document.querySelector('html');
            html.classList.add('is-open-modal-'+this.id);

            this.showModalReviewForm = true;
        },
        close() {
            let html = document.querySelector('html');
            html.classList.remove('is-open-modal-'+this.id);

            this.showModalReviewForm = false;
        },
        onEscapeKeyUp (event) {
            if (event.which === 27) {
                this.close();
            }
        },
        resetRecaptcha () {
          this.$refs.recaptcha.reset() // Direct call reset method
        },

        saveProduct () {
            if (this.captcha_token == '') {
                alert('Вы не разгадали капчу')
                return
            }

            axios.post(window.location.pathname + "/product_reviews", {
                product_review: this.newProductReview,
                captcha_token: this.captcha_token
            }).then((response) => {
                this.resetForm()
                this.close()
                alert("Спасибо за отзыв!\nЕго опубликуют после модерации!");
            }).catch((error) => {
                this.errors = error.response.data.errors
                this.resetRecaptcha()
            })
        },

        resetForm() {
            this.newProductReview = {
                star: '',
                advantages: '',
                disadvantages: '',
                comment: '',
                name: ''
            }
        },

        onVerify: function (response) {
            this.captcha_token = response
        },

        onExpired: function () {
            this.captcha_token = ''
        },
    },
    template:
        `<div v-if="showModalReviewForm" class="popup-wrapper">
            <div class="popup-wrapper__container">
                <div class="popup-wrapper__content">
                    <div class="popup-close" @click="close()"><svg class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg></div>
                    <div class="popup popup_reviews">
                        <div class="popup__heading">
                            Оставить отзыв
                        </div>
                        
                        <form class="review-form-add" @submit.prevent="saveProduct()">
                            <div class="review-form-add__rate review-form-rate review-form-rate_box">
                                <div class="review-form-rate__heading">
                                    {{product_name}}
                                </div>
                            
                                <div class="review-form-rate__pic">
                                    <img :src="product_image" alt=""/>
                                </div>
            
                                <div class="review-form-rate__stars">
                                    <fieldset class="rating">
                                        <input type="radio" id="star5" name="rating" value="5" v-model="newProductReview.star" />
                                        <label class="full" for="star5" title="great - 5 stars">
                                            <svg class="icon icon-star"><use xlink:href="#icon-star"></use></svg>
                                        </label>
                                        <input type="radio" id="star4" name="rating" value="4" v-model="newProductReview.star" />
                                        <label class="full" for="star4" title="good - 4 stars">
                                            <svg class="icon icon-star"><use xlink:href="#icon-star"></use></svg>
                                        </label>
                                        <input type="radio" id="star3" name="rating" value="3" v-model="newProductReview.star" />
                                        <label class="full" for="star3" title="ordinary - 3 stars">
                                            <svg class="icon icon-star"><use xlink:href="#icon-star"></use></svg>
                                        </label>
                                        <input type="radio" id="star2" name="rating" value="2" v-model="newProductReview.star" />
                                        <label class="full" for="star2" title="bad - 2 stars">
                                            <svg class="icon icon-star"><use xlink:href="#icon-star"></use></svg>
                                        </label>
                                        <input type="radio" id="star1" name="rating" value="1" v-model="newProductReview.star" />
                                        <label class="full" for="star1" title="terrible - 1 star">
                                            <svg class="icon icon-star"><use xlink:href="#icon-star"></use></svg>
                                        </label>
                                    </fieldset>
                                    <div>
                                        <span v-for="error in errors.star" style="color: red;">{{error}}</span>
                                    </div>
                                </div>
                            </div>
            
                            <div class="review-form-add__form-data form-data">
                                <div class="form-data__heading">Напишите ваши <br>впечатления</div>
                                <div class="form-field">
                                    <label class="custom-label">Достоинства</label>
                                    <textarea name="" class="custom-textarea theme-default" placeholder="Что вам понравилось?" v-model="newProductReview.advantages"></textarea>
                                    <span v-for="error in errors.advantages" style="color: red;">{{error}}</span>
                                </div>
            
                                <div class="form-field">
                                    <label class="custom-label">Недостатки</label>
                                    <textarea name="" class="custom-textarea theme-default" placeholder="Что не понравилось?" v-model="newProductReview.disadvantages"></textarea>
                                    <span v-for="error in errors.disadvantages" style="color: red;">{{error}}</span>
                                </div>
            
                                <div class="form-field">
                                    <label class="custom-label">Комментарий</label>
                                    <textarea name="" class="custom-textarea theme-default" placeholder="Другие впечатления" v-model="newProductReview.comment"></textarea>
                                    <span v-for="error in errors.comment" style="color: red;">{{error}}</span>
                                </div>

                                <div class="form-field">
                                    <vue-recaptcha 
                                        sitekey="6LdCUzMcAAAAAFKeD3P-_0tL5YGSvOGf0QOycLH4"
                                        ref="recaptcha"
                                        @verify="onVerify"
                                        @expired="onExpired"></vue-recaptcha>
                                </div>
                                
                                <div class="form-field-row form-field-row_valign-bottom">
                                    <div class="form-field">
                                        <label class="custom-label">Ваше имя</label>
                                        <input type="text" class="custom-input theme-default" placeholder="Представьтесь" name="" v-model="newProductReview.name">
                                        <span v-for="error in errors.name" style="color: red;">{{error}}</span>
                                    </div>
                                    
                                    <button class="button button_theme_default button_size_m">Отправить</button>
                                </div>
            
                            </div>
                        </form>
                       
                    </div>
                </div>
            </div>
        </div>`,
});