import Swiper from 'swiper/dist/js/swiper.min.js';

function productViewGallery(idPhoto) {
    let $productViewGallery = $('.js-product-view-gallery');

    $productViewGallery.each(function(i, slider) {
        let $slider = $(slider),
            $sliderMain = $slider.find('[data-slider="main"]'),
            $sliderMainContainer = $sliderMain.find('.swiper-container'),
            $sliderThumbs = $slider.find('[data-slider="thumbs"]'),
            $sliderThumbsContainer = $sliderThumbs.find('.swiper-container'),
            $sliderMainArrowPrev = $sliderMain.find('.swiper-button-prev'),
            $sliderMainArrowNext = $sliderMain.find('.swiper-button-next');

        let indexElement = $sliderMain.find('[data-id-slide="'+ idPhoto +'"]').index();

        let op_direction = 'horizontal',
            op_simulateTouch = true,
            op_noSwiping = false;

        if($(window).width() >= 1200) {
            op_direction = 'vertical';
            op_simulateTouch = false;
            op_noSwiping = true;
        }

        const sliderThumbsObj = new Swiper($sliderThumbsContainer, {
            spaceBetween: 12,
            slidesPerView: 'auto',
            freeMode: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            direction: op_direction,
            freeModeSticky: true,
        });

        const sliderMainObj = new Swiper($sliderMainContainer, {
            slidesPerView: 1,
            spaceBetween: 0,
            simulateTouch: op_simulateTouch,
            noSwiping: op_noSwiping,
            speed: 700,
            thumbs: {
                swiper: sliderThumbsObj,
            },
            keyboard: {
                enabled: true,
                onlyInViewport: false,
            },
            navigation: {
                nextEl: $sliderMainArrowNext,
                prevEl: $sliderMainArrowPrev,
            },
        });

        if(indexElement == '-1') {
            sliderMainObj.slideTo(0, 0);
        } else {
            sliderMainObj.slideTo(indexElement, 0);
        }
    });
}

export default productViewGallery;