import Vue from 'vue';
import tabsSlider from '../../components/tabsSlider';
import finder from '../../components/finder';

Vue.component('m-modal-finder', {
    data() {
        return {
            modalShow: false,
        };
    },
    mounted() {
        this.$root.$on('m_openModalFinder', (tab) => {
            this.render(tab);
            setTimeout(() => {
                tabsSlider();
                finder();
            });
        });
    },
    methods: {
        render(tab) {
            let html = document.querySelector('html');

            this.modalShow = true;

            html.classList.add('is-open-mobile-panel');
            html.dataset.animation = 'slideOutLeft';
        },
        close() {
            let html = document.querySelector('html');

            this.modalShow = false;

            html.classList.remove('is-open-mobile-panel');
            html.dataset.animation = 'slideInLeft';

            setTimeout(function() {
                html.removeAttribute('data-animation');
            }, 1000);
        },
        m_openModalCitiesInit() {
            /*
            *
            * Метод открытия модального окна из компонента m_modalAllCity
            * */

            this.$root.$emit('m_openAllCity');
        },
    },
    template:
    `<transition name="slide-right" enter-active-class='is-animated slideInRight' leave-active-class='is-animated slideOutRight'>
        <div v-if="modalShow" class="popup-wrapper popup-modal">
            <div class="popup-wrapper__container">
                <div class="popup-wrapper__content">
                    <div class="popup-close" @click="close()"><svg class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg></div>  
                    
                    <div class="popup-top-panel" @click="close()">
                        <div class="popup-back-link"></div>
                        <div class="popup-top-panel__text">Самовывоз из магазина</div>
                    </div>
                    
                    <div class="popup popup_delivery">
                        <div class="finder">
                          <div class="finder__header">
                            <div class="finder__header-location">
                              <div class="select-light js-catalog-sort">
                                <select name="SORT_PRODUCTS" class="select-light__input" style="display: none;">
                                  <option value="1">в Санкт-Петербурге</option>
                                  <option value="2">в Санкт-Петербурге</option>
                                  <option value="3">в Санкт-Петербурге</option>
                                </select>
                                <div class="nice-select select-light__input" tabindex="0"><span class="current">в Санкт-Петербурге</span>
                                  <ul class="list">
                                    <li data-value="1" class="option selected">в Санкт-Петербурге</li>
                                    <li data-value="2" class="option">в Санкт-Петербурге</li>
                                    <li data-value="3" class="option">в Санкт-Петербурге</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="tabs-slider js-tabs-slider">
                            <div class="tabs-slider__nav">
                              <div class="tabs-slider__nav-list">
                                <div data-line="" class="tabs-slider__nav-active-bar" style="width: 68.3906px; left: 0px;"></div>
                                <div data-toggle="tabs-finder-modal-list" class="tabs-slider__nav-item is-active">Списком</div>
                                <div data-toggle="tabs-finder-modal-map" class="tabs-slider__nav-item">На карте</div>
                              </div>
                            </div>
                            <div data-id="tabs-finder-modal-list" class="tabs-slider__slider is-active">
                              <div class="finder__shop">
                                <div class="finder__shop-photo"><img src="img/finder/sedova.jpg" alt=""></div>
                                <div class="finder__shop-main">
                                  <div class="finder__shop-main-address">ТОЦ «Эврика»
                                    <br><span>ул. Седова, 11, 3 этаж</span></div>
                                  <div class="finder__shop-main-metro">
                                    <div class="finder__shop-main-metro-line" style="background: rgb(20, 204, 131);"></div>
                                    <div class="finder__shop-main-metro-station">Елизаровская</div>
                                  </div>
                                  <a href="" class="finder__shop-main-map">
                                    <svg width="15" height="20" class="icon icon-pin">
                                      <use xlink:href="#icon-pin"></use>
                                    </svg> <b>На карте</b></a>
                                </div>
                                <div class="finder__shop-hours"> С 11 до 20 <span>Ежедневно</span></div>
                                <div class="finder__shop-phone"> (812) 309-78-92 <span>Салон колясок, автокресел и мебели</span></div>
                                <div class="finder__shop-call"><a href="tel:(812) 309-78-92" class="button button_size_m button_theme_ghost_dark"><span class="hidden-b-m">Заказать звонок</span> <span class="hidden-a-m">Позвонить</span></a></div>
                              </div>
                              <div class="finder__shop">
                                <div class="finder__shop-photo"><img src="img/finder/engelsa.jpg" alt=""></div>
                                <div class="finder__shop-main">
                                  <div class="finder__shop-main-address">ТМ «Светлановский»
                                    <br><span>пр. Энгельса, 33</span></div>
                                  <div class="finder__shop-main-metro">
                                    <div class="finder__shop-main-metro-line" style="background: rgb(25, 118, 210);"></div>
                                    <div class="finder__shop-main-metro-station">Удельная</div>
                                  </div>
                                  <a href="" class="finder__shop-main-map">
                                    <svg width="15" height="20" class="icon icon-pin">
                                      <use xlink:href="#icon-pin"></use>
                                    </svg> <b>На карте</b></a>
                                </div>
                                <div class="finder__shop-hours"> С 11 до 21 <span>Ежедневно</span></div>
                                <div class="finder__shop-phone"> (812) 309-34-46 <span>Салон колясок, автокресел и мебели</span></div>
                                <div class="finder__shop-call"><a href="tel:(812) 309-34-46" class="button button_size_m button_theme_ghost_dark"><span class="hidden-b-m">Заказать звонок</span> <span class="hidden-a-m">Позвонить</span></a></div>
                              </div>
                            </div>
                            <div data-id="tabs-finder-modal-map" class="tabs-slider__slider">
                              <div data-placemark="img/placemark.svg" class="finder__map"></div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>`,
});