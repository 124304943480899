import Vue from 'vue';
import vClickOutside from 'v-click-outside';
import noUiSlider from '../../vendors/nouislider';
import wNumb from '../../vendors/wNumb';
import axios from 'axios';
import picturePreviewSlider from '../../components/picturePreviewSlider';
import lazyImages from '../../components/lazy';
import FilterParamsService from '../../services/catalog/filter_params_service';
import selectSortProductsCatalog from "../../components/selectSortProductsCatalog"


Vue.component('filter-parametr-price', {
    props: ['name', 'valName', 'min', 'max', 'step', 'parametrName'],
    directives: {
        clickOutside: vClickOutside.directive,
    },
    data() {
        return {
            showDropdown: false,
            minRange: null,
            maxRange: null,
            slider: {
                startMin: this.min,
                startMax: this.max,
                min: this.min,
                max: this.max,
                step: this.step,
                valueText: '',
            },
        };
    },
    mounted() {
        this.initUiSlider()
        let filterParamsService = new FilterParamsService
        let params = filterParamsService.params

        if (params['min_price'] != undefined && params['max_price'] != undefined) {
            this.minRange = params['min_price']
            this.maxRange = params['max_price']
            this.slider.valueText = ': ' + this.minRange + ' - ' + this.maxRange + ' ' + this.valName;
        }        
    },
    methods: {
        openDropdown() {
            let _this = this;

            _this.showDropdown = !_this.showDropdown;
        },
        close() {
            this.showDropdown = false;
        },
        initUiSlider() {
            noUiSlider.create(this.$refs.slider, {
                start: [parseInt(this.slider.startMin), parseInt(this.slider.startMax)],
                step: parseInt(this.slider.step),
                range: {
                    'min': parseInt(this.slider.min),
                    'max': parseInt(this.slider.max),
                },
                connect: true,
                format: wNumb({
                    decimals: 0,
                    suffix: ' ₽',
                }),
                animate: true,
                animationDuration: 300,
            });

            this.$refs.slider.noUiSlider.on('update',(values, handle) => {
                this[handle ? 'maxRange' : 'minRange'] = parseInt(values[handle]);
            });
        },
        updateSlider: function() {
            this.$refs.slider.noUiSlider.set([this.minRange, this.maxRange]);
        },
        applyFilter: function() {
            this.slider.valueText = ': ' + this.minRange + ' - ' + this.maxRange + ' ' + this.valName;
            this.close();

            this.$parent.$refs.modalFilter.addPriceFilter(this.minRange, this.maxRange)
        },

        resetFilter: function() {
            this.lightReset()
            this.$parent.$refs.modalFilter.removePriceFilters()
        },

        lightReset() {
            this.minRange = this.min;
            this.maxRange = this.max;

            this.updateSlider();

            this.slider.valueText = '';

            this.close();
        },
    },
    template:
        `<div v-click-outside="close" class="filter-parametr-price" :class="{'is-fulled':slider.valueText}">
            <div class="filter-parametr-price__current">
                <div @click="openDropdown()" class="filter-parametr-price__current-value">{{ name }}<span v-if="slider.valueText">{{slider.valueText}}</span></div>
                
                <div @click="resetFilter()" class="filter-parametr-price__reset">
                    <svg width="10" height="10" class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg>
                </div>
            </div>
    
            <div v-show="showDropdown" class="filter-parametr-price__dropdown dropdown-filter-list dropdown-filter-list_theme_light">
                <div class="dropdown-filter-list__main">
                    <div class="slider-ui">
                        <div class="slider-ui__slide">
                            <div class="slider-ui__value">
                                <div class="slider-ui__value-row">
                                    <div class="slider-ui__value-box slider-ui__value-box_min">
                                        <span class="slider-ui__value-box-hint">от</span>
                                        <input data-name="min" type="text" class="slider-ui__value-input" :name="parametrName+'_MIN'" v-model="minRange" v-on:change="updateSlider">
                                    </div>
                                    
                                    <div class="slider-ui__value-box slider-ui__value-box_max">
                                        <span class="slider-ui__value-box-hint">до</span>
                                        <input data-name="max" type="text" class="slider-ui__value-input" :name="parametrName+'_MAX'" v-model="maxRange" v-on:change="updateSlider">
                                    </div>
                                </div>
                                
                                <span class="slider-ui__value-text">{{ valName }}</span>
                            </div>
                            
                            <div ref="slider" data-action="slick" class="slider-ui__slick"></div>
                        </div>
                    </div>
                </div>
    
                <div class="dropdown-filter-list__footer">
                    <button @click.prevent="applyFilter()" class="button button_theme_default button_size_s" type="button">Применить</button>
                </div>
            </div>
        </div>`,
});