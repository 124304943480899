import Vue from 'vue';

Vue.component('m-product-detail-description', {
    data() {
        return {

        };
    },
    methods: {

    },
    //template:``,
});