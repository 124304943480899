import Swiper from 'swiper/dist/js/swiper.min.js';

function catalogSectionNavigation() {
    let $catalogSectionNavigation = $('.js-catalog-section-navigation');

    $catalogSectionNavigation.each(function(i, slider) {
        let $slider = $(slider),
            $sliderContainer = $slider.find('.swiper-container'),
            $sliderWrapper = $slider.find('.swiper-wrapper'),
            $sliderScroll = $slider.find('.swiper-scrollbar'),
            $element = $slider.find('[data-element="item"]'),
            $togglerBtn = $slider.find('[data-action="toggler"]'),
            $togglerBtnCount = $slider.find('[data-element="count"]'),
            slideCount = $element.length,
            slideCountAll = 0,
            sliderObj = undefined;

        if(slideCount > 8) {
            slideCountAll = slideCount - 7;
            $togglerBtnCount.text('('+slideCountAll+')');
        }


        function initNav() {
            $element.each(function(i, item) {
                if(i < 7) {
                    $(item).addClass('is-show');
                }
            });

            let $elementShow = $slider.find('[data-element="item"].is-show');

            $elementShow.each(function(i, item) {
                if (i % 4 === 0) {
                    $elementShow.slice(i, i+4).wrapAll('<div class="swiper-slide"></div>');
                }
            });
        }

        initNav();

        $(document).on('click', '[data-action="toggler-nav"]', function(e) {
            e.preventDefault();

            if($slider.hasClass('is-opened')) {
                console.log(2);

                $slider.removeClass('is-opened');
                $('html').removeClass('is-opened-navigation-catalog');

                $sliderWrapper.html($element);

                initNav();

                sliderObj.destroy();
                sliderObj = undefined;
            } else {
                console.log(1);

                $slider.addClass('is-opened');
                $('html').addClass('is-opened-navigation-catalog');

                $sliderWrapper.html($element);

                for(let i = 0; i < $element.length; i++) {
                    if (i % 4 === 0) {
                        $element.slice(i, i+4).wrapAll('<div class="swiper-slide"></div>');
                    }
                }

                sliderObj = new Swiper($sliderContainer, {
                    slidesPerView: 'auto',
                    spaceBetween: 48,
                    freeMode: true,
                    mousewheel: {
                        eventsTarged: $slider,
                        sensitivity: 5,
                    },
                    scrollbar: {
                        el: $sliderScroll,
                    },
                });
            }
        });
    });
}

export default catalogSectionNavigation;
