function fixedBox() {
    let fixedBox = document.querySelector('.js-fixed-box');

    function fixedBoxFunction() {
        if(fixedBox && $(window).width() > 1199) {
            let fixElement = fixedBox.querySelectorAll('[data-fixed="element"]');

            for (let i = 0; i < fixElement.length; i++) {
                let item = fixElement[i],
                    windowHeight = window.innerHeight,
                    scrollWindow = window.pageYOffset,
                    offsetTopFixedBox = fixedBox.getBoundingClientRect().top + pageYOffset,
                    topOffset = offsetTopFixedBox + fixedBox.offsetHeight,
                    maxOffset = parseInt(scrollWindow + item.offsetHeight + 230);

                if(offsetTopFixedBox <= scrollWindow) {
                    item.classList.add('is-fixed-panel');

                    if (topOffset <= maxOffset) {
                        item.classList.add('is-fixed-panel-end');
                    } else {
                        item.classList.remove('is-fixed-panel-end');
                    }

                } else {
                    item.classList.remove('is-fixed-panel', 'is-fixed-panel-end');
                }
            }
        }
    }

    document.addEventListener("DOMContentLoaded", function () {
        fixedBoxFunction();
    });

    window.addEventListener('resize', function() {
        fixedBoxFunction();
    });

    window.addEventListener('scroll', function() {
        fixedBoxFunction();
    });
}

export default fixedBox;
