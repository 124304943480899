<template>
  <div class="feedback" v-if="!success">
    <div class="feedback__header">
      <h2>Форма обращения в сервисный центр</h2>
    </div>
    <form id="service_task">
    <div class="feedback__form">
      <div class="feedback__form-fieldset">
        <div class="feedback__form-fieldset-info">
          <div class="form-field">
            <label class="custom-label">ФИО (для юр.лиц название организации)*:</label>
            <input type="text" name="service_task[name]" class="custom-input theme-default" v-model='service_task.name'>
            <span v-for="error in errors.name" style="color: red;">{{error}}</span>
          </div>
          <div class="form-field">
            <label class="custom-label">Ваш телефон (обязательно проверьте правильность данных)*:</label>
            <input type="text" name="service_task[phone]" class="custom-input theme-default" v-model='service_task.phone'>
            <span v-for="error in errors.phone" style="color: red;">{{error}}</span>
          </div>
          <div class="form-field">
            <label class="custom-label">Ваш email (обязательно проверьте правильность данных)*:</label>
            <input type="text" name="service_task[email]" class="custom-input theme-default" v-model='service_task.email'>
            <span v-for="error in errors.email" style="color: red;">{{error}}</span>
          </div>
          <div class="form-field">
            <label class="custom-label">Место покупки*:</label>
            <input type="text" name="service_task[place_of_purchase]" class="custom-input theme-default" v-model='service_task.place_of_purchase'>
            <span v-for="error in errors.place_of_purchase" style="color: red;">{{error}}</span>
          </div>
          <div class="form-field">
            <label class="custom-label">Город*:</label>
            <input type="text" name="service_task[city]" class="custom-input theme-default" v-model='service_task.city'>
            <span v-for="error in errors.city" style="color: red;">{{error}}</span>
          </div>
          <div class="form-field">
            <label class="custom-label">Дата покупки*:</label>
            <input type="text" name="service_task[date_of_perchase]" class="custom-input theme-default" v-model='service_task.date_of_perchase'>
            <span v-for="error in errors.date_of_perchase" style="color: red;">{{error}}</span>
          </div>
          <div class="form-field">
            <label class="custom-label">Файлы*:</label>
            <input type="file" multiple="true" name="service_task[service_task_attachments_attributes][][image]">
            <span v-for="error in errors.date_of_perchase" style="color: red;">{{error}}</span>
          </div>
        </div>
        <div class="feedback__form-fieldset-message">
          <div class="form-field">
            <label class="custom-label">Ваше сообщение*:</label>
            <textarea name="service_task[message]" class="custom-textarea theme-default" v-model="service_task.message"></textarea>
            <span v-for="error in errors.message" style="color: red;">{{error}}</span>
          </div>
        </div>
      </div>
      <div class="feedback__form-submit">
        <label class="custom-checkbox">
          <input type="checkbox" value="Y" name="RULE_FEEDBACK_QUESTION" class="custom-checkbox__input" v-model='agree'>
          <span class="custom-checkbox__value">
            <span class="custom-checkbox__icon"></span>
            Даю свое согласие на <a href="#" target="_blank">обработку персональных данных</a>
          </span>
          <span style="color: red;">{{ agree_error }}</span>
        </label>

        <label class="custom-checkbox">
          <vue-recaptcha 
            sitekey="6LdCUzMcAAAAAFKeD3P-_0tL5YGSvOGf0QOycLH4"
            ref="recaptcha"
            @verify="onVerify"
            @expired="onExpired"></vue-recaptcha>
        </label>

        <button type="button" @click.prevent="sendForm" class="button button_theme_default button_size_ms">
          <span class="hidden-b-m">Отправить</span>
        </button>
      </div>
    </div>
    </form>
  </div>
  <div v-else>
    <h3 style="text-align: center;">Ваше обращение принято!</h3>

    <p style="text-align: center;">
      Обращение рассматривается в течение 3-х рабочих дней.
    </p>
  </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import VueRecaptcha from 'vue-recaptcha';

export default {
  data() {
    return {
      service_task: {
        name: '',
        phone: '',
        message: '',
        email: '',
        place_of_purchase: '',
        city: '',
        date_of_perchase: ''
      },
      captcha_token: '',
      agree: false,
      agree_error: '',
      errors: {},
      success: false
    }
  },

  components: {
    'vue-recaptcha': VueRecaptcha
  },

  methods: {
    sendForm: function() {
      if (!this.agree) { 
        this.agree_error = 'Нужно согласиться на обработку персональных даннных'
        return
      } else {
        this.agree_error = ''
      }

      if (this.captcha_token == '') {
        alert('Вы не разгадали капчу')
        return
      }

      let form = document.querySelector('#service_task')
      let data = new FormData(form)

      data.append('g-recaptcha-response', this.captcha_token)

      axios.post('/api/service_tasks', data).then((response) => {
        this.success = true
      }).catch((errors) => {
        this.errors = errors.response.data.errors
        this.resetRecaptcha()
      })
    },

    onVerify: function (response) {
      this.captcha_token = response
    },

    onExpired: function () {
      this.captcha_token = ''
    },

    resetRecaptcha () {
      this.$refs.recaptcha.reset() // Direct call reset method
    },
  },
}
</script>