<template>
  <div>
    <div class="catalog__grid">
      <product-preview v-for="product in products"
                       :key="product.id" 
                       :product="product"
                       :favorite="isFavorite(product.id)"
                       :compared="isCompared(product.id)"></product-preview>
    </div>
  </div>
</template>

<script>
import { deserialize } from 'deserialize-json-api';
import axios from 'axios'
import picturePreviewSlider from '../../components/picturePreviewSlider'
import picturePreviewSlider2 from '../../components/picturePreviewSlider2'
import productPreview from "./productPreview.vue"

export default {
  props: ['api_url'],

  data() {
    return {
      products: [],
      favoriteIds: [],
      compareIds: [],
      pagination: {},
      responseUrl: ''
    }
  },

  mounted() {
    this.favoriteIds = gon.favorite_ids
    this.compareIds = gon.compare_ids
    this.loadProducts()

    window.addEventListener('scroll', this.eventLoadNextPage)
  },

  components: {
    'product-preview': productPreview
  },

  methods: {
    eventLoadNextPage() {
      let catalogContainer = $('.catalog__grid')
      let point = catalogContainer.height() - 700

      if (window.scrollY > point) {
        if (this.pagination.pages == undefined) { return }

        if (this.pagination.current + 1 == this.pagination.pages.length) {
          window.removeEventListener('scroll', this.eventLoadNextPage)
        } else {
          window.removeEventListener('scroll', this.eventLoadNextPage)
          this.loadNextPage()
          setTimeout(() => {
            window.addEventListener('scroll', this.eventLoadNextPage)
          }, 300)
        }
      }
    },

    isCurrentPage(value) {
      return this.pagination.current == value
    },

    loadNextPage() {
      this.pagination.current = this.pagination.current + 1

      axios.get(this.pagination.pages[this.pagination.current]).then((response) => {
        this.appendProducts(response.data)
      })
    },

    loadProducts() {
      let url = this.api_url === undefined ? window.location.href : this.api_url

      if (url.search(/\?/) > 0) {
        url = url.replace('?', '.json?')
      } else {
        url = url + '.json'
      }

      axios.get(url).then((response) => {
        this.products = deserialize(response.data).data
        this.pagination = response.data.links
        this.responseUrl = response.request.responseURL

        setTimeout(function() {
          picturePreviewSlider()
        }, 400)

      }).catch((error) => {})
    },

    reloadProducts(payload) {
      let data = deserialize(payload).data
      this.products = data
    },

    appendProducts(payload) {
      let data = deserialize(payload).data
      this.products = this.products.concat(data)

      setTimeout(function() {
        picturePreviewSlider()
      }, 400)
    },

    reloadPagination(links) {
      this.pagination = links
      window.addEventListener('scroll', this.eventLoadNextPage)
    },

    isFavorite(productId) {
      for (let i = 0; i <= this.favoriteIds.length; i++) {
        if (this.favoriteIds[i] == productId) { return true }
      }

      return false
    },

    isCompared(productId) {
      for (let i = 0; i <= this.compareIds.length; i++) {
        if (this.compareIds[i] == productId) { return true }
      }

      return false
    }
  }
}
</script>