function crashTest() {
  $('.crash-test__list-product-summary').on('click', e => {
    $(e.currentTarget)
      .parent()
        .toggleClass('-active')
        .find('.crash-test__list-product-info')
          .slideToggle(200);
  });
}

export default crashTest;