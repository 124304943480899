import Vue from 'vue';
import axios from 'axios';
import VueRecaptcha from 'vue-recaptcha';

Vue.component('modal-feedback-from', {
    data() {
        return {
            id: null,
            showModalFeedbackForm: false,
            feedback: {
                message: '',
                name: '',
                phone: ''
            },
            captcha_token: '',
            errors: {}
        };
    },
    components: { 'vue-recaptcha': VueRecaptcha },
    beforeMount() {
        window.addEventListener('keyup', this.onEscapeKeyUp);
        this.uniqID();
    },
    computed: {
        product_name: function() {
            return document.querySelector("h1").textContent
        },
        product_image: function() {
            return document.querySelector(".picture-product-detail__main-slider-picture img").getAttribute("src")
        }
    },
    beforeDestroy () {
        window.removeEventListener('keyup', this.onEscapeKeyUp);
    },
    mounted() {
        this.$root.$on('openFeedbackModalForm', () => {
            this.render();
        });
    },
    methods: {
        uniqID() {
            this.id = Math.random().toString(36).substr(2, 9);
        },
        render() {
            let html = document.querySelector('html');
            html.classList.add('is-open-modal-'+this.id);

            this.showModalFeedbackForm = true;
        },
        close() {
            let html = document.querySelector('html');
            html.classList.remove('is-open-modal-'+this.id);

            this.showModalFeedbackForm = false;
        },
        onEscapeKeyUp (event) {
            if (event.which === 27) {
                this.close()
            }
        },
        sendFeedback() {
            if (this.captcha_token == '') {
                alert('Вы не разгадали капчу')
                return
            }

            axios.post('/feedbacks', {
                feedback: this.feedback,
                captcha_token: this.captcha_token
            }).then((response) => {
                this.resetForm()
                this.close()
                alert('Ваша заявка успешно отправлена!\nНаши операторы свяжутся с Вами в ближайшее время')
            }).catch((error) => {
                this.errors = error.response.data.errors
                this.$refs.recaptcha.reset()
            })
        },

        onVerify: function (response) {
            this.captcha_token = response
        },

        onExpired: function () {
            this.captcha_token = ''
        },

        resetRecaptcha () {
          this.$refs.recaptcha.reset() // Direct call reset method
        },

        resetForm() {
            this.feedback = {
                message: '',
                name: '',
                phone: ''
            }
        }
    },
    template:
        `<div v-if="showModalFeedbackForm" class="popup-wrapper">
            <div class="popup-wrapper__container">
                <div class="popup-wrapper__content">
                    <div class="popup-close" @click="close()"><svg class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg></div>
                    <div class="popup popup_reviews">
                        <div class="popup__heading">
                            Обратная связь
                        </div>
                        
                        <form class="review-form-add" @submit.prevent="sendFeedback()">
                            <div class="review-form-add__form-data form-data" style="padding-right: 80px;">
                                <div class="form-field">
                                    <label class="custom-label">Сообщение</label>
                                    <textarea style="width: 100%; height: 134px;" 
                                    class="custom-textarea theme-default"
                                    v-model="feedback.message"
                                    required="true"></textarea>
                                    <span v-for="error in errors.message" style="color: red;">{{error}}</span>
                                </div>

                                <div class="form-field-row form-field-row_valign-bottom">
                                    <vue-recaptcha 
                                        sitekey="6LdCUzMcAAAAAFKeD3P-_0tL5YGSvOGf0QOycLH4"
                                        ref="recaptcha"
                                        @verify="onVerify"
                                        @expired="onExpired"></vue-recaptcha>
                                </div>
                                <div class="form-field-row form-field-row_valign-bottom">
                                    <button 
                                        class="button button_theme_default button_size_m"
                                        style="margin-left: 0;">Отправить</button>
                                </div>
                            </div>
                            <div class="review-form-add__form-data form-data">
                                <div class="form-field">
                                    <label class="custom-label">Контактный телефон</label>
                                    <input type="text" 
                                    class="custom-input theme-default" 
                                    v-model="feedback.phone"
                                    required="true">
                                    <span v-for="error in errors.phone" style="color: red;">{{error}}</span>
                                </div>
                                
                                <div class="form-field-row form-field-row_valign-bottom">
                                    <div class="form-field">
                                        <label class="custom-label">Ваше имя</label>
                                        <input type="text" 
                                        class="custom-input theme-default" 
                                        v-model="feedback.name"
                                        required="true">
                                        <span v-for="error in errors.name" style="color: red;">{{error}}</span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>`,
});