import Vue from 'vue';
import axios from 'axios';
Vue.component('search-widget', {
    data() {
        return {
            searchText: '',
            categories: [],
            products: [],
            blockSearch: false,
            modalIsOpen: false
        };
    },
    beforeMount() {
        window.addEventListener('keyup', this.onEscapeKeyUp);
    },
    beforeDestroy () {
        window.removeEventListener('keyup', this.onEscapeKeyUp);
    },
    methods: {
        render() {

        },
        onEscapeKeyUp (event) {
            if (event.which === 27) {
                this.$emit('close');
            }
        },
        search() {
            if (this.searchText.length >= 2 && this.blockSearch == false) {
                this.blockSearch = true
                this.fetchResults()
            } else {
                this.blockSearch = false
                this.modalIsOpen = false
            }
        },
        fetchResults() {
            let searchUrl = `/search.json?search=${this.searchText}`

            axios.get(searchUrl).then( (response) => {
                this.categories = response.data.categories
                this.products = response.data.products
                this.blockSearch = false
                this.modalIsOpen = true
            }).catch((error) => {})
        },
        
    },
    watch: {
            searchText(){
                if (this.searchText.length >= 2 && this.blockSearch == false) {
                    this.blockSearch = true
                    this.fetchResults()
                } else {
                    this.blockSearch = false
                    this.modalIsOpen = false
                }
            }
        },
    template:
        `<div class="search-widget">
            <form action="/search"  class="search-widget__field search-field"> 
                <input type="text" name="search"  v-bind:value="searchText" v-on:input="searchText = $event.target.value"  autocomplete="off" class="search-field__input custom-input theme-light" placeholder="Поиск по сайту" value="">
                <button class="search-field__button">
                    <svg class="icon icon-loupe" width="18" height="18">
                        <use xlink:href='#icon-loupe'></use>
                    </svg>
                </button>
            </form>
            <div class="search-widget__dropdown" v-if="modalIsOpen">
                <div class="search-widget__dropdown-section" v-if="categories.length > 0">
                    <div class="search-widget__dropdown-section-heading">Категории</div>
                    <div class="search-widget__dropdown-section-results">
                        <div class="search-widget__dropdown-section-results-item" v-for="category in categories">
                            <a :href="'/categories/' + category.slug" class="search-widget__dropdown-section-results-link">{{category.title}}</a>
                        </div>
                    </div>
                </div>
                <div class="search-widget__dropdown-section" v-if="products.length > 0">
                    <div class="search-widget__dropdown-section-heading">Товары</div>
                    <div class="search-widget__dropdown-section-results">
                        <div class="search-widget__dropdown-section-results-item" v-for="product in products">
                            <a :href="'/products/' + product.slug" class="search-widget__dropdown-section-results-link">{{ product.title }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>`,
});