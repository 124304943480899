function selectSortProductsCatalog() {
    if (gon.params && gon.params.order) { 
      document.querySelector("[name='SORT_PRODUCTS']").value = gon.params.order
    }
  
    let $selectSort = $('.js-catalog-sort'),
        $selectSortInp = $selectSort.find('select');

    $selectSortInp.niceSelect();
    $selectSort.on('change', (e) => {
      window.app.addOrderToCatalog(e.target.value)
    })

}

export default selectSortProductsCatalog;