import Swiper from 'swiper/dist/js/swiper.min.js';

function compilationsSlider() {
    let $compilationsSlider = $('.js-compilations-slider');

    $compilationsSlider.each(function(i, slider) {
        let $slider = $(slider),
            $sliderContainer = $slider.find('.swiper-container'),
            $sliderArrowPrev = $slider.find('.swiper-button-prev'),
            $sliderArrowNext = $slider.find('.swiper-button-next'),
            $sliderPagination = $slider.find('.swiper-pagination-custom');

        const compilationsSliderObj = new Swiper($sliderContainer, {
            slidesPerView: 'auto',
            spaceBetween: 10,
            freeMode: true,
            freeModeSticky: false,
            navigation: {
                nextEl: $sliderArrowNext,
                prevEl: $sliderArrowPrev,
            },
            pagination: {
                el: $sliderPagination,
                dynamicBullets: true,
            },
            watchOverflow: true,
            breakpointsInverse: true,
            breakpoints: {
                768: {
                    spaceBetween: 20,
                },
                1025: {
                    pagination: false,
                },
            },
        });

    });
}

export default compilationsSlider;