import Vue from 'vue';

Vue.component('m-modal-product-reviews', {
    data() {
        return {
            id: null,
            m_showModalReviews: false,
        };
    },
    beforeMount() {
        this.uniqID();
    },
    mounted() {
        this.$root.$on('m_openReviews', () => {
            this.render();
        });
    },
    methods: {
        uniqID() {
            this.id = Math.random().toString(36).substr(2, 9);
        },
        render() {
            let _this = this,
                html = document.querySelector('html');

            this.m_showModalReviews = true;

            _this.$nextTick(function () {
                let $selectSort = $('.js-reviews-sort select');

                html.classList.add('is-open-modal-'+_this.id);
                html.dataset.animation = 'slideOutLeft';
                _this.$el.dataset.stepAnimation = 'step-1';

                $selectSort.niceSelect();

                console.log($selectSort);
            });
        },
        close() {
            let html = document.querySelector('html');

            html.classList.remove('is-open-modal-' + this.id);
            html.dataset.animation = 'slideInLeft';
            this.$el.removeAttribute('data-step-animation');

            setTimeout(function() {
                html.removeAttribute('data-animation');
            }, 1000);

            this.m_showModalReviews = false;
        },
    },
    template:
        `<transition enter-active-class='is-animated slideInRight' leave-active-class='is-animated slideOutRight'>
            <div v-if="m_showModalReviews" class="popup-wrapper">
                <div class="popup-wrapper__container">
                    <div class="popup-wrapper__content">
                        <div class="popup-close" @click="close()"><svg class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg></div>  
                        <div class="popup-top-panel" @click="close()">
                            <div class="popup-back-link"></div>
                            <div class="popup-top-panel__text">Оставить отзыв</div>
                        </div>   
                        <slot name="body"></slot>
                    </div>
                </div>
            </div>
        </transition>`,
});