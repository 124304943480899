import Swiper from 'swiper/dist/js/swiper.min.js';

function bannersSlider() {
    let $bannerSlider = $('.js-banner-slider');

    $bannerSlider.each(function(i, slider) {
        let $slider = $(slider),
            $sliderContainer = $slider.find('.swiper-container'),
            $slidePagination = $slider.find('.swiper-pagination-banners-slider');

        const bannerSliderObj = new Swiper($sliderContainer, {
            slidesPerView: 'auto',
            spaceBetween: 0,
            speed: 700,
            pagination: {
                el: $slidePagination,
                clickable: true,
                renderBullet(index, className) {
                    index = +index + 1;
                    return `<span class="banners-slider__bullet `+className+`">
                                    0`+ index +
                                `<svg class="progress" width="28" height="28" viewBox="0 0 120 120">
                                    <circle cx="60" cy="60" r="54" fill="none" stroke="none" stroke-width="6"></circle>
                                    <circle class="progress__value" cx="60" cy="60" r="54" fill="none" stroke="#000" stroke-width="4"></circle>
                                </svg>
                            </span>`;
                },
            },
            autoplay: {
                delay: 5000,
                disableOnInteraction: false
            },
        });
    });
}

export default bannersSlider;