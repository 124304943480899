function sectionDropped() {
    let $sectionDropped = $('.js-section-dropped'),
        windowHeight = $(window).height();

    $sectionDropped.each(function(i, section) {
        let $section = $(section),
            $togglerBtn = $section.find('[data-toggler]'),
            togglerBtnViewText = $togglerBtn.text(),
            heightContent = $section.find('[data-drop]').outerHeight();

        if(heightContent > ((windowHeight * 70) / 100)) {
            $section.addClass('is-dropped');
        }

        $togglerBtn.on('click', function(e) {
            e.preventDefault();

            let hideText = $(this).data('hide-text');

            $section.toggleClass('is-opened');

            if($section.hasClass('is-opened')) {
                $section.addClass('is-opened');
                $(this).text(hideText);
            } else {
                $section.removeClass('is-opened');
                $(this).text(togglerBtnViewText);

                $('html, body').animate({
                    scrollTop: $section.offset().top
                }, 800);
            }
        });
    });
}

export default sectionDropped;
