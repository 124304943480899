import Vue from 'vue';
import axios from 'axios';
import accessorizeSlider from "../components/accessorizeSlider";
import { deserialize } from 'deserialize-json-api';

Vue.component('modal-add-cart', {
    data() {
        return {
            id: null,
            showModalAddCart: false,
        };
    },
    beforeMount() {
        window.addEventListener('keyup', this.onEscapeKeyUp);

        this.uniqID();
    },
    beforeDestroy () {
        window.removeEventListener('keyup', this.onEscapeKeyUp);
    },
    mounted() {
        this.$root.$on('openAddCart', (offerId) => {
            axios.post('/line_items', {
                product_offer_id: offerId 
            }).then((response) => {
                this.productOffer = response.data
                this.render(offerId);

                window.dataLayer.push({
                    "ecommerce": {
                        "currencyCode": "RUB",
                        "add" : {
                            "products" : [{
                                id: this.productOffer.articul,
                                name: this.productOffer.title,
                                price: this.productOffer.price,
                                quantity: 1
                            }]
                        }
                    }
                });

                axios.get('/api/carts').then((response) => {
                    let html = response.data
                    $('#cart-preview').html(html)
                })
            }).catch((error) => {
            })
        });
    },
    methods: {
        uniqID() {
            this.id = Math.random().toString(36).substr(2, 9);
        },
        render(offerId) {
            let _this = this,
                html = document.querySelector('html');
            html.classList.add('is-open-modal-' + this.id);

            this.showModalAddCart = true;

            _this.$nextTick(function () {
                accessorizeSlider();
            });
        },
        close() {
            let html = document.querySelector('html');
            html.classList.remove('is-open-modal-' + this.id);

            this.showModalAddCart = false;

            if ($(document).width() < 1200) {
                location.reload()
            };
        },
        onEscapeKeyUp (event) {
            if (event.which === 27) {
                this.close();
            }
        },
    },
    template:
        `<transition name="fade-up" enter-active-class='is-animated fadeInUp' leave-active-class='is-animated fadeOutDown'>
        <div v-if="showModalAddCart" class="popup-wrapper popup-add-cart">
            <div class="popup-wrapper__container">
                <div class="popup-wrapper__content">
                    <button class="popup-close" @click="close()"><svg class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg></button>      
                    <div slot="body" class="popup">
                        <div class="add-cart-box">
                            <div class="add-cart-box__heading">
                                Товар добавлен в корзину!
                            </div>
        
                            <div class="product-detail-main-info product-detail-main-info_add-cart">
                                <div class="product-detail-main-info__picture-product-detail product-detail-main-info__picture-product-detail_single xs-hide">
                                    <img :src="productOffer.image" alt=""/>
                                </div>
                                
                                <div class="product-detail-product-info">
                                    <div class="product-detail-category">{{ productOffer.product.category.title }}</div>
                                </div>
        
                                <div class="product-detail-main-info__name">{{ productOffer.product.title }}</div>
        
                                <div class="product-detail__sale-box product-detail-sale-box">
                                    <div class="product-detail-prices">
                                        <div class="product-detail-prices__price product-detail-prices__price_default">{{ productOffer.price }} &#8381;</div>
                                        <div class="product-detail-prices__price product-detail-prices__price_old">{{ productOffer.old_price }} &#8381;</div>
                                    </div>
        
                                    <div class="product-detail-delivery xs-hide">
                                        <div class="product-detail-delivery__item">
                                            <a href="#" class="product-detail-delivery__item-link">{{ productOffer.available_warehouses_message }}</a>
                                        </div>
                                    </div>
                                    <br />
                                    <div class="product-detail-info-links xs-hide">
                                        <div class="product-detail-info-links__item">
                                            <a href="#" class="product-detail-info-links__link">
                                                Гарантия 1 год
                                            </a>
                                        </div>
                                    </div>
                                </div>
        
                                <div class="product-detail-summary xs-hide">
                                    <!-- Descriptions -->
                                    <div class="product-detail-descriptions" v-html="productOffer.product.short_description"></div>
        
                                    <table class="product-detail-summary__table-options table-options">
                                        <tr>
                                            <td>Страна бренда:</td>
                                            <td>Норвегия</td>
                                        </tr>
                                        <tr>
                                            <td>Страна-производитель:</td>
                                            <td>Китай</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
        
                            <div class="add-cart-box__controls">
                                <a @click="close()" class="button button_theme_ghost_dark button_size_m xs-hide">Продолжить покупки</a>
                                <a href="/cart" class="button button_theme_default button_size_m">Перейти в корзину</a>
                            </div>
                            
                            <div class="add-cart-box__heading" v-if="productOffer.product.product_accessories.length > 0">К этой модели часто покупают</div>
                            <div class="accessorize-slider js-accessorize-slider" v-if="productOffer.product.product_accessories.length > 0">
                                <div class="accessorize-slider__container swiper-container">
                                    <div class="accessorize-slider__wrapper swiper-wrapper">
                                        <div class="accessorize-slider__slide swiper-slide">
                                            <div class="product-card-light">
                                                <a href="#" class="product-card-light__picture">
                                                    <img src="img/contant/8.jpg" alt=""/>
                                                </a>
    
                                                <div class="product-card-light__info">
                                                    <a href="#" class="product-card-light__name">Конверт в коляску Bugaboo функциональный 2018</a>
    
                                                    <div class="product-card-light__prices">
                                                        <span class="product-card-light__price">15 890 &#8381;</span>
                                                    </div>
    
                                                    <a href="#" class="product-card-light__button-cart button button_theme_ghost_default button_size_m">
                                                        Купить
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="accessorize-slider__arrows swiper-arrows">
                                    <div class="swiper-arrow-circle swiper-arrow-circle_prev swiper-button-prev">
                                        <svg width="16" height="12" class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                                    </div>
                                    <div class="swiper-arrow-circle swiper-arrow-circle_next swiper-button-next">
                                        <svg width="16" height="12" class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </transition>`,
});