import Vue from 'vue';
import axios from 'axios';

Vue.component('cart', {
    data() {
        return {
            line_items: [],
            current_user: {},
            cart: {},
            message: '',
            current_city: gon.current_city,
            promocode: {
                code: ''
            }
        };
    },
    mounted() {
        this.cart = gon.cart
        this.line_items = gon.cart.line_items
        this.block_cart = false
        this.current_user = gon.current_user
        this.message = gon.message
        console.log(gon.cart.line_items)
    },
    methods: {
        applyPromocode() {
            axios.post("/apply_promocodes", {
                promocode: this.promocode
            }).then((response) => {
                this.cart = response.data.cart
                this.message = response.data.message
                this.line_items = response.data.cart.line_items
            }).catch((error) => {
                console.log(error.response.data)
            })
        },

        applySocialCard() {
            axios.post("/apply_social_cards", {}).then((response) => {
                this.cart = response.data.cart
                this.message = response.data.message
                this.line_items = response.data.cart.line_items
            }).catch((error) => {
            })
        },

        removeItem: function(index, line_item) {
            this.block_cart = true

            axios.delete(`/line_items/${line_item.id}`).then((response) => {
                let cart = response.data.cart;
                this.line_items.splice(index, 1)
                this.cart.total_price = cart.total_price
                this.cart.old_total_price = cart.old_total_price
                this.block_cart = false

                window.dataLayer.push({
                    "ecommerce": {
                        "currencyCode": "RUB",
                        "remove" : {
                            "products" : [{
                                id: line_item.articul,
                                name: line_item.title,
                                price: line_item.price,
                                quantity: line_item.quantity
                            }]
                        }
                    }
                });

                axios.get('/api/carts').then((response) => {
                    let html = response.data
                    $('#cart-preview').html(html)
                })
            }).catch((error) => {
                this.block_cart = false
            })
        },

        incrementQuantity(index, line_item) {
            if (this.block_cart) { return }

            let oldQuantity = this.line_items[index].quantity
            let newQuantity = oldQuantity + 1
            this.block_cart = true
            axios.patch("/line_items/" + line_item.id, { 
                quantity: newQuantity 
            }).then((response) => {
                this.recount(index, response.data.cart)
                this.line_items[index].quantity = newQuantity
                this.block_cart = false

                window.dataLayer.push({
                    "ecommerce": {
                        "currencyCode": "RUB",
                        "add" : {
                            "products" : [{
                                id: line_item.articul,
                                name: line_item.title,
                                price: line_item.price,
                                quantity: 1
                            }]
                        }
                    }
                });

                console.log(window.dataLayer)
            }).catch((error) => {
                this.block_cart = false
                this.line_items[index].quantity = oldQuantity
                alert('Извините, но указанное количество товара в данный момент недоступно.')
            })
        },

        decrementQuantity(index, line_item) {
            if (this.block_cart) { return }

            let oldQuantity = this.line_items[index].quantity
            if (oldQuantity <= 1) { return }

            let newQuantity = this.line_items[index].quantity = oldQuantity - 1

            this.block_cart = true
            axios.patch("/line_items/" + line_item.id, { 
                quantity: newQuantity 
            }).then((response) => {
                this.recount(index, response.data.cart)
                this.block_cart = false

                window.dataLayer.push({
                    "ecommerce": {
                        "currencyCode": "RUB",
                        "remove" : {
                            "products" : [{
                                id: line_item.articul,
                                name: line_item.title,
                                price: line_item.price,
                                quantity: 1
                            }]
                        }
                    }
                });

                console.log(window.dataLayer)
            }).catch((error) => {
                this.block_cart = false
            })  
        },

        recount(index, cart) {
            let currentLineItem = this.line_items[index];
            // Пересчитать корзину обычного пользователя
            this.line_items[index].total_price = currentLineItem.price * currentLineItem.quantity;
            this.cart.total_price = cart.total_price;
            this.cart.old_total_price = cart.old_total_price

            // Пересчитать корзину оптовика
            this.line_items[index].opt_total_price = currentLineItem.opt_price * currentLineItem.quantity;
            this.cart.opt_total_price = cart.opt_total_price;

            // Обновить превьюху корзины
            axios.get('/api/carts').then((response) => {
                console.log('hi')
                let html = response.data
                $('#cart-preview').html(html)
            })
        },

        refreshItem: function(productID) {
            let $item = $(this.$el).find('[data-id="'+ productID +'"]');

            $item.removeClass('is-removed');
        }
    },
    template:
        `<div class="cart">
        <div class="cart__container custom-container">
            <a href="/cart" class="cart__button-clear" data-method="delete">
                <svg class="icon icon-cross" width="13" height="13">
                    <use xlink:href='#icon-cross'></use>
                </svg>
                Очистить
            </a>

            <div class="cart__info">
                <div class="cart__equipment">
                    <div v-for="(line_item, index) in line_items" :data-id="line_item.id" class="cart__equipment-item equipment-product">
                        <a href="#" class="equipment-product__pic">
                            <img :src="line_item.image" alt=""/>
                        </a>

                        <div class="equipment-product__main">
                            <div class="equipment-product__main-top">
                                <div class="equipment-product__info">
                                    <div class="equipment-product__category">{{ line_item.category }}</div>
                                    <a :href="'/products/' + line_item.product_offer_slug" class="equipment-product__name">
                                        {{ line_item.title }}
                                    </a>
                                    <div v-if="line_item.product_offer_available_for_order == true">
                                        Данного товара пока нет в наличии на складах, но на него можно оформить предзаказ. Стандартный срок выполнения предзаказа от 45 календарных дней
                                    </div>
                                    <div v-if="line_item.size">
                                        Размер: {{ line_item.size }}
                                    </div>
                                </div>

                                <div class="equipment-product__prices">
                                    <div class="equipment-product__price">
                                        <div v-if="current_user.role == 'saler'" class="equipment-product__price-value">{{ line_item.opt_price }} &#8381;</div>
                                        <div v-else class="equipment-product__price-value">{{ line_item.price }} &#8381;</div>
                                        <div class="equipment-product__price-value equipment-product__price-value_old" v-if="line_item.old_total_price && line_item.old_total_price > line_item.total_price">{{ line_item.old_price }} &#8381;</div>
                                    </div>

                                    <div class="equipment-product__quantities-actions">
                                        <div v-if="current_user.role == 'saler' "class="equipment-product__price-summ">{{ line_item.opt_total_price }} &#8381;</div>
                                        <div v-else class="equipment-product__price-summ">{{ line_item.total_price }} &#8381;</div>

                                        <div class="equipment-product__quantity quantity">
                                            <button class="quantity__btn quantity__btn_min" type="button" @click.prevent="decrementQuantity(index, line_item)">
                                                <svg class="icon icon-minus" width="8" height="2">
                                                    <use xlink:href='#icon-minus'></use>
                                                </svg>
                                            </button>
                                            <input type="text" :value="line_item.quantity" class="quantity__input" readonly>
                                            <button class="quantity__btn quantity__btn_max" type="button" @click.prevent="incrementQuantity(index, line_item)">
                                                <svg class="icon icon-plus" width="8" height="8">
                                                    <use xlink:href='#icon-plus'></use>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>

                                    <div class="equipment-product__notice notice notice_success" v-if="line_item.old_total_price && line_item.old_total_price > line_item.total_price">
                                        <div class="notice__icon">
                                            <span class="c-icon-percent">%</span>
                                        </div>
                                        <div class="notice__text">
                                            Сейчас товар можно купить со скидкой <b>-{{ line_item.sale_in_percent }}%</b>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>

                        <button @click.prevent="removeItem(index, line_item)" class="equipment-product__btn equipment-product__btn_remove">
                            <svg class="icon icon-cross" width="13" height="13">
                                <use xlink:href='#icon-cross'></use>
                            </svg>
                        </button>

                        <button @click.prevent="refreshItem(1)" class="equipment-product__btn equipment-product__btn_refresh">
                            <svg class="icon icon-update" width="17" height="17">
                                <use xlink:href='#icon-update'></use>
                            </svg>
                        </button>
                    </div>
                </div>

                <div class="cart__order">
                    <div class="cart__discount" v-if="current_city.name == 'Санкт-Петербург'">
                        <form @submit.prevent="applyPromocode" action="" class="cart__discount-form-box form-data-inline">
                            <label class="custom-checkbox">
                                <input @change="applySocialCard" v-model="cart.social_card" type="checkbox" value="Y" name="SOCIAL_CARD_PAY" class="custom-checkbox__input">
                                <span class="custom-checkbox__value">
                                    <span class="custom-checkbox__icon"></span>
                                    Оплатить социальной картой (только для Санкт-Петербурга)
                                </span>
                            </label>
                            <p style="color: red;" v-if="message">{{ message }}</p>
                        </form>
                    </div>

                    <div class="order-summ">
                        <div class="order-summ__heading">Итого:</div>
                        <div class="order-summ__prices">
                            <span v-if="current_user.role == 'saler'" class="order-summ__price order-summ__price_default">{{ cart.opt_total_price }} &#8381;</span>
                            <span v-else class="order-summ__price order-summ__price_default">{{ cart.total_price }} &#8381;</span>
                            <span class="order-summ__price order-summ__price_old" v-if="cart.old_total_price && cart.old_total_price > cart.total_price">{{ cart.old_total_price }} &#8381;</span>
                        </div>
                    </div>

                    <button class="cart__order-button button button_size_l button_theme_default"
                        @click="window.location.replace('/orders/new')">Оформить заказ</button>
                </div>
            </div>
        </div>
    </div>`,
});