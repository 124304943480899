import ymaps from 'ymaps';
import Swiper from 'swiper/dist/js/swiper.min.js';

function shop() {
    function initMap() {
        const mapHolder = document.querySelector('.shop__map');

        if (!mapHolder) {
            return;
        }

        function getMapCenter(pointCoords) {
            return [
                parseFloat(pointCoords[0], 10) + 0.00095,
                parseFloat(pointCoords[1], 10) + 0.01022,
            ];
        }

        ymaps.load('https://api-maps.yandex.ru/2.1/?lang=ru_RU').then(maps => {
            const coords = mapHolder.dataset.coords.split(',');
            const map = new maps.Map(mapHolder, {
                center: getMapCenter(coords),
                zoom: 15,
                controls: [],
            });

            const point = new maps.Placemark(coords, {}, {
                iconLayout: 'default#image',
                iconImageHref: mapHolder.dataset.placemark,
                iconImageSize: [40, 40],
                iconImageOffset: [-20, -40],
                iconImageClipRect: [[40, 40], [80, 0]],
            });

            map.geoObjects.add(point);
        });
    }

    function initSliderMain() {
        let $compilationsSlider = $('.js-shop-slider_main');

        $compilationsSlider.each(function(i, slider) {
            let $slider = $(slider),
                $sliderContainer = $slider.find('.swiper-container'),
                $sliderArrowPrev = $slider.find('.swiper-button-prev'),
                $sliderArrowNext = $slider.find('.swiper-button-next'),
                $sliderPagination = $slider.find('.swiper-pagination-fraction');

            const compilationsSliderObj = new Swiper($sliderContainer, {
                slidesPerView: 'auto',
                navigation: {
                    nextEl: $sliderArrowNext,
                    prevEl: $sliderArrowPrev,
                },
                pagination: {
                    el: $sliderPagination,
                    type: 'fraction',
                    renderFraction: (currentClass, totalClass) => `<span class="${currentClass}"></span> из <span class="${totalClass}"></span>`,
                },
                watchOverflow: true,
                breakpointsInverse: true,
            });

        });
    }

    function initSliderTour() {
        let $sliders = $('.js-shop-slider_tour');

        $sliders.each(function(i, slider) {
            let $slider = $(slider),
                $sliderContainer = $slider.find('.swiper-container'),
                $sliderArrowPrev = $slider.find('.swiper-button-prev'),
                $sliderArrowNext = $slider.find('.swiper-button-next'),
                $sliderPagination = $slider.find('.swiper-pagination-custom');

            const actionsPromoSliderObj = new Swiper($sliderContainer, {
                slidesPerView: 'auto',
                freeMode: true,
                freeModeSticky: false,
                navigation: {
                    nextEl: $sliderArrowNext,
                    prevEl: $sliderArrowPrev,
                },
                pagination: {
                    el: $sliderPagination,
                    dynamicBullets: true,
                },
                watchOverflow: true,
            });
        });
    }

    initMap();
    initSliderMain();
    initSliderTour();
}

export default shop;
