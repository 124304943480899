import Vue from 'vue';
import vClickOutside from 'v-click-outside';

Vue.component('filter-parametr', {
    props: ['name', 'search', 'params'],
    directives: {
        clickOutside: vClickOutside.directive,
    },
    data() {
        return {
            showDropdown: false,
            checkedParams: [],
            valueText: '',
        };
    },
    methods: {
        openDropdown() {
            let _this = this;

            _this.showDropdown = !_this.showDropdown;
        },
        close() {
            this.showDropdown = false;
        },
        applyFilter() {
            let values = '',
                count = 0,
                countText = '';

            values = this.checkedParams.slice(0, 2).join(', ');
            count = this.checkedParams.length - 2;

            if(count > 0) {
                countText = ', еще ' + count;
            }

            this.valueText = ': ' + values + countText;

            this.close();
        },
        resetFilter() {
            this.checkedParams = [];
            this.valueText = '';

            this.close();
        },
    },
    template:
        `<div v-click-outside="close" class="filter-parametr" :class="{'is-search':search === 'Y', 'is-fulled':valueText}">
            <div class="filter-parametr__current">
                <div @click="openDropdown()" class="filter-parametr__current-value">{{ name }}<span v-if="valueText">{{valueText}}</span></div>
                <div @click.prevent="resetFilter()" class="filter-parametr__reset">
                    <svg width="10" height="10" class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg>
                </div>
            </div>
            
            <div v-show="showDropdown" class="filter-parametr__dropdown dropdown-filter-list">
                <div v-if="search === 'Y'" class="dropdown-filter-list__header">
                    <div class="dropdown-filter-list__search">
                        <input type="text" placeholder="Поиск" name="">
                    </div>
                </div>
                
                <div class="dropdown-filter-list__main">
                    <label v-for="item in params" class="custom-checkbox">
                        <input :type="item.type" :value="item.value" :name="item.parametrName" v-model="checkedParams" class="custom-checkbox__input">
                        <span class="custom-checkbox__value">
                            <span class="custom-checkbox__icon"></span>{{ item.name }}
                        </span>
                    </label>
                </div>
                
                <div class="dropdown-filter-list__footer">
                    <button @click.prevent="applyFilter()" class="button button_theme_default button_size_s" type="button">Применить</button>
                </div>
            </div>
        </div>`,
});