class Pluralizer {
  constructor(values = [], count) {
    if (!Array.isArray(values) || values.length != 3) { 
      console.log('Первый аргумент должен быть массивом из трёх элементов')
    }

    [this.one, this.few, this.many] = values
    this.count = count
  }

  call(newCount = null) {
    this.count = newCount == null ? this.count : newCount
    return this.pluralize()
  }

  pluralize() {
    let tail

    tail = this.count % 100

    if ([11, 12, 13, 14].includes(tail)) {
      return this.many
    }

    tail = this.count % 10

    if (tail == 1) {
      return this.one
    }

    if ([2, 3, 4].includes(tail)) {
      return this.few
    }

    return this.many
  }
}

export default Pluralizer