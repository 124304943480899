<template>
    <div class="accessorize-slider__slide swiper-slide">
        <div class="product-card-light">
            <a :href="'/products/' + currentOffer.slug" class="product-card-light__picture">
                <img :src="currentOffer.product_images[0].image.thumb.url" alt=""/>
            </a>

            <div class="product-card-light__info">
                <a :href="'/products/' + currentOffer.slug" class="product-card-light__name">
                    {{ currentOffer.title }}
                </a>

                <div class="product-card-light__prices">
                    <span class="product-card-light__price">{{ rolePrice(currentOffer) }} &#8381;</span>
                </div>

                <a v-if="addedToCart" href="/cart" class="product-card-light__button-cart button button_theme_ghost_default button_size_m">
                    В корзину
                </a>
 
                <a v-else-if="currentOffer.available_status == 'available'" 
                    @click="addToCart(currentOffer.id)" class="product-card-light__button-cart button button_theme_ghost_default button_size_m">
                    Купить
                </a>

                <a v-else-if="currentOffer.available_status == 'only_salon'" 
                class="product-card-light__button-cart button button_theme_ghost_default button_size_m">
                    Только в салоне
                </a>

                <a v-else-if="currentOffer.available_status == 'available_to_order'" class="product-card-light__button-cart button button_theme_ghost_default button_size_m">
                    Только предзаказ
                </a>

                <a v-else class="product-card-light__button-cart button button_theme_ghost_default button_size_m">
                    Нет в наличии
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['product'],
    data() {
        return {
            addedToCart: false
        }
    },
    computed: {
        currentOffer() {
            return this.product.product_offers[0]
        },
    },
    mounted() {
        this.addedToCart = this.$root.isAddToCart(this.currentOffer.id)
        console.log(this.currentOffer.available_status)
    },
    methods: {
        rolePrice(productOffer) {
            return productOffer.price
        },

        addToCart() {
            this.$root.showModalAddCartInit(this.currentOffer.id)
            setTimeout(() => {
                this.addedToCart = true
            }, 300)
        }
    }
}
</script>