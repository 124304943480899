function anchorNavigation() {
    let $anchorNavigation = $('.js-anchor-navigation'),
        $link = $anchorNavigation.find('a[href]'),
        $linkSingleAnchor = $('.js-single-anchor'),
        $sections = $('[data-section]');

    if($(window).width() > 1199) {
        $(window).scroll(function() {
            let currentScroll = $(this).scrollTop(),
                $currentSection;

            $sections.each(function() {
                $currentSection = null;

                let divPosition = $(this).offset().top - 50;

                if(divPosition - divPosition * .02 < currentScroll) {
                    $currentSection = $(this);
                }

                if( $currentSection ) {
                    var id = $currentSection.attr('data-section');
                    $link.removeClass('cur');
                    $anchorNavigation.find('a[href="#'+id+'"]').addClass('cur');
                }
            });
        });
    }

    function clickAnchor(context) {
        $('html, body').animate({
            scrollTop: $('[data-section="' + $.attr(context, 'href').substr(1) + '"]').offset().top - 20
        }, 500);

        $link.removeClass('cur');
        $(context).addClass('cur');

        return false;
    }

    $link.on('click', function (e) {
        e.preventDefault();

        clickAnchor(this);
    });

    $linkSingleAnchor.on('click', function (e) {
        e.preventDefault();

        clickAnchor(this);
    });

}

export default anchorNavigation;
