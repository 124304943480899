function video() {
    let videos = document.querySelectorAll('.js-video');

    for (let i = 0; i < videos.length; i++) {
      videos[i].addEventListener('click',function(){
        let video = this.querySelector('iframe'),
        url = video.getAttribute('data-src');

          video.setAttribute('src', url);
          this.classList.add('is-play');
      })
      
    }
}

export default video;