import Vue from 'vue/dist/vue.min.js';

Vue.component('city-location', {
    data() {
        return {
            activeCities: gon.active_cities,
            cityName: gon.current_city_name,
            cityPanelShow: false,
        };
    },
    beforeMount() {
        window.addEventListener('keyup', this.onEscapeKeyUp);
    },
    beforeDestroy () {
        window.removeEventListener('keyup', this.onEscapeKeyUp);
    },
    mounted() {
        this.$root.$on('openCityLocationPanel', () => {
            this.render();
        });
    },
    methods: {
        render() {
            let html = document.querySelector('html');

            this.cityPanelShow = true;

            html.classList.add('is-open-panel-city');
            html.dataset.animation = 'slideDownCity';
        },
        close() {
            let _this = this,
                html = document.querySelector('html');

            this.cityPanelShow = false;

            html.classList.remove('is-open-panel-city');
            html.dataset.animation = 'slideTopCity';

            setTimeout(function() {
                html.removeAttribute('data-animation');
            }, 1000);
        },
        onEscapeKeyUp (event) {
            if (event.which === 27) {
                this.close();
            }
        },
        openAllCityModalMobile() {
            this.$root.m_showModalAllCityInit()
        },
        openAllCityModal() {
            this.$root.showModalAllCityInit()
        }
    },
    template:
        `<transition name="slide-up" enter-active-class='is-animated slideUpReturn' leave-active-class='is-animated slideUp'>
            <div v-if="cityPanelShow" class="city-location">
                <div @click="close()" class="city-location__close">
                    <svg width="13" height="13" class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg>
                </div>
            
                <div class="city-location__container custom-container">
                    <div class="city-location__main">
                        <div class="city-location__current-city">
                            Ваш город — {{cityName}}?
                        </div>
                        <div class="city-location__hint">От города зависит наличие товаров, сроки и условия доставки</div>
                        <a href="#" class="button button_theme_default button_size_m" @click="close()">
                            Да<span class="xs-hide">, вы угадали</span>
                        </a>
                        <a href="#" @click="openAllCityModalMobile()" class="button button_theme_ghost_dark button_size_m mdx-hide">Выбрать свой город</a>
                    </div>
            
                    <div class="city-location__info">
                        <div class="city-location__info-title">Выберите свой город</div>
                        <ul class="city-location__cities">
                            <li class="city-location__cities-item" v-for="city in activeCities">
                                <a :href="'/change_city/' + city.name" class="city-location__cities-link">{{ city.name }}</a>
                            </li>
                        </ul>
            
                        <a href="#" @click="openAllCityModal" class="button button_theme_ghost_dark button_size_m">Выбрать другой город</a>
                    </div>
                </div>
            </div>
        </transition>`,
});