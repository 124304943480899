import Vue from 'vue';

Vue.component('option-switch', {
    props: ['name', 'option', 'check'],
    data() {
        return {
            checked: this.check,
        };
    },
    methods: {
    },
    template:
        `<div class="option-switch">
            <div class="option-switch__text">
                {{name}}
                <div v-if="option.hintText" data-position="right" class="tooltip-info">
                    <span class="tooltip-info__icon">?</span> 
                    <span class="tooltip-info__content">{{option.hintText}}</span>
                </div>
            </div>
        
            <label class="custom-switch">
                <input type="checkbox" value="Y" :name="option.parametrName" v-model="checked">
                <span class="custom-switch__slider"></span>
            </label>
        </div>`,
});