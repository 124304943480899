import Vue from 'vue';

Vue.component('filter-mobile-parametr-color', {
    props: ['name', 'params'],
    data() {
        return {
            showDropdown: false,
            count: 0,
            values: '',
            list: this.params,
            checkedElements: [],
        };
    },
    computed: {
        ischecked: function () {
            return this.list.filter(function (i) {
                return i.checked === true;
            });
        },
        unchecked: function () {
            return this.list.filter(function (i) {
                return i.checked === false;
            });
        },
    },
    methods: {
        open() {
            let _this = this;

            _this.showDropdown = !_this.showDropdown;
        },
        close() {
            this.showDropdown = false;
        },
        applyFilter() {
            this.values = this.ischecked.slice(0, 2);
            this.count = this.ischecked.length - 2;

            this.showDropdown = false;
        },
        resetFilter() {
            this.values = '';
            this.count = 0;

            for(let i = 0; this.list.length > i; i++) {
                this.list[i].checked = false;
            }
        },
    },
    template:
        `<div class="filter-mobile-parametr filter-mobile-parametr_type-lists">
            <button @click.prevent="open()" type="button" class="filter-mobile-parametr__head">
                <span class="filter-mobile-parametr__head-name">{{name}}</span>
                <span v-if="values" class="filter-mobile-parametr__head-values">
                    <span class="filter-mobile-parametr__head-values-colors">
                        <span v-for="color in values" class="filter-mobile-parametr__head-values-colors-item">
                            <span v-if="color.colors.length == 1" class="color-type color-type_size_xs" :style="{ backgroundColor: '#' + color.colors[0] }"></span>
                                    
                            <span v-if="color.colors.length == 2" class="color-type color-type_size_xs" :style="{ backgroundColor: '#' + color.colors[0] }">
                                <span class="color-type__color-second" :style="{ backgroundColor: '#' + color.colors[1] }"></span>
                            </span>
                            
                            <span v-if="color.colors.length == 3" class="color-type color-type_size_xs" :style="{ backgroundColor: '#' + color.colors[0] }">
                                <span class="color-type__color-second" :style="{ backgroundColor: '#' + color.colors[1] }"></span>
                                <span class="color-type__color-third" :style="{ backgroundColor: '#' + color.colors[2] }"></span>
                            </span>
                        </span>
                    </span>
                    <span v-if="count > 0" class="filter-mobile-parametr__head-values-count">(еще {{count}})</span>
                </span>
            </button>
            
            <div v-show="showDropdown" class="filter-mobile-parametr__layout-panel">
                <div @click="close()" class="filter-mobile-parametr__layout-panel-header">
                    <span class="filter-mobile-parametr__layout-panel-header-title">{{name}}</span>
                </div>
                
                <div v-if="ischecked.length > 0" data-list="uses" class="filter-mobile-parametr__layout-panel-list" :class="{'is-default':ischecked.length > 0}">
                    <div class="filter-mobile-parametr__layout-panel-list-header">
                        <div class="filter-mobile-parametr__layout-panel-list-header-title">Уже используются</div>
                        <button @click.prevent="resetFilter()" type="button" class="filter-mobile-parametr__reset">
                            <svg width="8" height="8" class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg>Сбросить
                        </button>
                    </div>
                    
                    <!-- Список с выбранными позициями -->
                    <div v-for="(item, index) in list" :key="index" v-if="item.checked" class="filter-mobile-parametr__layout-panel-parametr">
                        <label class="custom-checkbox-color">
                            <input :type="item.type" :value="item" :name="item.parametrName" :data-colors="item.colors" v-model="item.checked" 
                            class="custom-checkbox-color__input">
                            <span class="custom-checkbox-color__value">
                                <span class="custom-checkbox-color__icon">
                                    <span v-if="item.colors.length == 1" class="color-type color-type_size_md" :style="{ backgroundColor: '#' + item.colors[0] }"></span>
                                    
                                    <span v-if="item.colors.length == 2" class="color-type color-type_size_md" :style="{ backgroundColor: '#' + item.colors[0] }">
                                        <span class="color-type__color-second" :style="{ backgroundColor: '#' + item.colors[1] }"></span>
                                    </span>
                                    
                                    <span v-if="item.colors.length == 3" class="color-type color-type_size_md" :style="{ backgroundColor: '#' + item.colors[0] }">
                                        <span class="color-type__color-second" :style="{ backgroundColor: '#' + item.colors[1] }"></span>
                                        <span class="color-type__color-third" :style="{ backgroundColor: '#' + item.colors[2] }"></span>
                                    </span>
                                </span>
                                
                                {{ item.name }}
                            </span>
                        </label>
                    </div>
                </div>
                
                <!-- Список со всеми позициями -->
                <div data-list="others" class="filter-mobile-parametr__layout-panel-list" :class="{'is-default':ischecked.length > 0}">
                    <div class="filter-mobile-parametr__layout-panel-list-header">
                        <div class="filter-mobile-parametr__layout-panel-list-header-title">Остальные</div>
                    </div>
                    
                    <div v-for="(item, index) in list" :key="index" v-if="!item.checked" class="filter-mobile-parametr__layout-panel-parametr">
                        <label class="custom-checkbox-color">
                            <input :type="item.type" :value="item" :name="item.parametrName" :data-colors="item.colors" v-model="item.checked"
                            class="custom-checkbox-color__input">
                            <span class="custom-checkbox-color__value">
                                <span class="custom-checkbox-color__icon">
                                    <span v-if="item.colors.length == 1" class="color-type color-type_size_md" :style="{ backgroundColor: '#' + item.colors[0] }"></span>
                                    
                                    <span v-if="item.colors.length == 2" class="color-type color-type_size_md" :style="{ backgroundColor: '#' + item.colors[0] }">
                                        <span class="color-type__color-second" :style="{ backgroundColor: '#' + item.colors[1] }"></span>
                                    </span>
                                    
                                    <span v-if="item.colors.length == 3" class="color-type color-type_size_md" :style="{ backgroundColor: '#' + item.colors[0] }">
                                        <span class="color-type__color-second" :style="{ backgroundColor: '#' + item.colors[1] }"></span>
                                        <span class="color-type__color-third" :style="{ backgroundColor: '#' + item.colors[2] }"></span>
                                    </span>
                                </span>
                                
                                {{ item.name }}
                            </span>
                        </label>
                    </div>
                    
                </div>
                
                <div class="filter-mobile-parametr__layout-panel-footer">
                    <button @click.prevent="applyFilter()" type="button" class="button button_theme_default button_size_m">Применить</button>
                </div>
            </div>
        </div>`,
});