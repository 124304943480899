import Vue from 'vue';

Vue.component('tabs', {
    data() {
        return {
            tabs: [],
        };
    },
    created() {
        this.tabs = this.$children;
    },
    mounted() {
        this.$nextTick(() => {
            this.lineActiveTab();
        });
    },
    methods: {
        selectTab(selectedTab) {
            this.tabs.forEach(tab => {
                tab.isActive = (tab.id == selectedTab.id);
            });

            this.$nextTick(() => {
                this.lineActiveTab();
            });
        },
        lineActiveTab() {
            let line = this.$el.querySelector('[data-line]'),
                tabActive = this.$el.querySelector('.cur');

            line.style.width = tabActive.offsetWidth + 'px';
            line.style.left = tabActive.offsetLeft + 'px';
        },
    },
    template:
    `
        <div class="tabs">
            <div class="tabs__navigation">
                <div data-line class="tabs__navigation-active-bar"></div>
                
                <ul class="tabs__navigation-list">
                    <li v-for="tab in tabs" :class="{ 'cur': tab.isActive }" class="tabs__navigation-item">
                        <a :href="tab.href" @click="selectTab(tab)" class="tabs__navigation-link">{{ tab.name }}</a>
                    </li>
                </ul>
            </div>
            
            <slot></slot>
        </div>
    `,
});

Vue.component('tab', {
    props: {
        id: {
            required: true,
        },
        name: {
            required: true,
        },
        selected: {
            default: false,
        },
    },
    data() {
        return {
            isActive: false,
        };
    },
    computed: {
        href() {
            return '#' + this.id.toLowerCase().replace(/ /g, '-');
        },
    },
    mounted() {
        this.isActive = this.selected;
    },
    template:
    `
        <div v-show="isActive" class="tabs__section">
            <slot></slot>
        </div>
    `,
});