import Vue from 'vue';
import axios from 'axios';

Vue.component('modal-filter', {
    data() {
        return {
            id: null,
            showModalFilter: false,
            fetchedDefaultParams: false,
            filters: {},
            params: {},
            columns: [[],[],[]],
            filterFetched: false
        };
    },
    beforeMount() {
        window.addEventListener('keyup', this.onEscapeKeyUp);

        this.uniqID();
    },
    beforeDestroy () {
        window.removeEventListener('keyup', this.onEscapeKeyUp);
    },
    mounted() {
        this.$root.$on('openFilter', () => {
            this.render();
        });

        this.$root.$on('addOrderToCatalog', (order) => {
            this.addOrder(order)
        });
    },


    methods: {
        uniqID() {
            this.id = Math.random().toString(36).substr(2, 9);
        },
        render() {
            let html = document.querySelector('html');
            html.classList.add('is-open-modal-' + this.id);

            this.fetchFilters()

            if (this.fetchedDefaultParams == false) {
                this.setDefaultParams()
                this.fetchedDefaultParams = true
            }

            this.showModalFilter = true;
        },

        close() {
            let html = document.querySelector('html');
            html.classList.remove('is-open-modal-' + this.id);

            this.showModalFilter = false;
        },
        onEscapeKeyUp (event) {
            if (event.which === 27) {
                this.close();
            }
        },

        fetchFilters() {

            this.filters = gon.category_filters
            this.filterBlocks()
        },

        filterBlocks() {
            this.columns = [[],[],[]]

            Object.keys(this.filters).map((k, v) => {
                this.columns[v % 3].push({
                    title: k,
                    values: this.filters[k]
                })
            })
        },

        isChecked(key, value) {
            if (this.params[key] == undefined) { return false }

            for (let i = 0; i < this.params[key].length; i++) {
                if (this.params[key][i] == value) { return true }
            }

            return false
        },

        setDefaultParams() {
            for (let [key, values] of Object.entries(gon.params)) {
                console.log(values)
                for (let i = 0; i < values.length; i++) {
                    let value = values[i]
                    if (this.params[key]) {
                        this.params[key].push(value)
                    } else {
                        this.params[key]=[value]
                    }
                }
            }
        },

        addPriceFilter(min, max) {
            this.params['min_price'] = min
            this.params['max_price'] = max
            this.applyFilters()
        },

        removePriceFilters() {
            delete this.params['min_price']
            delete this.params['max_price']
            this.applyFilters()
        },

        addOrder(order) {
            if (order == '') {
                delete this.params['order']
            } else {
                this.params['order'] = order
            }
            
            this.applyFilters()
        },

        applyFilters() {
            let url = window.location.pathname + '.json'
            let params = this.params

            delete params['page']

            axios.get(url, {
                params: params
            }).then((response) => {
                let newUrl = this.convertJsonEndpointToUrl(response.request.responseURL)
                history.pushState('Foo', 'Bar', newUrl)
                this.$parent.$root.$refs.productsList.reloadProducts(response.data)
                this.$parent.$root.$refs.productsList.reloadPagination(response.data.links)
                this.close()
            })
        },

        convertJsonEndpointToUrl(jsonEndpoint) {
            let result = jsonEndpoint.replace('.json?', '?').replace('.json', '')
            return result
        },

        changeFilter(key, value) {
            if (this.params[key] == undefined) {
                this.params[key] = [value] 
            } else {
                var oldValue
                for(var i = 0; i < this.params[key].length; i++) {
                    if (this.params[key][i] == value) {
                        this.params[key].splice(i, 1)
                        return;
                    }
                }
                this.params[key].push(value) 
            }
        },

        clearFiltres() {
            let url = window.location.pathname + '.json'

            axios.get(url).then((response) => {
                let newUrl = this.convertJsonEndpointToUrl(response.request.responseURL)
                history.pushState('Foo', 'Bar', newUrl)
                this.$parent.$root.$refs.productsList.reloadProducts(response.data)
                this.$parent.$root.$refs.productsList.reloadPagination(response.data.links)
                this.close()   
            }).catch((error) => { console.log('error') })
        },
    },
    template:
        `<div v-show="showModalFilter" class="popup-wrapper">
            <div class="popup-wrapper__container">
                <div class="popup-wrapper__content">
                    <div class="popup-close" @click="close()"><svg class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg></div>  
                    <div class="popup popup_filter">
                        
                        <div class="filter-popup">
                            <div class="filter-popup__header">
                                <div class="filter-popup__header-info">
                                    <div class="filter-popup__header-title"></div>
                                    <div class="filter-popup__count" v-html=""></div>
                                </div>
                                
                                <div class="filter-popup__header-actions">
                                    <button class="button button_theme_default button_size_l" type="button" @click="applyFilters()">Применить</button>
                                    <a href="#" class="filter-popup__reset" @click="clearFiltres()">Очистить фильтр</a>
                                </div>
                            </div>
                            
                            <div class="filter-popup__content">
                                <div class="filter-popup__content-col" v-for="column in columns">
                                    <div class="filter-popup__parametr" v-for="filter in column">
                                        <div class="filter-popup__parametr-title">{{ filter.title }}</div>
                                        
                                        <div class="filter-popup__parametr-list" v-for="value in filter.values">
                                            <label class="custom-checkbox">
                                                <input type="checkbox" :checked="isChecked(filter.title, value)" value="Y" name="key" class="custom-checkbox__input" @change="changeFilter(filter.title, value)">
                                                <span class="custom-checkbox__value">
                                                    <span class="custom-checkbox__icon"></span>{{ value }}
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="filter-popup__content-col">
                                </div>
                                
                                <div class="filter-popup__content-col">
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>`,
});

// 