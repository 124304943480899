import Vue from 'vue';

Vue.component('m-modal-review-rate', {
    data() {
        return {
            id: null,
            m_showModalReviewRate: false,
        };
    },
    beforeMount() {
        this.uniqID();
    },
    mounted() {
        this.$root.$on('m_openRate', () => {
            this.render();
        });
    },
    methods: {
        uniqID() {
            this.id = Math.random().toString(36).substr(2, 9);
        },
        render() {
            let _this = this,
                html = document.querySelector('html'),
                boxStep1 = document.querySelector('[data-step-animation="step-1"]');

            this.m_showModalReviewRate = true;

            _this.$nextTick(function () {
                html.classList.add('is-open-modal-'+_this.id);
                boxStep1.dataset.animation = 'slideOutLeft';
                _this.$el.dataset.stepAnimation = 'step-2';
            });
        },
        close() {
            let boxStep1 = document.querySelector('[data-step-animation="step-1"]');

            this.m_showModalReviewRate = false;

            boxStep1.dataset.animation = 'slideInLeft';
        },
        m_showModalReviewFormInit() {
            this.$root.$emit('m_openReviewForm');
        },
    },
    template:
        `<transition name="slide-right" enter-active-class='is-animated slideInRight' leave-active-class='is-animated slideOutRight'>
            <div v-if="m_showModalReviewRate" class="popup-wrapper">
                <div class="popup-wrapper__container">
                    <div class="popup-wrapper__content">
                        <div class="popup-close" @click="close()"><svg class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg></div>  
                        <div class="popup-top-panel" @click="close()">
                            <div class="popup-back-link"></div>
                            <div class="popup-top-panel__text">Оставить отзыв</div>
                        </div>
                        <div class="popup">
                            <div class="review-form-rate">
                                <div class="review-form-rate__heading">
                                    Оцените коляску Stokke Xplory V5 Athleisure
                                </div>
                            
                                <div class="review-form-rate__pic">
                                    <img src="img/contant/stokke5.jpg" alt=""/>
                                </div>
            
                                <form action="" class="review-form-rate__stars" @click="m_showModalReviewFormInit()">
                                    <fieldset class="rating">
                                        <input type="radio" id="star5" name="rating" value="5" />
                                        <label class="full" for="star5" title="great - 5 stars">
                                            <svg class="icon icon-star"><use xlink:href="#icon-star"></use></svg>
                                        </label>
                                        <input type="radio" id="star4" name="rating" value="4" />
                                        <label class="full" for="star4" title="good - 4 stars">
                                            <svg class="icon icon-star"><use xlink:href="#icon-star"></use></svg>
                                        </label>
                                        <input type="radio" id="star3" name="rating" value="3" />
                                        <label class="full" for="star3" title="ordinary - 3 stars">
                                            <svg class="icon icon-star"><use xlink:href="#icon-star"></use></svg>
                                        </label>
                                        <input type="radio" id="star2" name="rating" value="2" />
                                        <label class="full" for="star2" title="bad - 2 stars">
                                            <svg class="icon icon-star"><use xlink:href="#icon-star"></use></svg>
                                        </label>
                                        <input type="radio" id="star1" name="rating" value="1" />
                                        <label class="full" for="star1" title="terrible - 1 star">
                                            <svg class="icon icon-star"><use xlink:href="#icon-star"></use></svg>
                                        </label>
                                    </fieldset>
                                </form>
            
                                <div class="review-form-rate__hint">
                                    Нажмите, чтобы оценить
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>`,
});