import Vue from 'vue';
import axios from 'axios';

Vue.component('product-review-layout', {
    data() {
        return {
            reviews: [],
            reviews_stat: [],
            avg_rate: 0
        };
    },

    mounted() {
        let url = window.location.pathname + "/product_reviews"

        axios.get(url).then((response) => {
            this.reviews = response.data.reviews
            this.avg_rate = response.data.avg_rate
            this.reviews_stat = response.data.reviews_stat

            console.log(this.reviews_stat)
        }).catch((error) => {
            console.log('Что-то пошло не так')
        })
    },

    computed: {
        product_name: function() {
            return document.querySelector("h1").textContent
        }
    },

    methods: {
        showModalReviewFormInit() {
            this.$root.$emit('openReviewForm');
        },

        starToClass(star) {
            let classNames = {
                5: "great",
                4: "good",
                3: "ordinary",
                2: "bad",
                1: "terrible"
            }

            return `review_rate_${classNames[star]}`
        },

        widthStat(key, value) {
            let valuePercent = 100 / this.reviews.length * value

            return `width: ${valuePercent}%;`
        }
    },
    template:
        `<div class="product-review product-review_popup">
            <div class="product-review__heading">{{ product_name }} отзывы</div>
            <div class="product-review__header">
                <div class="product-review__rate-box">
                    <div class="product-review__rate-box-nums">
                        <div class="product-review__mid-rate product-review__mid-rate_good">{{avg_rate}}</div>
                        <div class="product-review__fix-rate">/ 5</div>
                    </div>
                    <a href="#" class="product-review__rate-box-reviews">{{reviews.length}} отзыва</a>
                </div>

                <div class="product-review__stats">
                    <div class="product-review__stats-line rating-review" v-for="(stat, key, index) in reviews_stat">
                        <div class="rating-review__rate">{{key}}</div>
                        <div class="rating-review__scale">
                            <div class="rating-review__scale-progress" :style="widthStat(key, stat)"></div>
                        </div>
                        <div class="rating-review__count">{{stat}}</div>
                    </div>
                </div>

                <a href="#"  @click.prevent="showModalReviewFormInit()" class="product-review__button-new-review button button_size_m button_theme_ghost_dark">Оставить отзыв</a>
            </div>

            <div class="product-review__list">      
                <section class="product-review__list-item review" :class="starToClass(review.star)" v-for="review in reviews">
                    <header class="review__header">
                        <div class="review__user">
                            <div class="review__user-rate">{{review.star}}</div>
                            <div class="review__user-info">
                                <div class="review__user-name">{{review.name}}</div>
                                <time class="review__user-date">{{review.create_at}}</time>
                            </div>
                        </div>
                    </header>

                    <div class="review__content">
                        <div class="review__section-info">
                            <div class="review__section-info-heading">Достоинства</div>
                            <div class="review__section-info-text">{{review.advantages}}</div>
                        </div>
                        <div class="review__section-info">
                            <div class="review__section-info-heading">Недостатки</div>
                            <div class="review__section-info-text">{{review.disadvantages}}</div>
                        </div>
                        <div class="review__section-info">
                            <div class="review__section-info-heading">Комментарий</div>
                            <div class="review__section-info-text">
                                {{review.comment}}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>`,
});