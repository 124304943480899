import Vue from 'vue'
import axios from 'axios'

Vue.component('modal-all-city', {
  data() {
    return {
      showModalAllCity: false,
      search: '',
      cities: []
    }
  },
  mounted() {
    this.$root.$on('openAllCity', () => {
      this.render();
    });
  },
  computed: {
    filteredCities() {
      let filterValue = this.search.toLowerCase()

      let result = this.cities.filter((item) => {
        let cityTitle = item.name.toLowerCase()
        return cityTitle.search(filterValue) >= 0
      })

      return result
    }
  },
  methods: { 
    render() {
      this.showModalAllCity = true
      this.fetchCities()
    },
    close() {
      this.showModalAllCity = false
    },
    fetchCities() {
      axios.get('/cities').then((response) => {
        this.cities = response.data.cities
      }).catch((error) => {})
    },
  },
  template: `
    <transition name="fade-up" enter-active-class='is-animated fadeInUp' leave-active-class='is-animated fadeOutDown'>
      <div v-if="showModalAllCity" class="popup-wrapper popup-add-cart">
          <div class="popup-wrapper__container">
              <div class="popup-wrapper__content">
                  <button class="popup-close" @click="close()"><svg class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg></button>      
                  <div slot="body" class="popup" style="padding-top: 30px;">
                    <form action="" class="search-widget__field search-field" style="width: 33%;">
                      <input type="text" 
                      autocomplete="off" 
                      placeholder="Поиск" 
                      name="search" 
                      class="search-field__input custom-input theme-light"
                      v-model="search">

                      <button class="search-field__button" @click.prevent="">
                        <svg width="18" height="18" class="icon icon-loupe">
                          <use xlink:href="#icon-loupe"></use>
                        </svg>
                      </button>
                    </form>
                    <ul style="column-count: 4;list-style: none;">
                      <li v-for="city in filteredCities" style="">
                        <a :href="'/change_city/' + city.name">{{ city.name }}</a>
                      </li>
                    </ul>
                  </div>
              </div>
          </div>
      </div>
    </transition>
  `
});