import Vue from 'vue';

Vue.component('product-element-fixed-card', {
    props: ['product'],
    data() {
        return {
            imageUrl: "",
            oldPrice: "",
            price: "",
            title: "",
            offerId: gon.offer_id
        };
    },
    mounted() {
        var image = document.querySelector('.picture-product-detail__main-slider-picture img')
        var price = document.querySelector('.product-detail-prices__price_default')
        var oldPrice = document.querySelector('.product-detail-prices__price_old')
        var title = document.querySelector('.product-detail-main-info__name')
        this.imageUrl = image.getAttribute('src')
        this.price = price.textContent
        this.title = title.textContent
        console.log(this.offerId)

        if (oldPrice != undefined) {
            this.oldPrice = oldPrice.textContent
        }
    },
    methods: {
        render() {

        },
        showModalAddCartInit(offerId) {
            this.$root.$emit('openAddCart', offerId);
            this.$root.cart.line_items.push({ product_id: offerId })
        },
        isCart(offerId) {
            return this.$root.isAddToCart(offerId)
        }
    },
    template:
        `<div 
            style="height: 360px;"
            class="product-detail__preview-product-card product-card-light product-card-light_promo product-card-light_size_lg mdx-show-block">
           <!--<div class="product-card-light__color-indicator color-indicator" style="background-color: #C63024; box-shadow: 0 0 0 5px rgba(198,48,36,.20);"></div>-->

            <a href="#" class="product-card-light__picture">
                <img :src="imageUrl" alt=""/>
            </a>

            <div class="product-card-light__info">
                <a href="#" class="product-card-light__name">
                    {{ title }}
                </a>

                <div class="product-card-light__prices">
                    <span class="product-card-light__price">{{ price }}</span>
                    <span class="product-card-light__price product-card-light__price_old">{{ oldPrice }}</span>
                </div>
            </div>
        </div>`,
});