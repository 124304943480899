<template>
    <div class="accessorize-slider__wrapper swiper-wrapper">
        <product-accessory-card v-for="product in products" :key="product.id" :product="product"></product-accessory-card>
    </div>
</template>

<script>
import { deserialize } from 'deserialize-json-api';
import axios from 'axios'
import prodcutAccessoryCard from './product_accessory_card.vue'

export default {
    props: ['product_id'],
    data() {
        return { 
            products: []
        }
    },
    components: {
        'product-accessory-card': prodcutAccessoryCard
    },
    mounted() {
        this.loadProducts()
    },
    methods: {
        loadProducts() {
            axios
                .get(`/api/product_accessories/${this.product_id}`)
                .then((response) => {
                    this.products = deserialize(response.data).data
                    productAccssories()

                })
                .catch((error) => {})
        }
    }
}
</script>