import Vue from 'vue';
import axios from 'axios';

Vue.component('m-sign-in', {
    data() {
        return {
            id: null,
            m_showModalSignIn: false,
            isSignIn: true,
            privatePolicy: false,
            login_error: '',
            errors: {},
            accept_error: false,
            user: {
                email: '',
                password: ''
            }
        };
    },
    beforeMount() {
        this.uniqID();
    },
    mounted() {
        this.$root.$on('m_openSignIn', () => {
            this.render();
        });
    },
    methods: {
        uniqID() {
            this.id = Math.random().toString(36).substr(2, 9);
        },
        render() {
            let _this = this,
                html = document.querySelector('html');

            this.m_showModalSignIn = true;

            _this.$nextTick(function () {
                html.classList.add('is-open-modal-'+_this.id);
                html.dataset.animation = 'slideOutLeft';
            });
        },
        close() {
            this.m_showModalSignIn = false;

            let html = document.querySelector('html');

            html.classList.remove('is-open-modal-' + this.id);
            html.dataset.animation = 'slideInLeft';

            setTimeout(function() {
                html.removeAttribute('data-animation');
            }, 1000);
        },

        login() {
            axios.post('/users/sign_in.json', { 
                user: this.user 
            }).then((response) => {
                window.location.reload()
            }).catch((error) => {
                if (error.response.status == 401) {
                    this.login_error = 'Указан неверный e-mail или пароль'
                }
            })
        },

        signUp() {
            if (!this.privatePolicy) { 
                this.accept_error = true
                return 
            }

            this.accept_error = false

            axios.post('/users.json', { 
                user: this.user 
            }).then((response) => {
                window.location.reload()
            }).catch((error) => {
                this.errors = error.response.data.errors
            })
        }
    },
    template:
        `<transition name="slide-right" enter-active-class='is-animated slideInRight' leave-active-class='is-animated slideOutRight'>
        <div v-if="m_showModalSignIn" class="popup-wrapper popup-modal">
            <div class="popup-wrapper__container">
                <div class="popup-wrapper__content">
                    <div class="popup-close" @click="close()"><svg class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg></div>  
                    <div class="popup-top-panel" @click="close()">
                        <div class="popup-back-link"></div>
                        <div class="popup-top-panel__text">
                            <template v-if="isSignIn">Вход</template>
                            <template v-else>Регистрация</template>
                        </div>
                        <div class="mobile-panel__close"><svg width="13" height="13" class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg></div>
                    </div>
                    
                    <div slot="body" class="popup popup-sign">

                        <div class="mobile-panel-section" v-if="isSignIn">
                            <div class="settings-form">
                                <div class="settings-form__line">
                                    <div class="settings-form__line-label" v-if="login_error" style="color: red;">{{ login_error }}</div>
                                    <div class="settings-form__line-label">e-Mail</div>
                                    <div class="settings-form__line-input">
                                        <svg width="15" height="15" class="icon icon-check"><use xlink:href="#icon-check"></use></svg>
                                        <input type="text" class="custom-input theme-default" v-model="user.email">
                                    </div>
                                </div>
                                <div class="settings-form__line">
                                    <div class="settings-form__line-label">Пароль <a href="/users/password/new">Забыли пароль?</a></div>
                                    <div class="settings-form__line-input">
                                        <svg width="15" height="15" class="icon icon-check"><use xlink:href="#icon-check"></use></svg>
                                        <input type="password" class="custom-input theme-default" v-model="user.password">
                                    </div>
                                </div>
                                <div class="settings-form__line">
                                  <button type="button" class="button button_theme_default button_size_l" @click="login">Войти</button>
                                </div>
                            </div>
                        </div>

                        <div class="mobile-panel-section" v-else>
                            <div class="settings-form">
                                <div class="settings-form__line">
                                    <div class="settings-form__line-label">eMail</div>
                                    <div class="settings-form__line-input">
                                        <svg width="15" height="15" class="icon icon-check"><use xlink:href="#icon-check"></use></svg>
                                        <input type="text" class="custom-input theme-default" v-model="user.email">
                                        <span v-for="error in errors.email" style="color: red;">{{error}}</span>
                                    </div>
                                </div>
                                <div class="settings-form__line">
                                    <div class="settings-form__line-label">Пароль</div>
                                    <div class="settings-form__line-input">
                                        <svg width="24" height="18" class="icon icon-eye"><use xlink:href="#icon-eye"></use></svg>
                                        <input type="password" class="custom-input theme-default" v-model="user.password">
                                        <span v-for="error in errors.password" style="color: red;">{{error}}</span>
                                    </div>
                                    <div class="settings-form__line-hint">
                                        Пароль должен содержать не менее 6 символов
                                    </div>
                                </div>
                                <div class="settings-form__line">
                                    <div class="settings-form__line-label">Подтвердите пароль</div>
                                    <div class="settings-form__line-input">
                                        <svg width="24" height="18" class="icon icon-eye"><use xlink:href="#icon-eye"></use></svg>
                                        <input type="password" class="custom-input theme-default" v-model="user.password_confirmation">
                                        <span v-for="error in errors.password_confirmation" style="color: red;">{{error}}</span>
                                    </div>
                                </div>
                                <div class="settings-form__line">
                                  <label class="custom-checkbox">
                                    <input type="checkbox" value="Y" name="RULE_FEEDBACK_QUESTION" class="custom-checkbox__input" v-model="privatePolicy">
                                    <span class="custom-checkbox__value">
                                      <span class="custom-checkbox__icon"></span>
                                      Я согласен на <a href="#" target="_blank">обработку персональных данных</a>
                                    </span>
                                    <span style="color: red;" v-if="accept_error">Вы должны дать согласие на обработку персональных данных</span>
                                  </label>
                                </div>
                                <div class="settings-form__line">
                                  <button type="button" class="button button_theme_default button_size_l" @click="signUp">Зарегистрироваться</button>
                                </div>
                            </div>
                        </div>
                        
                        <div class="mobile-panel-section">
                            <button class="popup-sign__switch" type="button" @click="isSignIn = !isSignIn">
                                <template v-if="isSignIn">Зарегистрироваться</template>
                                <template v-else>Назад ко входу</template>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </transition>`,
});