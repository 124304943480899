import Vue from 'vue';

Vue.component('modal-product-reviews', {
    data() {
        return {
            id: null,
            showModalReviews: false,
        };
    },
    beforeMount() {
        window.addEventListener('keyup', this.onEscapeKeyUp);

        this.uniqID();
    },
    beforeDestroy () {
        window.removeEventListener('keyup', this.onEscapeKeyUp);
    },
    mounted() {
        this.$root.$on('openReviews', () => {
            this.render();
        });
    },
    methods: {
        uniqID() {
            this.id = Math.random().toString(36).substr(2, 9);
        },
        render() {
            let html = document.querySelector('html');
            html.classList.add('is-open-modal-' + this.id);

            this.showModalReviews = true;
        },
        close() {
            let html = document.querySelector('html');
            html.classList.remove('is-open-modal-' + this.id);

            this.showModalReviews = false;
        },
        onEscapeKeyUp (event) {
            if (event.which === 27) {
                this.close();
            }
        },
    },
    template:
        `<div v-if="showModalReviews" class="popup-wrapper">
            <div class="popup-wrapper__container">
                <div class="popup-wrapper__content">
                    <div class="popup-close" @click="close()"><svg class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg></div>  
                    <slot name="header"></slot>      
                    <slot name="body"></slot>
                </div>
            </div>
        </div>`,
});