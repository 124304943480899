import Swiper from 'swiper/dist/js/swiper.min.js';

function instSlider() {
    let $instSlider = $('.js-inst-slider');

    $instSlider.each(function(i, slider) {
        let $slider = $(slider),
            $container = $slider.find('.swiper-container'),
            $arrowPrev = $slider.find('.swiper-button-prev'),
            $arrowNext = $slider.find('.swiper-button-next'),
            $sliderPagination = $slider.find('.swiper-pagination-custom');
            // $slideInst = $slider.find('inst');

        const instSliderObj = new Swiper($container, {
            slidesPerColumn: 2,
            slidesPerColumnFill: 'row',
            slidesPerView: 'auto',
            spaceBetween: 10,
            removeClippedSubviews: false,
            // preloadImages: true,
            // loop: true,
            speed: 500,
            freeMode: false,
            lazy: true,
            freeModeSticky: false,
            navigation: {
                nextEl: $arrowNext,
                prevEl: $arrowPrev,
            },
            pagination: {
                el: $sliderPagination,
                dynamicBullets: true,
            },
            breakpointsInverse: false,
            breakpoints: {
                768: {
                    spaceBetween: 20,
                },
            },
        });

        // $slideProduct.hover(function() {
        //     $(this).closest('.js-inst-slider').addClass('is-hover-product');
        // }, function() {
        //     $(this).closest('.js-inst-slider').removeClass('is-hover-product');
        // });
        
    });
}

export default instSlider;