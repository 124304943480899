import Vue from 'vue';
import axios from 'axios';

Vue.component('m-all-city-modal', {
    data() {
        return {
            id: null,
            m_showModalAllCity: false,
            cities: [],
            unactiveCities: []
        };
    },
    beforeMount() {
        this.uniqID();
    },
    mounted() {
        this.$root.$on('m_openAllCity', () => {
            this.render();
        });
    },
    methods: {
        uniqID() {
            this.id = Math.random().toString(36).substr(2, 9);
        },
        render() {
            let _this = this,
                html = document.querySelector('html');

            axios.get('/cities').then((response) => {
                console.log('try fetch cities')
                this.unactiveCities = response.data.cities
            }).then((error) => {

            })

            this.m_showModalAllCity = true;

            _this.$nextTick(function () {
                html.classList.add('is-open-modal-'+_this.id);
                html.dataset.animation = 'slideOutLeft';
            });
        },
        close() {
            this.m_showModalAllCity = false;

            let html = document.querySelector('html');

            html.classList.remove('is-open-modal-' + this.id);
            html.dataset.animation = 'slideInLeft';

            setTimeout(function() {
                html.removeAttribute('data-animation');
            }, 1000);
        },
    },
    template:
        `<transition name="slide-right" enter-active-class='is-animated slideInRight' leave-active-class='is-animated slideOutRight'>
        <div v-if="m_showModalAllCity" class="popup-wrapper popup-modal">
            <div class="popup-wrapper__container">
                <div class="popup-wrapper__content">
                    <div class="popup-close" @click="close()"><svg class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg></div>  
                    <div class="popup-top-panel" @click="close()">
                        <div class="popup-back-link"></div>
                        <div class="popup-top-panel__text">Выберите город</div>
                    </div>
                    
                    <div slot="body" class="popup popup_cities">
                        <div class="popup__header" style="display: none">
                            <div class="search-panel-widget">
                                <input type="text" class="search-panel-widget__input">
                            </div>
                            <div class="popup-close" @click="close()"><svg class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg></div>
                        </div>
                        
                        <div class="mobile-panel-section">
                            <nav class="mobile-panel-navigation">
                                <ul class="mobile-panel-navigation__list">
                                    <li class="mobile-panel-navigation__item" v-for='city in cities'>
                                        <a :href="'/change_city/' + city.name" class="mobile-panel-navigation__link">
                                            <span class="mobile-panel-navigation__link-text">{{ city.name }}</span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div class="mobile-panel-section">
                        <div class="mobile-panel-section__title">Другие города</div> 
                        <nav class="mobile-panel-navigation">
                            <ul class="mobile-panel-navigation__list">
                                <li class="mobile-panel-navigation__item" v-for='city in unactiveCities'>
                                    <a :href="'/change_city/' + city.name " class="mobile-panel-navigation__link">
                                        <span class="mobile-panel-navigation__link-text">{{ city.name }}</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        </transition>`,
});