import Vue from 'vue';
import axios from 'axios';
import filterMobileParametr from './filterMobileParametr';
import filterMobileParametrColor from './filterMobileParametrColor';
import filterMobileParametrPrice from './filterMobileParametrPrice';
import filterMobileParametrSlider from './filterMobileParametrSlider';
import filterOptionSwitch from './filterOptionSwitch';
import lazyImages from '../../components/lazy';
import FilterParamsService from '../../services/catalog/filter_params_service';
import selectSortProductsCatalog from "../../components/selectSortProductsCatalog";

Vue.component('filter-mobile', {
    data() {
        return {
            m_openFilterMobile: false,
            filters: {},
            params: {},
            filtersFetched: false,
            fetchedDefaultParams: false
        };
    },
    mounted() {
        this.$root.$on('m_openFilterMobile', () => {
            this.render();
        });
    },
    methods: {
        setFilters() {
            this.filters = gon.category_filters
        },
        render() {
            let html = document.querySelector('html');
            this.setFilters();

            if (this.fetchedDefaultParams == false) {
                this.setDefaultParams()
                this.fetchedDefaultParams = true
            }

            this.m_openFilterMobile = true;

            html.classList.add('is-open-filter-mobile');
            html.dataset.animation = 'slideOutRight';
        },

        setDefaultParams() {
            for (let [key, values] of Object.entries(gon.params)) {
                if (Array.isArray(values)) {
                    for (let i = 0; i < values.length; i++) {
                        let value = values[i]
                        if (this.params[key]) {
                            this.params[key].push(value)
                        } else {
                            this.params[key]=[value]
                        }
                    }
                } else {
                    this.params[key] = values
                }
            }
        },

        close() {
            let _this = this,
                html = document.querySelector('html');

            this.m_openFilterMobile = false;

            html.classList.remove('is-open-filter-mobile');
            html.dataset.animation = 'slideInRight';

            setTimeout(function() {
                html.removeAttribute('data-animation');
            }, 1000);
        },

        convertJsonEndpointToUrl(jsonEndpoint) {
            let result = jsonEndpoint.replace('.json?', '?').replace('.json', '')
            return result
        },

        applyFilters() {
            let url = window.location.pathname + '.json'

            axios.get(url, {
                params: this.params
            }).then((response) => {
                let newUrl = this.convertJsonEndpointToUrl(response.request.responseURL)
                history.pushState('Foo', 'Bar', newUrl)

                this.$parent.$root.$refs.productsList.reloadProducts(response.data)
                this.$parent.$root.$refs.productsList.reloadPagination(response.data.links)
                this.close()
            })
        },

        addPriceFilter(min, max) {
            this.params['min_price'] = min
            this.params['max_price'] = max
            this.applyFilters()
        },
    },
    template:
        `<transition name="slide-right" enter-active-class='is-animated slideInLeft' leave-active-class='is-animated slideOutLeft'>
        <div v-if="m_openFilterMobile" class="filter-mobile">
            <div class="filter-mobile__header">
                Фильтр 
                <button @click.prevent="close()" class="filter-mobile__close"><svg width="13" height="13" class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg></button>
            </div>
            
            <div data-list="uses" class="filter-mobile-params is-empty">
                <div class="filter-mobile-params__header">
                    <div class="filter-mobile-params__header-title">Уже используются</div>
                    <button type="button" class="filter-mobile-params__reset">
                        <svg width="8" height="8" class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg>Сбросить
                    </button>
                </div>
            </div>
            
            <div data-list="others" class="filter-mobile-params is-default">
                <div class="filter-mobile-params__header">
                    <div class="filter-mobile-params__header-title">Остальные</div>
                </div>
                
                <div class="filter-mobile-params__list">
                    <filter-mobile-parametr-price :params="params" @applyPriceFilters="addPriceFilter" name="Стоимость" min="0" max="300000" step="1" val-name="₽"></filter-mobile-parametr-price>
                    <filter-mobile-parametr v-for="(values, name, index) in filters" :name="name" :values="values" :params="params"  @applyFilters="applyFilters"></filter-mobile-parametr>
                </div>
            </div>
        </div>
    </transition>`,
});