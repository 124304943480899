import Vue from 'vue';

Vue.component('m-modal-pickup', {
    data() {
        return {
            modalShow: false,
        };
    },
    mounted() {
        this.$root.$on('m_openModalPickup', (tab) => {
            this.render(tab);
        });
    },
    methods: {
        render(tab) {
            let html = document.querySelector('html');

            this.modalShow = true;

            html.classList.add('is-open-mobile-panel');
            html.dataset.animation = 'slideOutLeft';
        },
        close() {
            let html = document.querySelector('html');

            this.modalShow = false;

            html.classList.remove('is-open-mobile-panel');
            html.dataset.animation = 'slideInLeft';

            setTimeout(function() {
                html.removeAttribute('data-animation');
            }, 1000);
        },
        m_openModalCitiesInit() {
            /*
            *
            * Метод открытия модального окна из компонента m_modalAllCity
            * */

            this.$root.$emit('m_openAllCity');
        },
    },
    template:
    `<transition name="slide-right" enter-active-class='is-animated slideInRight' leave-active-class='is-animated slideOutRight'>
        <div v-if="modalShow" class="popup-wrapper popup-modal">
            <div class="popup-wrapper__container">
                <div class="popup-wrapper__content">
                    <div class="popup-close" @click="close()"><svg class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg></div>  
                    
                    <div class="popup-top-panel" @click="close()">
                        <div class="popup-back-link"></div>
                        <div class="popup-top-panel__text">Самовывоз из магазина</div>
                    </div>
                    
                    <div class="popup popup_delivery">
                        <div class="product-tabs-block">
                            <div class="product-tabs-block__header">
                                <a href="#" @click.prevent="m_openModalCitiesInit()" class="custom-link-view custom-link-view_bottom c-dark">В Санкт-Петербург и ЛО</a>
                            </div>
                            
                            <div class="product-tabs-block__content">
                                <tabs class="tabs_popup">
                                    <tab name="Списком" id="list" :selected="true">
                                        <div class="sale-points">
                                            <div class="sale-points__shops-list">
                                                <div class="sale-points__shops-list-grid">
                                                    <div class="sale-points__shop sale-point-shop">
                                                        <svg class="sale-point-shop__icon icon icon-pin" width="18" height="18"><use xlink:href='#icon-pin'></use></svg>
                                                        
                                                        <div class="sale-point-shop__status warning">Скоро закончится</div>
                                                        
                                                        <div class="sale-point-shop__address-name">
                                                            ТОЦ «Эврика», ул. Седова, 11, 3 этаж
                                                        </div>
                                                        
                                                        <div class="sale-point-shop__metro">
                                                            <div class="sale-point-shop__metro metro-point">
                                                                <span class="metro-point__icon" style="background-color: #14CC83"></span>Елизаровская
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="sale-point-shop__time-work">
                                                            <div class="sale-point-shop__time-work-item">
                                                                C 11 до 20 <span class="sale-point-shop__time-work-hint">Закроется через 15 минут</span>
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="sale-point-shop__footer">
                                                            <div class="sale-point-shop__phone">
                                                                <a href="tel:88123097892" class="sale-point-shop__phone-num">(812) 309-78-92</a>
                                                                <div class="sale-point-shop__phone-hint">Советуем уточнить наличие</div>
                                                            </div>
                                                            
                                                            <div class="sale-point-shop__actions">
                                                                <button type="button" class="button button_theme_ghost_dark button_size_m">Заказать звонок</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="sale-points__shop sale-point-shop">
                                                        <svg class="sale-point-shop__icon icon icon-pin" width="18" height="18"><use xlink:href='#icon-pin'></use></svg>
                                                        
                                                        <div class="sale-point-shop__status success">Много</div>
                                                        
                                                        <div class="sale-point-shop__address-name">
                                                            ТМ «Светлановский», пр. Энгельса, 33
                                                        </div>
                                                        
                                                        <div class="sale-point-shop__metro metro-point">
                                                            <span class="metro-point__icon" style="background-color: #1976D2"></span>Удельная
                                                        </div>
                                                        
                                                        <div class="sale-point-shop__time-work">
                                                            <div class="sale-point-shop__time-work-item">
                                                                C 11 до 21 <span class="sale-point-shop__time-work-hint">Сейчас открыто</span>
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="sale-point-shop__footer">
                                                            <div class="sale-point-shop__phone">
                                                                <a href="tel:88123097892" class="sale-point-shop__phone-num">(812) 309-78-92</a>
                                                            </div>
                                                            
                                                            <div class="sale-point-shop__actions sale-point-shop__actions_full">
                                                                <button type="button" class="button button_theme_default button_size_m">Забронировать</button>
                                                                <button type="button" class="button button_theme_ghost_dark button_size_m">Заказать звонок</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                           
                                        </div>
                                    </tab>
                                    
                                    <tab name="На карте" id="onmap">
                                        <h1>How much we do it for</h1>
                                    </tab>
                                </tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>`,
});