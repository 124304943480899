import Vue from 'vue';

Vue.component('m-product-review-layout', {
    data() {
        return {};
    },
    methods: {
        m_showModalReviewRateInit() {
            this.$root.$emit('m_openRate');
        },
    },
    template:
        `<div class="product-review product-review_popup">
            <div class="product-review__header">
                <div class="product-review__rate-box">
                    <div class="product-review__rate-box-nums">
                        <!--
                            Статусы рейтинговых оценок:

                            great - 5
                            good - 4
                            ordinary - 3
                            bad - 2
                            terrible - 1
                         -->
                        <div class="product-review__mid-rate product-review__mid-rate_good">4,5</div>
                        <div class="product-review__fix-rate">/ 5</div>
                    </div>
                    <a href="#" class="product-review__rate-box-reviews">23 отзыва</a>
                </div>

                <div class="product-review__stats">
                    <div class="product-review__stats-line rating-review">
                        <div class="rating-review__rate">5</div>
                        <div class="rating-review__scale">
                            <div class="rating-review__scale-progress" style="width: 60%;"></div>
                        </div>
                        <div class="rating-review__count">12</div>
                    </div>
                    <div class="product-review__stats-line rating-review">
                        <div class="rating-review__rate">4</div>
                        <div class="rating-review__scale">
                            <div class="rating-review__scale-progress" style="width: 40%;"></div>
                        </div>
                        <div class="rating-review__count">7</div>
                    </div>
                    <div class="product-review__stats-line rating-review">
                        <div class="rating-review__rate">3</div>
                        <div class="rating-review__scale">
                            <div class="rating-review__scale-progress" style="width: 30%;"></div>
                        </div>
                        <div class="rating-review__count">3</div>
                    </div>
                    <div class="product-review__stats-line rating-review">
                        <div class="rating-review__rate">2</div>
                        <div class="rating-review__scale">
                            <div class="rating-review__scale-progress" style="width: 10%;"></div>
                        </div>
                        <div class="rating-review__count">1</div>
                    </div>
                    <div class="product-review__stats-line rating-review">
                        <div class="rating-review__rate">1</div>
                        <div class="rating-review__scale">
                            <div class="rating-review__scale-progress" style="width: 0%;"></div>
                        </div>
                        <div class="rating-review__count">0</div>
                    </div>
                </div>

                <a href="#" @click.prevent="m_showModalReviewRateInit()" class="product-review__button-new-review button button_size_m button_theme_ghost_dark">Оставить отзыв</a>
            </div>

            <div class="product-review__sort sort-widget">
                <div class="sort-widget__title">Сначала показать:</div>
                <div class="select-light js-reviews-sort">
                    <select name="SORT_REVIEWS" class="select-light__input">
                        <option value="1">Полезные</option>
                        <option value="2">Новые</option>
                        <option value="3">Старые</option>
                        <option value="4">Положительные</option>
                        <option value="5">Отрицательные</option>
                    </select>
                </div>
            </div>

            <div class="product-review__list">
                <!--
                    Статусы рейтинговых оценок:

                    great - 5
                    good - 4
                    ordinary - 3
                    bad - 2
                    terrible - 1
                 -->
                
                <section class="product-review__list-item review review_rate_great">
                    <header class="review__header">
                        <div class="review__user">
                            <div class="review__user-rate">5</div>
                            <div class="review__user-info">
                                <div class="review__user-name">Наталья</div>
                                <time class="review__user-date">22 января 2018</time>
                            </div>
                        </div>

                        <div class="review__controls">
                            <div class="review__controls-title">Был ли полезен отзыв?</div>
                            <a href="#" class="review__controls-link review__controls-link_like">
                                <svg class="icon icon-like"><use xlink:href="#icon-like"></use></svg>
                                <span class="review__controls-link-count">76</span>
                            </a>
                            <a href="#" class="review__controls-link review__controls-link_dislike">
                                <svg class="icon icon-dislike"><use xlink:href="#icon-dislike"></use></svg>
                                <span class="review__controls-link-count">12</span>
                            </a>
                        </div>
                    </header>

                    <div class="review__content">
                        <div class="review__section-info">
                            <div class="review__section-info-heading">Достоинства</div>
                            <div class="review__section-info-text">
                                Маневренная,управляется лекал одной рукой, максимально близко
                                к ребёнку, быстро складывается и самая легкая из всех колясок.
                            </div>
                        </div>
                        <div class="review__section-info">
                            <div class="review__section-info-heading">Недостатки</div>
                            <div class="review__section-info-text">После использования 1 год и 7 мес. Недостатков нет)))</div>
                        </div>
                        <div class="review__section-info">
                            <div class="review__section-info-heading">Комментарий</div>
                            <div class="review__section-info-text">
                                к этой коляске надо привыкнуть и получать удовольствие
                                от прогулок. Мой ребёнок, засыпает только в этой коляске особенно когда
                                дорога не ровная. Наверно на каждого человека свой товар. Мы любим нашу
                                коляску и жаль будет расставаться. Живём не в городе и катаемся по паркам ))
                            </div>
                        </div>
                    </div>
                </section>
               
                <section class="product-review__list-item review review_rate_bad">
                    <header class="review__header">
                        <div class="review__user">
                            <div class="review__user-rate">2</div>
                            <div class="review__user-info">
                                <div class="review__user-name">Денис</div>
                                <time class="review__user-date">8 января 2018</time>
                            </div>
                        </div>

                        <div class="review__controls">
                            <div class="review__controls-title">Был ли полезен отзыв?</div>
                            <a href="#" class="review__controls-link review__controls-link_like">
                                <svg class="icon icon-like"><use xlink:href="#icon-like"></use></svg>
                                <span class="review__controls-link-count">76</span>
                            </a>
                            <a href="#" class="review__controls-link review__controls-link_dislike">
                                <svg class="icon icon-dislike"><use xlink:href="#icon-dislike"></use></svg>
                                <span class="review__controls-link-count">12</span>
                            </a>
                        </div>
                    </header>

                    <div class="review__content">
                        <div class="review__section-info">
                            <div class="review__section-info-heading">Достоинства</div>
                            <div class="review__section-info-text">Красиво смотрится</div>
                        </div>
                        <div class="review__section-info">
                            <div class="review__section-info-heading">Недостатки</div>
                            <div class="review__section-info-text">Эта коляска один сплошной недостаток????</div>
                        </div>
                        <div class="review__section-info">
                            <div class="review__section-info-heading">Комментарий</div>
                            <div class="review__section-info-text">
                                единственный возможный плюс данной коляски это красивый дизайн,
                                но это пока ты не сталкулся с минусами. Вообщем покупка данной коляски это
                                выкинутые деньги, причём ты понимаешь это после первого дня использования???????????
                                Амортизации в данной коляски вообще нет!! как будто ты возишь ребёнка в продуктовой
                                тележке, для зимы она вообще не подходит, так как люлька полностью продувается
                                с боку, даже при использовании зимнего комплекта, даже в черте города катать эту
                                коляску затруднительно, а если выезжаешь в парк, то складывается ощущение, что возишь
                                по стиральной доске. Вообщем купив эту коляску, да ещё с большим количеством
                                аксессуаров практически в пол цены коляски, после первого дня использования,
                                понимаешь что это выкинутые деньги, и что цена и качество этой коляски несоизмеримы ??
                                Я так и не понял с чего складывается такая цена, но зайдя в обычный Детский
                                Мир я покатал коляски в ценовом диапазоне 30 рублей и понял, что там коляски даже
                                функциональнее и комфортнее есть???? короче эта коляска - выкинутые деньги!!!
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div class="product-review__footer">
                <a href="#" class="product-review__button-view-reviews button button_size_m button_theme_grey">Показать еще 20</a>
                <div class="product-review__pagination-count">Показано 20 из 205 отзывов</div>
            </div>
        </div>`,
});