function hideable() {
  $('.hideable__header-toggler').on('click', e => {
    $(e.target)
      .parents('.hideable')
        .toggleClass('hideable_active')
        .find('.hideable__body')
          .slideToggle(200);
  });
}

export default hideable;