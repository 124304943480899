import Swiper from 'swiper/dist/js/swiper.min.js';

function picturePreviewSlider2() {
    let $picturePreviewSlider = $('.js-picture-preview-slider2');

    $picturePreviewSlider.each(function(i, slider) {
        let $picturePreviewSliderMain = $(slider).find('[data-slider="main"]'),
            $picturePreviewSliderMainContainer = $picturePreviewSliderMain.find('.swiper-container'),
            $picturePreviewSliderMainArrowPrev = $picturePreviewSliderMain.find('.swiper-button-prev'),
            $picturePreviewSliderMainArrowNext = $picturePreviewSliderMain.find('.swiper-button-next'),
            $picturePreviewThumbs = $(slider).find('[data-slider="thumbs"]'),
            $picturePreviewThumbsArrowPrev = $picturePreviewThumbs.find('.swiper-button-blur-button-prev'),
            $picturePreviewThumbsArrowNext = $picturePreviewThumbs.find('.swiper-button-blur-button-next'),
            $picturePreviewThumbsContainer = $picturePreviewThumbs.find('.swiper-container'),
            countThumbsSlide = $picturePreviewThumbs.find('.swiper-slide').length;

        if(countThumbsSlide > 6) {
            $picturePreviewThumbs.removeClass('is-center');
        }

        const picturePreviewThumbsObj = new Swiper($picturePreviewThumbsContainer, {
            spaceBetween: 7,
            slidesPerView: 'auto',
            freeMode: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            navigation: {
                nextEl: $picturePreviewThumbsArrowNext,
                prevEl: $picturePreviewThumbsArrowPrev,
            },
            watchOverflow: true,
        });

        const picturePreviewSliderObj = new Swiper($picturePreviewSliderMainContainer, {
            loop: true,
            slidesPerView: 1,
            spaceBetween: 0,
            simulateTouch: false,
            noSwiping: true,
            speed: 700,
            navigation: {
                nextEl: $picturePreviewSliderMainArrowNext,
                prevEl: $picturePreviewSliderMainArrowPrev,
            },
            thumbs: {
                swiper: picturePreviewThumbsObj,
            },
            preloadImages: false,
            lazy: {
                loadPrevNext: true,
                loadPrevNextAmount: 1,
            },
            watchOverflow: true,
        });
    });
}

export default picturePreviewSlider2;