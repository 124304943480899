import Vue from 'vue';
import noUiSlider from '../../vendors/nouislider.js';

Vue.component('range-slider-ui-price', {
    props: ['name', 'valName', 'min', 'max', 'step', 'buttons'],
    data() {
        return {

        };
    },
    mounted() {
        this.initUiSlider();
    },
    methods: {
        initUiSlider() {
            let _this = this,
                $sliderUIRange = $(_this.$el);

            $sliderUIRange.each(function(index, box) {
                let slick = $(box).find('[data-action="slick"]'),
                    min = parseInt(_this.min),
                    max = parseInt(_this.max),
                    step = parseInt(_this.step);

                noUiSlider.create(slick[0], {
                    animate: true,
                    animationDuration: 300,
                    start: [min, max],
                    connect: true,
                    //tooltips: [wNumb({decimals: 0}), wNumb({decimals: 0})],
                    range: {
                        'min': min,
                        'max': max,
                    },
                    step: step,
                });

                // slick[0].noUiSlider.on('update', function (values, handle) {
                //     inputValue.val(parseFloat(values[0], 0) + ' - ' + parseFloat(values[1], 0));
                // });
                //
                // slick[0].noUiSlider.on('start', function (values, handle) {
                //     $(box).addClass('is-slick');
                // });
                //
                // slick[0].noUiSlider.on('end', function (values, handle) {
                //     $(box).removeClass('is-slick');
                // });
            });
        },
    },
    template:
        `<div class="slider-ui">
            <div class="slider-ui__slide">
                <div class="slider-ui__value">
                    <div class="slider-ui__value-row">
                        <div class="slider-ui__value-box slider-ui__value-box_min">
                            <span class="slider-ui__value-box-hint">от</span>
                            <input type="text" class="slider-ui__value-input" :name="name+'_MIN'" v-model="min">
                        </div>
                        
                        <div class="slider-ui__value-box slider-ui__value-box_max">
                            <span class="slider-ui__value-box-hint">до</span>
                            <input type="text" class="slider-ui__value-input" :name="name+'_MAX'" v-model="max">
                        </div>
                    </div>
                    
                    <span class="slider-ui__value-text">{{ valName }}</span>
                </div>
                
                <div data-action="slick" class="slider-ui__slick"></div>
            </div>
            
            <div v-if="buttons === 'Y'" class="slider-ui__buttons">
                <div class="slider-ui__btn">До 20 000 ₽</div>
                <div class="slider-ui__btn">До 30 000 ₽</div>
                <div class="slider-ui__btn">До 40 000 ₽</div>
                <div class="slider-ui__btn">До 50 000 ₽</div>
                <div class="slider-ui__btn">До 60 000 ₽</div>
                <div class="slider-ui__btn cur">До 70 000 ₽</div>
                <div class="slider-ui__btn">До 80 000 ₽</div>
                <div class="slider-ui__btn">До 90 000 ₽</div>
            </div>
        </div>`,
});