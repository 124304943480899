import Vue from 'vue';
import filterParametr from './filterParametr';
import filterOptionSwitch from './filterOptionSwitch';

import filterParametrPrice from './filterParametrPrice';
import rangeSliderUI from './rangeSliderUI';
import rangeSliderUI_Price from './rangeSliderUI_Price';
import lazyImages from '../../components/lazy';
import modalFilter from './modalFilter';
import selectSortProductsCatalog from "../../components/selectSortProductsCatalog";
import axios from 'axios';

Vue.component('filter-desktop', {
    data() {
        return {

        };
    },
    mounted() {
    },
    methods: {
        showModalFilterInit() {
            /*
            *
            * Метод открытия модального окна из компонента modalFilter
            * */
            this.$root.$emit('openFilter');
        },

        clearFilters() {
            this.$refs.modalFilter.clearFiltres()
        },

        lightResetPriceFilter() {
            this.$refs.priceFilter.lightReset()
        },
    },
    template:
        `<div>
            <div class="filter">
                <filter-parametr-price ref="priceFilter" class="filter__parametr" name="Цена" min="0" max="300000" step="1" parametr-name="FILTER_PRICE" val-name="₽"></filter-parametr-price>
                
                <button @click.prevent="showModalFilterInit()" type="button" class="filter__button-all-params">
                    <svg width="14" height="12" class="icon icon-filter-param"><use xlink:href="#icon-filter-param"></use></svg>
                    Все параметры
                </button>
            
                <button type="button" class="filter__button-reset" @click.prevent="clearFilters()">Очистить фильтр</button>
            </div>
    
            <modal-filter @clearPriceFilter="lightResetPriceFilter" ref="modalFilter"></modal-filter>
        </div>`,
});