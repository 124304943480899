function catalog() {
  const nav = document.querySelector('.catalog__selected');
  let isNavShown = false;

  if (!nav) {
    return;
  }

  function stickyNav() {
    const el = document.querySelector('.catalog__actions');
    const pos = el.getBoundingClientRect();
    const offset = pos.top + pos.height + parseInt(window.getComputedStyle(el).marginBottom, 10);

    if (offset <= 0 && !isNavShown) {
      nav.classList.add('-fixed');
      isNavShown = true;
    }
    if (offset > 0 && isNavShown) {
      nav.classList.remove('-fixed');
      isNavShown = false;
    }
  }

  window.addEventListener('scroll', stickyNav, false);
  window.addEventListener('resize', stickyNav, false);

  stickyNav();
}

export default catalog;
