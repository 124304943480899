import $ from 'jquery';

function delivery() {
  $(document).on('click', '[data-target-map]', function(e) {
    let targetMap = $(this).data('targetMap')

    $('.zone').hide()

    $(`[data-map="${targetMap}"]`).show()
  })

  $('.zone:not(:first-child)').hide()
}

export default delivery;
