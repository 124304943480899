import Vue from 'vue';

Vue.component('filter-mobile-parametr', {
    props: ['name', 'values', 'params'],
    data() {
        return {
            showDropdown: false,
            count: 0
        };
    },
    mounted() {
        if (this.params[this.name] != undefined) { 
        }
    },
    computed: {
        checkedParams() {
            let values = this.params[this.name]

            if (values != undefined) {
                return values.join(',')
            } else {
                return undefined
            }
        }
    },

    methods: {
        open() {
            let _this = this;

            _this.showDropdown = !_this.showDropdown;
        },
        close() {
            this.showDropdown = false;
        },
        selectParam(name, item) {
            if (this.params[name] == undefined) {
                this.params[name] = [item]
            } else {
                let i = this.params[name].indexOf(item)
                if (i >= 0) {
                    this.params[name].splice(i, 1)
                } else {
                    this.params[name].push(item)
                }          
            }
            console.log(this.params[name])
        },
        applyFilter() {
            this.$emit('applyFilters')
        },
        isChecked(name, item) {
            let currentParams = this.params[name]

            if (currentParams == undefined) { return false }
            if (currentParams.indexOf(item) >= 0) { return true }

            return false
        }
    },
    template:
        `<div class="filter-mobile-parametr">
            <button @click.prevent="open()" type="button" class="filter-mobile-parametr__head">
                <span class="filter-mobile-parametr__head-name">{{name}}</span>
                <span v-if="checkedParams" class="filter-mobile-parametr__head-values">
                    <span class="filter-mobile-parametr__head-values-text">{{ checkedParams }}</span>
                </span>
            </button>
            
            <div v-show="showDropdown" class="filter-mobile-parametr__layout-panel">
                <div @click="close()" class="filter-mobile-parametr__layout-panel-header">
                    <span class="filter-mobile-parametr__layout-panel-header-title">{{name}}</span>
                </div>
                
                <div class="filter-mobile-parametr__layout-panel-list">
                    <div v-for="item in values" class="filter-mobile-parametr__layout-panel-parametr">
                        <label class="custom-checkbox">
                            <input type="checkbox" class="custom-checkbox__input" :checked="isChecked(name, item)" @click="selectParam(name, item)">
                            <span class="custom-checkbox__value">
                                <span class="custom-checkbox__icon"></span>{{ item }}
                            </span>
                        </label>
                    </div>
                </div>
                
                <div class="filter-mobile-parametr__layout-panel-footer">
                    <button @click.prevent="applyFilter()" type="button" class="button button_theme_default button_size_m">Применить</button>
                </div>
            </div>
        </div>`,
});