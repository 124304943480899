import Vue from 'vue';

Vue.component('m-modal-review-form', {
    data() {
        return {
            id: null,
            m_showModalReviewForm: false,
        };
    },
    beforeMount() {
        this.uniqID();
    },
    mounted() {
        this.$root.$on('m_openReviewForm', () => {
            this.render();
        });
    },
    methods: {
        uniqID() {
            this.id = Math.random().toString(36).substr(2, 9);
        },
        render() {
            let _this = this,
                html = document.querySelector('html'),
                boxStep1 = document.querySelector('[data-step-animation="step-1"]'),
                boxStep2 = document.querySelector('[data-step-animation="step-2"]');

            this.m_showModalReviewForm = true;

            _this.$nextTick(function () {
                html.classList.add('is-open-modal-'+_this.id);
                boxStep1.dataset.animation = 'slideOutLeft2x';
                boxStep2.dataset.animation = 'slideOutLeft';
                _this.$el.dataset.stepAnimation = 'step-3';
            });
        },
        close() {
            let boxStep1 = document.querySelector('[data-step-animation="step-1"]'),
                boxStep2 = document.querySelector('[data-step-animation="step-2"]');

            this.m_showModalReviewForm = false;

            boxStep1.dataset.animation = 'slideOutLeft';
            boxStep2.dataset.animation = 'slideInLeft';

            setTimeout(function() {
                boxStep2.removeAttribute('data-animation');
            }, 1000);
        },
    },
    template:
        `<transition name="slide-right" enter-active-class='is-animated slideInRight' leave-active-class='is-animated slideOutRight'>
            <div v-if="m_showModalReviewForm" class="popup-wrapper">
                <div class="popup-wrapper__container">
                    <div class="popup-wrapper__content">
                        <div class="popup-close" @click="close()"><svg class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg></div>  
                        <div class="popup-top-panel" @click="close()">
                            <div class="popup-back-link"></div>
                            <div class="popup-top-panel__text">Оставить отзыв</div>
                        </div>
                        <div class="popup">
                            <div class="popup__heading">
                                Напишите ваши впечатления
                            </div>
            
                            <form class="form-data">
                                <div class="form-field">
                                    <label class="custom-label">Достоинства</label>
                                    <textarea name="" class="custom-textarea theme-default" placeholder="Что вам понравилось?"></textarea>
                                </div>
            
                                <div class="form-field">
                                    <label class="custom-label">Недостатки</label>
                                    <textarea name="" class="custom-textarea theme-default" placeholder="Что не понравилось?"></textarea>
                                </div>
            
                                <div class="form-field">
                                    <label class="custom-label">Комментарий</label>
                                    <textarea name="" class="custom-textarea theme-default" placeholder="Другие впечатления"></textarea>
                                </div>
            
                                <div class="form-field">
                                    <label class="custom-label">Ваше имя</label>
                                    <input type="text" class="custom-input theme-default" placeholder="Представьтесь" name="">
                                </div>
            
                                <div class="form-data__footer">
                                    <button class="button button_theme_default button_size_m">Отправить</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </transition>`,
});