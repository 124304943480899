import Vue from 'vue';

Vue.component('m-product-detail-sertificates', {
    data() {
        return {

        };
    },
    methods: {

    },
    template:
        `<div>
        <div class="section-layout-info section-layout-info_text section-layout-info_popup">
            <div class="section-layout-info__container">
                <div class="section-layout-info__inner">
                    <div class="section-layout-info__heading">
                        Сертификаты качества
                    </div>

                    <div class="section-layout-info__content">
                        <div class="sertificates-list">
                            <div class="sertificates-list__item article-preview">
                                <div class="article-preview__pic">
                                    <img src="img/contant/sertificate1.png" alt=""/>
                                </div>
                                <div class="article-preview__info">
                                    <div class="article-preview__title">Российская система качества</div>
                                    <div class="article-preview__text">
                                        Признанное качество: детские кроватки Micuna и Fiorellino – одни
                                        из лидеров по результатам исследования Российской системы качества
                                    </div>
                                    <a href="#" class="article-preview__view-full">Читать полностью</a>
                                </div>
                            </div>
                            <div class="sertificates-list__item article-preview">
                                <div class="article-preview__pic">
                                    <img src="img/contant/sertificate2.png" alt=""/>
                                </div>
                                <div class="article-preview__info">
                                    <div class="article-preview__title">
                                        Сертификат AIDIMA – Технологический институт мебели
                                    </div>
                                    <div class="article-preview__text">
                                        Перед началом использования все краски и лаки проходят обязательную
                                        сертификацию в AIDIMA – Технологическом институте мебели – и после
                                        прохождения всех экспертиз получают сертификат соответствия
                                        стандарту UNE-EN 71-3.
                                    </div>
                                    <a href="#" class="article-preview__view-full">Читать полностью</a>
                                </div>
                            </div>
                            <div class="sertificates-list__item article-preview">
                                <div class="article-preview__pic">
                                    <img src="img/contant/sertificate3.png" alt=""/>
                                </div>
                                <div class="article-preview__info">
                                    <div class="article-preview__title">
                                        Сертификат OEKO-TEX Standart 100
                                    </div>
                                    <a href="#" class="article-preview__view-full">Читать полностью</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>`,
});