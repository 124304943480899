import Vue from 'vue';
import axios from 'axios';

Vue.component('modal-lk-navigation-root', {
    data() {
        return {
            id: null,
            m_showModalLkNavigation: false,
            items: [],
        };
    },

    mounted() {
        console.log('Mounted'),
        this.$root.$on('openMobileLK', () => {
            this.render();
        });
    },
    methods: {
        render() {
            let _this = this,
                html = document.querySelector('html');

            this.m_showModalLkNavigation = true;
            this.fetchCurrentUser();
            _this.$nextTick(function () {
                html.classList.add('is-open-modal-'+_this.id);
                html.dataset.animation = 'slideOutLeft';
            });
        },
        close() {
            this.m_showModalLkNavigation = false;

            let html = document.querySelector('html');

            html.classList.remove('is-open-modal-' + this.id);
            html.dataset.animation = 'slideInLeft';

            setTimeout(function() {
                html.removeAttribute('data-animation');
            }, 1000);
        },
        fetchCurrentUser() {
            axios.get('/api/current_user').then((response) => {
                this.items = response.data
            }).catch((error) => {})
        },
    },
    template:
        `<transition name="slide-right" enter-active-class='is-animated slideInRight' leave-active-class='is-animated slideOutRight'>
        <div v-if="m_showModalLkNavigation" class="popup-wrapper popup-modal" style="z-index: 300;">
            <div class="popup-wrapper__container">
                <div class="popup-wrapper__content">
                    <div class="popup-close" @click="close()"><svg class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg></div>  
                    <div class="popup-top-panel" @click="close()">
                        <div class="popup-back-link"></div>
                        <div class="popup-top-panel__text">Личный кабинет</div>
                    </div>
                    <nav class="mobile-panel-navigation">
                        <ul class="mobile-panel-navigation__list">
                            <li class="mobile-panel-navigation__item">
                                <a href="/profile" class="mobile-panel-navigation__link mobile-panel-navigation__link_inline">
                                {{items.email}}
                                </a>
                            </li>
                            <li class="mobile-panel-navigation__item">
                            <a href="/orders" class="mobile-panel-navigation__link mobile-panel-navigation__link_inline">
                            Заказы
                            </a>
                        </li>
                        <li class="mobile-panel-navigation__item">
                            <a href="/users/product_reviews" class="mobile-panel-navigation__link mobile-panel-navigation__link_inline">
                            Отзывы
                            </a>
                        </li>
                        <li class="mobile-panel-navigation__item">
                            <a href="/profile" class="mobile-panel-navigation__link mobile-panel-navigation__link_inline">
                            Профиль
                            </a>
                        </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
        </transition>`,
});