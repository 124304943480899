import Vue from 'vue';
import shop from '../components/shop';

Vue.component('m-modal', {
    data() {
        return {
            id: null,
        };
    },
    beforeMount() {
        this.uniqID();
    },
    mounted() {
        shop();
    },
    methods: {
        uniqID() {
            this.id = Math.random().toString(36).substr(2, 9);
        },
        render() {
            let _this = this,
                html = document.querySelector('html');

            _this.$nextTick(function () {
                html.classList.add('is-open-modal');
                html.dataset.animation = 'slideOutLeft';
            });
        },
        onEscapeKeyUp (event) {
            if (event.which === 27) {
                this.$emit('close');
            }
        },
    },
    template:
        `<div class="popup-wrapper popup-modal">
            <div class="popup-wrapper__container">
                <div class="popup-wrapper__content">
                    <div class="popup-close" @click="$emit('close')"><svg class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg></div>  
                    <slot name="header"></slot>      
                    <slot name="body"></slot>
                </div>
            </div>
        </div>`,
});