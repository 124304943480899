class FilterParamsService {
  constructor() {
    let searchParams = new URLSearchParams(window.location.search)
    this.params = {}

    for (let p of searchParams) {
      let key = p[0].replace('[]', '')
      let value = p[1]

      if (key == 'min_price' || key == 'max_price' || key == 'order') {
        this.params[key] = value
        continue
      }

      if (this.params[key] == undefined) {
        this.params[key] = [value]
      } else {
        this.params[key].push(value)
      }
    }
  }
}

export default FilterParamsService;