function compare() {
  const $page = document.querySelector('.compare');
  let isNavShown = false;

  if (!$page) {
    return;
  }

  function toggleData(e) {
    const btn = e.target.closest('.compare__group-head');

    if (!btn) {
      return;
    }

    btn.parentNode.classList.toggle('-active');
  }

  function stickyNav() {
    const nav = document.querySelector('.compare__nav');
    const isMobile = window.innerWidth < 768;

   
      const el = document.querySelector('.compare-head');
      const pos = el.getBoundingClientRect();
      const offset = nav.clientHeight;

      if (pos.top + pos.height <= offset && !isNavShown) {
        nav.classList.add('-active');
        isNavShown = true;
      }
      if (pos.top + pos.height > offset && isNavShown) {
        nav.classList.remove('-active');
        isNavShown = false;
      }
    
  }

  document.addEventListener('click', toggleData, false);
  window.addEventListener('scroll', stickyNav, false);
  window.addEventListener('resize', stickyNav, false);

  stickyNav();
}

export default compare;
