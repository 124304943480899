<template>
  <div>
    <div v-if="order_id">
      <div class="page-header page-header_single page-header_min">
        <div class="custom-container">
          <p>
            Заказ успешно оформлен!<br>
            Номер вашего заказа {{ order_id }}.<br>
            Наши операторы свяжутся с Вами в ближайшее время.
          </p>
        </div>
        
        <form action="/orders/pay" id="newOrderPayment" style="display: none;" method="POST">
          <input type="text" :value="order_id" name="order_id">
          <input type="text" :value="csrfToken" name="authenticity_token">
          <input type="submit" value="Оплатить заказ">
        </form>
      </div>
    </div>
    <div v-else>
      <form @submit.prevent="save()">
        <p>
          Если Вы хотите видеть историю заказов в нашем магазине, <br/>
          пожалуйста зарегистрируйтесь в личном кабинете.
        </p>
        <div class="user-profile__block">
          <div class="settings-form">
            <div class="form-inputs">
              <div class="settings-form__line">
                <div class="settings-form__line-input">
                  <label for="order_pay_as" class="select optional">Тип плательщика</label>
                  <select v-model="order.pay_as" class="select optional custom-input theme-default">
                    <option :value="pay_as" v-for="(pay_as, index) in payment_as">
                      {{ pay_as }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div v-if="order.pay_as == 'Оплачиваю как Физ. лицо'">

              <div class="form-inputs">
                <div class="settings-form__line">
                  <div class="settings-form__line-input">
                    <label for="order_city" class="string required"><abbr title="required">*</abbr> Город / Населенный пункт</label>
                    <input type="text" v-model="order.city" v-on:change="calculateDelivery" class="custom-input theme-default">
                    <span v-if="errors.city" v-for="error in errors.city" style="color: red;">{{ error }}</span>
                  </div>
                </div>
              </div>

              <div class="form-inputs">
                <div class="settings-form__line">
                  <div class="settings-form__line-input">
                    <label for="order_street" class="string required"><abbr title="required">*</abbr> Улица</label>
                    <input type="text" v-model="order.street" class="custom-input theme-default">
                    <span v-if="errors.street" v-for="error in errors.street" style="color: red;">{{ error }}</span>
                  </div>
                </div>
              </div>

              <div class="form-inputs">
                <div class="settings-form__line">
                  <div class="settings-form__line-input">
                    <label for="order_house" class="string required"><abbr title="required">*</abbr> Дом</label>
                    <input type="text" v-model="order.house" class="custom-input theme-default">
                    <span v-if="errors.house" v-for="error in errors.house" style="color: red;">{{ error }}</span>
                  </div>
                </div>
              </div>

              <div class="form-inputs">
                <div class="settings-form__line">
                  <div class="settings-form__line-input">
                    <label for="order_flat" class="string required">Квартира</label>
                    <input type="text" v-model="order.flat" class="custom-input theme-default">
                    <span v-if="errors.flat" v-for="error in errors.flat" style="color: red;">{{ error }}</span>
                  </div>
                </div>
              </div>

              <p v-if="showDeliveryMessage" style="color: red;">
                {{ this.deliveryMessage }}
              </p>

              <p v-if="crateSum > 0" style="color: gray;">
                Стоимость обрешетки {{ crateSum }} руб.<br/>
                Оплачивается отдельно
              </p>

              <div class="form-inputs">
                <div class="settings-form__line">
                  <div class="settings-form__line-input">
                    <label for="order_porch" class="string optional">Подъезд</label>
                    <input type="text" v-model="order.porch" class="custom-input theme-default">
                  </div>
                </div>
              </div>

              <div class="form-inputs">
                <div class="settings-form__line">
                  <div class="settings-form__line-input">
                    <label for="order_floor" class="string optional">Этаж</label>
                    <input type="text" v-model="order.floor" class="custom-input theme-default">
                  </div>
                </div>
              </div>

              <div class="form-inputs">
                <div class="settings-form__line">
                  <div class="settings-form__line-input">
                    
                    <label for="order_elevator" class="boolean optional checkbox">
                      <input type="checkbox" v-model="order.elevator" class="boolean optional"> Наличие лифта
                    </label>
                  </div>
                </div>
              </div>

              <div class="form-inputs">
                <div class="settings-form__line">
                  <div class="settings-form__line-input">
                    <label for="order_customer_name" class="string optional">Грузополучатель</label>
                    <input type="text" v-model="order.customer_name" class="custom-input theme-default">
                  </div>
                </div>
              </div>

              <div class="form-inputs">
                <div class="settings-form__line">
                  <div class="settings-form__line-input">
                    <label for="order_take_self" class="boolean optional checkbox">
                      <input type="checkbox" v-model="order.take_self" class="boolean optional"> Буду получать самостоятельно
                    </label>
                  </div>
                </div>
              </div>

              <div class="form-inputs">
                <div class="settings-form__line">
                  <div class="settings-form__line-input">
                    <label for="order_comment" class="text optional">Комментарий</label>
                    <input type="text" v-model="order.comment" class="custom-input theme-default">
                  </div>
                </div>
              </div>

              <div class="form-inputs">
                <div class="settings-form__line">
                  <div class="settings-form__line-input">
                    <label for="order_delivery" class="select optional">Доставка</label>
                    <select v-model="order.delivery" class="select optional custom-input theme-default">
                      <option :value="delivery" v-for="delivery in deliveries">{{ delivery }}</option> 
                    </select>
                  </div>
                </div>
              </div>

              <div class="form-inputs">
                <div class="settings-form__line">
                  <div class="settings-form__line-input">
                    <label for="order_payment" class="select optional">Оплата</label>
                    <select v-model="order.payment" class="select optional custom-input theme-default">
                      <option :value="payment" v-for="payment in payments">{{ payment }}</option> 
                    </select>
                  </div>
                </div>
              </div>

              <div class="form-inputs">
                <div class="settings-form__line">
                  <div class="settings-form__line-input">
                    <label class="string optional"><abbr title="required">*</abbr>Имя</label>
                    <input type="text" v-model="order.first_name" class="custom-input theme-default">
                    <span v-if="errors.first_name" v-for="error in errors.first_name" style="color: red;">{{ error }}</span>
                  </div>
                </div>
              </div>

              <div class="form-inputs">
                <div class="settings-form__line">
                  <div class="settings-form__line-input">
                    <label class="string optional"><abbr title="required">*</abbr>Фамилия</label>
                    <input type="text" v-model="order.last_name" class="custom-input theme-default">
                    <span v-if="errors.last_name" v-for="error in errors.last_name" style="color: red;">{{ error }}</span>
                  </div>
                </div>
              </div>

              <div class="form-inputs">
                <div class="settings-form__line">
                  <div class="settings-form__line-input">
                    <label for="order_middle_name" class="string optional"><abbr title="required">*</abbr>Отчество</label>
                    <input type="text" v-model="order.middle_name" class="custom-input theme-default">
                    <span v-if="errors.middle_name" v-for="error in errors.middle_name" style="color: red;">{{ error }}</span>
                  </div>
                </div>
              </div>

              <div class="form-inputs">
                <div class="settings-form__line">
                  <div class="settings-form__line-input">
                    <label for="order_phone" class="tel optional"><abbr title="required">*</abbr>Телефон</label>
                    <input type="text" v-model="order.phone" class="custom-input theme-default">
                    <span v-if="errors.phone" v-for="error in errors.phone" style="color: red;">{{ error }}</span>
                  </div>
                </div>
              </div>

              <div class="form-inputs">
                <div class="settings-form__line">
                  <div class="settings-form__line-input">
                    <label class="tel optional">eMail</label>
                    <label class="tel optional"><abbr title="required">*</abbr>eMail</label>
                    <input type="email" v-model="order.email" class="custom-input theme-default">
                    <span v-if="errors.email" v-for="error in errors.email" style="color: red;">{{ error }}</span>
                  </div>
                </div>
              </div>

            </div>

            <div v-else>
              <div class="form-inputs">
                <div class="settings-form__line">
                  <div class="settings-form__line-input">
                    <label for="company_user" class="string required"><abbr title="required">*</abbr>Контактное лицо</label>
                    <input type="text" v-model="order.company_user" class="custom-input theme-default">
                    <span v-if="errors.company_user" v-for="error in errors.company_user" style="color: red;">{{ error }}</span>
                  </div>
                </div>
              </div>

              <p style="color: red;">
                {{ this.deliveryMessage }}
              </p>

              <p v-if="crateSum > 0" style="color: gray;">
                Стоимость обрешетки {{ crateSum }} руб.<br/>
                Оплачивается отдельно
              </p>

              <div class="form-inputs">
                <div class="settings-form__line">
                  <div class="settings-form__line-input">
                    <label for="order_payment" class="select optional">Оплата</label>
                    <select v-model="order.payment" class="select optional custom-input theme-default">
                      <option :value="order.payment">{{ order.payment }}</option> 
                    </select>
                  </div>
                </div>
              </div>

              <div class="form-inputs">
                <div class="settings-form__line">
                  <div class="settings-form__line-input">
                    <label for="email" class="string required"><abbr title="required">*</abbr>e-Mail</label>
                    <input type="text" v-model="order.email" class="custom-input theme-default">
                    <span v-if="errors.city" v-for="error in errors.email" style="color: red;">{{ error }}</span>
                  </div>
                </div>
              </div>

              <div class="form-inputs">
                <div class="settings-form__line">
                  <div class="settings-form__line-input">
                    <label for="phone" class="string required"><abbr title="required">*</abbr>Телефон</label>
                    <input type="text" v-model="order.phone" class="custom-input theme-default">
                    <span v-if="errors.phone" v-for="error in errors.phone" style="color: red;">{{ error }}</span>
                  </div>
                </div>
              </div>

              <div class="form-inputs">
                <div class="settings-form__line">
                  <div class="settings-form__line-input">
                    <label for="company_address" class="string required"><abbr title="required">*</abbr>Адрес доставки заказа</label>
                    <input type="text" v-model="order.company_address" class="custom-input theme-default">
                    <span v-if="errors.company_address" v-for="error in errors.company_address" style="color: red;">{{ error }}</span>
                  </div>
                </div>
              </div>

              <div class="form-inputs">
                <div class="settings-form__line">
                  <div class="settings-form__line-input">
                    <label for="company_name" class="string required"><abbr title="required">*</abbr>Название компании</label>
                    <input type="text" v-model="order.company_name" class="custom-input theme-default">
                    <span v-if="errors.company_name" v-for="error in errors.company_name" style="color: red;">{{ error }}</span>
                  </div>
                </div>
              </div>

              <div class="form-inputs">
                <div class="settings-form__line">
                  <div class="settings-form__line-input">
                    <label for="legal_address" class="string required"><abbr title="required">*</abbr>Юридический адрес</label>
                    <input type="text" v-model="order.legal_address" class="custom-input theme-default">
                    <span v-if="errors.legal_address" v-for="error in errors.legal_address" style="color: red;">{{ error }}</span>
                  </div>
                </div>
              </div>


              <div class="form-inputs">
                <div class="settings-form__line">
                  <div class="settings-form__line-input">
                    <label for="inn" class="string required"><abbr title="required">*</abbr>ИНН</label>
                    <input type="text" v-model="order.inn" class="custom-input theme-default">
                    <span v-if="errors.inn" v-for="error in errors.inn" style="color: red;">{{ error }}</span>
                  </div>
                </div>
              </div>


              <div class="form-inputs">
                <div class="settings-form__line">
                  <div class="settings-form__line-input">
                    <label for="kpp" class="string required"><abbr title="required">*</abbr>КПП</label>
                    <input type="text" v-model="order.kpp" class="custom-input theme-default">
                    <span v-if="errors.kpp" v-for="error in errors.kpp" style="color: red;">{{ error }}</span>
                  </div>
                </div>
              </div>

              <div class="form-inputs">
                <div class="settings-form__line">
                  <div class="settings-form__line-input">
                    <label for="okpo" class="string required"><abbr title="required">*</abbr>ОКПО</label>
                    <input type="text" v-model="order.okpo" class="custom-input theme-default">
                    <span v-if="errors.okpo" v-for="error in errors.okpo" style="color: red;">{{ error }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-inputs">
              <div class="settings-form__line">
                <div class="settings-form__line-input">
                  <vue-recaptcha 
                    sitekey="6LdCUzMcAAAAAFKeD3P-_0tL5YGSvOGf0QOycLH4"
                    ref="recaptcha"
                    @verify="onVerify"
                    @expired="onExpired"
                  ></vue-recaptcha>
                </div>
              </div>
            </div>

                
            <div class="user-profile__block">
              <button class="button button_theme_default button_size_m">Заказать</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import VueRecaptcha from 'vue-recaptcha'

export default {
  props: ['payments', 'deliveries', 'payment_as'],
  data() {
    return {
      order: {
        pay_as: this.payment_as[0],
        city: '',
        street: '',
        house: '',
        porch: '',
        floor: '',
        elevator: false,
        customer_name: '',
        take_self: false,
        comment: '',
        delivery: this.deliveries[0],
        payment: this.payments[0],
        first_name: '',
        last_name: '',
        middle_name: '',
        phone: '',
        email: '',
        delivery_price: null
      },
      crateSum: 0,
      errors: {},
      order_id: null,
      captcha_token: '',
      csrfToken: '',
      deliveryMessage: 'Стоимость доставки расчитывается оператором',
      showDeliveryMessage: false
    }
  },

  watch: {
    'order.pay_as': function (val) {
      if (val == "Оплачиваю как Юр. лицо") {
        this.showDeliveryMessage = true
        this.deliveryMessage = 'Стоимость доставки расчитывается оператором'
        this.order.delivery_price = null
        this.order.payment = 'Безналичный расчет'
      } else {
        this.calculateDelivery()
      }
    }
  },
  
  components: { 'vue-recaptcha': VueRecaptcha },

  mounted() {
    console.log(this.payment_as)
    this.order = Object.assign(this.order, gon.current_user)
    this.csrfToken = document.querySelector("meta[name='csrf-token']").content

    setInterval(() => {
      this.checkCrate()
    }, 3000)
  },

  methods: {
    checkCrate() {
      console.log('check')
      if (this.order.city.length == 0) { return }
      
      axios.get('/api/crates.json', { 
        params: { address: this.order.city } 
      }).then((response) => {
        this.crateSum = 0
        this.crateSum = response.data.crate_sum
      })
    },

    calculateDelivery() {
      if (this.order.city.length > 0) {
        this.showDeliveryMessage = true
        axios.get(`/api/delivery_maps?address=${this.order.city}`).then((response) => {
          let price = response.data.result
          if (price == 0) {
            this.deliveryMessage = 'Бесплатная доставка'
            this.order.delivery_price = price
          } else if (price > 0) {
            this.deliveryMessage = `Стоимость доставки ${price} руб.`
            this.order.delivery_price = price
          } else {
            this.deliveryMessage = 'Стоимость доставки расчитывается оператором'
            this.order.delivery_price = null
          }
        }).catch((error) => {
          this.deliveryMessage = 'Стоимость доставки расчитывается оператором'
        })
      } else {
        this.deliveryMessage = 'Стоимость доставки расчитывается оператором'
      }
    },

    save() {
      if (this.captcha_token == '') {
        alert('Вы не разгадали капчу')
        return
      }

      axios.post('/api/orders', { 
        order: this.order,
        captcha_token: this.captcha_token
      }).then((response) => {
        this.order_id = response.data.id

        var line_items = gon.cart.line_items;
        var products = line_items.map(function(i) {
          return { 
            id: i.articul,
            name: i.title,
            quantity: i.quantity
          }
        })

        window.dataLayer.push({
            "ecommerce": {
                "currencyCode": "RUB",
                "purchase" : {
                  "actionField": {
                      "id" : response.data.id
                  },
                  "products": products
                }
            }
        });

        document.querySelector('h1').scrollIntoView()

        if (this.order.payment == 'Банковской картой на сайте') {
          setTimeout(function() {
            document.querySelector('#newOrderPayment').submit()
          }, 500)
        }
      }).catch((errors) => {
        this.errors = errors.response.data.errors
        this.$refs.recaptcha.reset()
      })
    },

    onVerify: function (response) {
        this.captcha_token = response
    },

    onExpired: function () {
        this.captcha_token = ''
    },

    resetRecaptcha () {
      this.$refs.recaptcha.reset() // Direct call reset method
    },
  }
}
</script>