import Vue from 'vue';
import axios from 'axios';
import searchWidget from './searchWidget'

Vue.component('modal-mobile-search', {
    data() {
        return {
            id: null,
            m_modalMobileSearch: false,
            items: [],
        };
    },

    mounted() {
        console.log('Mounted'),
        this.$root.$on('openMobileSearch', () => {
            this.render();
        });
    },
    methods: {
        render() {
            let _this = this,
                html = document.querySelector('html');

            this.m_modalMobileSearch = true;
            _this.$nextTick(function () {
                html.classList.add('is-open-modal-'+_this.id);
                html.dataset.animation = 'slideOutLeft';
            });
        },
        close() {
            this.m_modalMobileSearch = false;

            let html = document.querySelector('html');

            html.classList.remove('is-open-modal-' + this.id);
            html.dataset.animation = 'slideInLeft';

            setTimeout(function() {
                html.removeAttribute('data-animation');
            }, 1000);
        },
    },
    template:
        `<transition name="slide-right" enter-active-class='is-animated slideInRight' leave-active-class='is-animated slideOutRight'>
        <div v-if="m_modalMobileSearch" class="popup-wrapper popup-modal" style="z-index: 300;">
            <div class="popup-wrapper__container">
                <div class="popup-wrapper__content">
                    <div class="popup-close" @click="close()"><svg class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg></div>  
                    <div class="popup-top-panel">
                        <div class="popup-back-link" @click="close()"></div>
                        <search-widget></search-widget>
                    </div>
                    
                    <nav class="mobile-panel-navigation">
                    </nav>
                </div>
            </div>
        </div>
        </transition>`,
});