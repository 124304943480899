class Price {
  static differencePricesInPercents(price, old_price) {
    let percents = 100 - ((price / old_price) * 100)
    return Math.round(percents)
  }

  static format(number) {
    let stringNumber = number.toString()
    let oldPriceArray = stringNumber.split('').reverse()
    let newPriceArray = []

    for (let i = 0; i < oldPriceArray.length; i++) {
      if (i != 0 && i % 3 == 0) { newPriceArray.push(' ') }
      newPriceArray.push(oldPriceArray[i])
    }

    return newPriceArray.reverse().join('')
  }
}

export default Price