import Vue from 'vue';
import axios from 'axios';

Vue.component('modal-full-description', {
    data() {
        return {
            id: null,
            m_modalFullDescription: false,
            items: [],
        };
    },

    mounted() {
        console.log('Mounteddesc'),
        this.$root.$on('openMobileFullDescription', () => {
            console.log('renddesc');
            this.render();
        });
    },
    methods: {
        render() {
            let _this = this,
                html = document.querySelector('html');

            this.m_modalFullDescription = true;
            _this.$nextTick(function () {
                html.classList.add('is-open-modal-'+_this.id);
                html.dataset.animation = 'slideOutLeft';
            });
        },
        close() {
            this.m_modalFullDescription = false;

            let html = document.querySelector('html');

            html.classList.remove('is-open-modal-' + this.id);
            html.dataset.animation = 'slideInLeft';

            setTimeout(function() {
                html.removeAttribute('data-animation');
            }, 1000);
        },
    },
    props: ['progress'],
    template:
        `<transition name="slide-right" enter-active-class='is-animated slideInRight' leave-active-class='is-animated slideOutRight'>
        <div v-if="m_modalFullDescription" class="popup-wrapper popup-modal" style="z-index: 300;">
            <div class="popup-wrapper__container">
                <div class="popup-wrapper__content">
                    <div class="popup-close" @click="close()"><svg class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg></div>  
                    <div class="popup-top-panel" @click="close()">
                        <div class="popup-back-link"></div>
                        <div class="popup-top-panel__text">Подробное описание товара</div>
                    </div>
                    <div class = "modalFD">
                    <br>
                    <div class="section-layout-info__content row">
                    <div class="col-xs-1 col-md-1"></div>
              <div class="col-xs-10 col-md-10">
                    <div v-html = "progress.full_description">
                    </div>
                    <div class="col-xs-1 col-md-1"></div>
                    </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        </transition>`,
});