import Swiper from 'swiper/dist/js/swiper.min.js';

function articlesSlider() {
    let $articlesSlider = $('.js-articles-slider');

    $articlesSlider.each(function(i, slider) {
        let $slider = $(slider),
            $container = $slider.find('.swiper-container'),
            $arrowPrev = $slider.find('.swiper-button-prev'),
            $arrowNext = $slider.find('.swiper-button-next'),
            $sliderPagination = $slider.find('.swiper-pagination-custom');

        const articlesSliderObj = new Swiper($container, {
            slidesPerView: 'auto',
            spaceBetween: 10,
            speed: 700,
            freeMode: true,
            freeModeSticky: false,
            navigation: {
                nextEl: $arrowNext,
                prevEl: $arrowPrev,
            },
            pagination: {
                el: $sliderPagination,
                dynamicBullets: true,
            },
            breakpointsInverse: true,
            breakpoints: {
                768: {
                    spaceBetween: 20,
                },
                1025: {
                    pagination: false,
                },
            },
        });
    });
}

export default articlesSlider;