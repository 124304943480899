function compareSlider() {

    let $left = document.querySelector('.js_compare_slider_left');
    if($left){
      $left.addEventListener('click',function(){
          console.log("left")
          let content = document.querySelector(".compare");
          content.scrollLeft -=100
      },false)
    }
    let comp = document.querySelector(".compare");
    let prew = document.querySelector(".compare__nav-models");
  
    if(comp){

    comp.addEventListener('scroll',function()
    {0<$(this).scrollLeft()?$(this).find(".property-name").addClass("upper"):$(this).find(".property-name").removeClass("upper")
    if (prew){prew.scrollLeft = comp.scrollLeft;}

}, false)
}  
    if (prew){
        prew.addEventListener('scroll', function(){
            comp.scrollLeft = prew.scrollLeft;
        },false)
    }
    let $right = document.querySelector('.js_compare_slider_right');
      if($right){
      $right.addEventListener('click',function(){
          let content = document.querySelector(".compare");
          content.scrollLeft += 100;
      },false)
    }
    window.addEventListener('load', function(event) {
        Arrow();
    }, true);
    window.addEventListener('resize', function(event) {
        Arrow();
    }, true);
    function Arrow(){
        let comp = document.querySelector(".compare");
        var compw = getComputedStyle(comp).width;
        var compsw = comp.scrollWidth;
        var e = parseInt(compw, 10);
        var w = parseInt(compsw, 10);
        if (e < w){
            let arrow = document.querySelector(".arrow_left");
            arrow.style.visibility = "visible";
           
              let arrowr = document.querySelector(".arrow_right");
              arrowr.style.visibility = "visible";
        }
        else {
            let arrow = document.querySelector(".arrow_left");
            arrow.style.visibility = "hidden";
           
              let arrowr = document.querySelector(".arrow_right");
              arrowr.style.visibility = "hidden";

        }
    }
    
    
   
}

export default compareSlider;