import Vue from 'vue';
import axios from 'axios';
import noUiSlider from '../../vendors/nouislider';
import wNumb from '../../vendors/wNumb';
import lazyImages from '../../components/lazy';
import FilterParamsService from '../../services/catalog/filter_params_service';
import selectSortProductsCatalog from "../../components/selectSortProductsCatalog";

Vue.component('filter-mobile-parametr-price', {
    props: ['name', 'valName', 'min', 'max', 'step', 'parametrName', 'params'],
    data() {
        return {
            showDropdown: false,
            minRange: null,
            maxRange: null,
            slider: {
                startMin: this.min,
                startMax: this.max,
                min: this.min,
                max: this.max,
                step: this.step,
                valueText: '',
            },
        };
    },
    mounted() {
        this.initUiSlider();
    },

    computed: {
        valueText() {
            if (this.params['min_price'] != undefined && this.params['max_price']!= undefined) {
                return this.params['min_price'] + '-' + this.params['max_price'] + ' ' + this.valName
            }

            return null
        }
    },

    methods: {
        open() {
            let _this = this;

            _this.showDropdown = !_this.showDropdown;
        },
        close() {
            this.showDropdown = false;
        },
        initUiSlider() {
            let _this = this,
                $sliderUIRange = $(_this.$el);

            noUiSlider.create(this.$refs.slider, {
                start: [parseInt(this.slider.startMin), parseInt(this.slider.startMax)],
                step: parseInt(this.slider.step),
                range: {
                    'min': parseInt(this.slider.min),
                    'max': parseInt(this.slider.max),
                },
                connect: true,
                format: wNumb({
                    decimals: 0,
                    suffix: ' ₽',
                }),
                animate: true,
                animationDuration: 300,
            });

            this.$refs.slider.noUiSlider.on('update',(values, handle) => {
                this[handle ? 'maxRange' : 'minRange'] = parseInt(values[handle]);
            });
        },
        updateSlider: function() {
            this.$refs.slider.noUiSlider.set([this.minRange, this.maxRange]);
        },
        setSliderRange: function(price) {
            this.maxRange = price;

            this.$refs.slider.noUiSlider.set([0, this.maxRange]);
        },
        applyFilter: function() {
            this.slider.valueText = this.params['min_price'] + ' - ' +  this.params['min_price'] + ' ' + this.valName;

            this.$emit('applyPriceFilters', this.minRange, this.maxRange)

            this.close();
        },
    },
    template:
        `<div class="filter-mobile-parametr filter-mobile-parametr_price">
            <button @click.prevent="open()" type="button" class="filter-mobile-parametr__head">
                <span class="filter-mobile-parametr__head-name">{{name}}</span>
                <span v-if="valueText" class="filter-mobile-parametr__head-values">
                    <span class="filter-mobile-parametr__head-values-text">{{valueText}}</span>
                </span>
            </button>
            
            <div v-show="showDropdown" class="filter-mobile-parametr__layout-panel">
                <div @click="close()" class="filter-mobile-parametr__layout-panel-header">
                    <span class="filter-mobile-parametr__layout-panel-header-title">{{name}}</span>
                </div>
                
                <div class="filter-mobile-parametr__layout-panel-content">
                    <div class="slider-ui">
                        <div class="slider-ui__slide">
                            <div class="slider-ui__value">
                                <div class="slider-ui__value-row">
                                    <div class="slider-ui__value-box slider-ui__value-box_min">
                                        <span class="slider-ui__value-box-hint">от</span>
                                        <input data-name="min" type="text" class="slider-ui__value-input" :name="parametrName+'_MIN'" v-model="minRange" v-on:change="updateSlider">
                                    </div>
                                    
                                    <div class="slider-ui__value-box slider-ui__value-box_max">
                                        <span class="slider-ui__value-box-hint">до</span>
                                        <input data-name="max" type="text" class="slider-ui__value-input" :name="parametrName+'_MAX'" v-model="maxRange" v-on:change="updateSlider">
                                    </div>
                                </div>
                                
                                <span class="slider-ui__value-text">{{ valName }}</span>
                            </div>
                            
                            <div ref="slider" data-action="slick" class="slider-ui__slick"></div>
                        </div>
                        
                        <div class="slider-ui__buttons">
                            <div data-action="button" @click.prevent="setSliderRange(20000)" class="slider-ui__btn">До 20 000 ₽</div>
                            <div data-action="button" @click.prevent="setSliderRange(30000)" class="slider-ui__btn">До 30 000 ₽</div>
                            <div data-action="button" @click.prevent="setSliderRange(40000)" class="slider-ui__btn">До 40 000 ₽</div>
                            <div data-action="button" @click.prevent="setSliderRange(50000)" class="slider-ui__btn">До 50 000 ₽</div>
                            <div data-action="button" @click.prevent="setSliderRange(60000)" class="slider-ui__btn">До 60 000 ₽</div>
                            <div data-action="button" @click.prevent="setSliderRange(70000)" class="slider-ui__btn">До 70 000 ₽</div>
                            <div data-action="button" @click.prevent="setSliderRange(80000)" class="slider-ui__btn">До 80 000 ₽</div>
                            <div data-action="button" @click.prevent="setSliderRange(90000)" class="slider-ui__btn">До 90 000 ₽</div>
                        </div>
                    </div>
                </div>
                
                <div class="filter-mobile-parametr__layout-panel-footer">
                    <button @click.prevent="applyFilter()" type="button" class="button button_theme_default button_size_m">Применить</button>
                </div>
            </div>
        </div>`,
});