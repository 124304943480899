import Vue from 'vue';

Vue.component('m-sign-up', {
    data() {
        return {
            id: null,
            m_showModalSignUp: false,
        };
    },
    beforeMount() {
        this.uniqID();
    },
    mounted() {
        this.$root.$on('m_openSignUp', () => {
            this.render();
        });
    },
    methods: {
        uniqID() {
            this.id = Math.random().toString(36).substr(2, 9);
        },
        render() {
            let _this = this,
                html = document.querySelector('html');

            this.m_showModalSignUp = true;

            _this.$nextTick(function () {
                html.classList.add('is-open-modal-'+_this.id);
                html.dataset.animation = 'slideOutLeft';
            });
        },
        close() {
            this.m_showModalSignUp = false;

            let html = document.querySelector('html');

            html.classList.remove('is-open-modal-' + this.id);
            html.dataset.animation = 'slideInLeft';

            setTimeout(function() {
                html.removeAttribute('data-animation');
            }, 1000);
        },
    },
    template:
        `<transition name="slide-right" enter-active-class='is-animated slideInRight' leave-active-class='is-animated slideOutRight'>
        <div v-if="m_showModalSignUp" class="popup-wrapper popup-modal">
            <div class="popup-wrapper__container">
                <div class="popup-wrapper__content">
                    <div class="popup-close" @click="close()"><svg class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg></div>  
                    <div class="popup-top-panel" @click="close()">
                        <div class="popup-back-link"></div>
                        <div class="popup-top-panel__text">Регистрация</div>
                        <div class="mobile-panel__close"><svg width="13" height="13" class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg></div>
                    </div>
                    
                    <div slot="body" class="popup popup-sign">
                        <div class="mobile-panel-section">
                            <div class="popup-sign__form">
                                <div class="popup-sign__form-line">
                                    <div class="popup-sign__form-line-label">Телефон</div>
                                    <div class="popup-sign__form-line-input">
                                        <svg width="15" height="15" class="icon icon-check"><use xlink:href="#icon-check"></use></svg>
                                        <input type="text" class="custom-input theme-default" value="+7 981 652 62 61">
                                    </div>
                                </div>
                                <div class="popup-sign__form-line">
                                    <div class="popup-sign__form-line-label">Пароль <a href="">Забыли пароль?</a></div>
                                    <div class="popup-sign__form-line-input">
                                        <svg width="15" height="15" class="icon icon-check"><use xlink:href="#icon-check"></use></svg>
                                        <input type="password" class="custom-input theme-default" value="●●●●●●●●●">
                                    </div>
                                </div>
                                <div class="popup-sign__form-line">
                                  <button type="button" class="button button_theme_default button_size_l">Войти</button>
                                </div>
                            </div>
                        </div>
                        
                        <div class="mobile-panel-section">
                            <div class="popup-sign__social">
                                <div class="popup-sign__social-label">Войти через соц.сеть</div>
                                <div class="socials">
                                    <div class="social-links">
                                        <a href="#" title="vk" class="social-links__link">
                                            <svg width="16" height="9" class="icon icon-vk">
                                                <use xlink:href="#icon-vk"></use>
                                            </svg>
                                        </a>
                                        <a href="#" title="fb" class="social-links__link">
                                            <svg width="8" height="16" class="icon icon-fb">
                                                <use xlink:href="#icon-fb"></use>
                                            </svg>
                                        </a>
                                        <a href="#" title="ok" class="social-links__link">
                                            <svg width="11" height="18" class="icon icon-ok">
                                                <use xlink:href="#icon-ok"></use>
                                            </svg>
                                        </a>
                                        <a href="#" title="whatsapp" class="social-links__link">
                                            <svg width="16" height="16" class="icon icon-whatsapp">
                                                <use xlink:href="#icon-whatsapp"></use>
                                            </svg>
                                        </a>
                                        <a href="#" title="telegram" class="social-links__link">
                                            <svg width="16" height="14" class="icon icon-telegram">
                                                <use xlink:href="#icon-telegram"></use>
                                            </svg>
                                        </a>
                                    </div>
                                </div>                            </div>
                        </div>
                        
                        <div class="mobile-panel-section">
                            <button class="popup-sign__switch" type="button">Зарегистрироваться</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </transition>`,
});