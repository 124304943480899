import Vue from 'vue';
import axios from 'axios';
import VueRecaptcha from 'vue-recaptcha';

Vue.component('feedback-form', {
  data: function() {
    return {
      feedback: {
        department: 1,
        name: '',
        phone: '+7',
        message: ''
      },
      captcha_token: '',
      agree: false,
      agree_error: '',
      errors: {},
      success: false
    }
  },
  components: {
    'vue-recaptcha': VueRecaptcha
  },
  methods: {
    sendForm: function() {
      if (!this.agree) { 
        this.agree_error = 'Нужно согласиться на обработку персональных даннных'
        return
      }

      if (this.captcha_token == '') {
        alert('Вы не разгадали капчу')
        return
      }

      axios.post('/feedbacks', {
        feedback: this.feedback,
        captcha_token: this.captcha_token
      }).then((response) => {
        this.success = true
        console.log(response)
      }).catch((errors) => {
        this.errors = errors.response.data.errors
        this.resetRecaptcha()
      })
    },

    onVerify: function (response) {
      this.captcha_token = response
    },

    onExpired: function () {
      this.captcha_token = ''
    },

    resetRecaptcha () {
      this.$refs.recaptcha.reset() // Direct call reset method
    },
  },
  template: `
    <div class="feedback" v-if="!success">
      <div class="feedback__header">
        <h2>Готовы ответить <br>на любой вопрос</h2>
        <p>Возник вопрос или нужен совет по выбору, оставьте заявку и наши консультанты помогут вам</p>
      </div>
      <div class="feedback__form">
        <div class="feedback__form-fieldset">
          <div class="feedback__form-fieldset-info">
            <div class="form-field">
              <label class="custom-label">Имя</label>
              <input type="text" class="custom-input theme-default" v-model='feedback.name'>
              <span v-for="error in errors.name" style="color: red;">{{error}}</span>
            </div>
            <div class="form-field">
              <label class="custom-label">Телефон</label>
              <input type="tel" class="custom-input theme-default" v-model='feedback.phone'>
              <span v-for="error in errors.phone" style="color: red;">{{error}}</span>
            </div>
          </div>
          <div class="feedback__form-fieldset-message">
            <div class="form-field">
              <label class="custom-label">Сообщение</label>
              <textarea name="" class="custom-textarea theme-default" v-model="feedback.message"></textarea>
              <span v-for="error in errors.message" style="color: red;">{{error}}</span>
            </div>
          </div>
        </div>
        <div class="feedback__form-submit">
          <label class="custom-checkbox">
            <input type="checkbox" value="Y" name="RULE_FEEDBACK_QUESTION" class="custom-checkbox__input" v-model='agree'>
            <span class="custom-checkbox__value">
              <span class="custom-checkbox__icon"></span>
              Даю свое согласие на <a href="#" target="_blank">обработку персональных данных</a>
            </span>
            <span style="color: red;">{{ agree_error }}</span>
          </label>

          <label class="custom-checkbox">
            <vue-recaptcha 
              sitekey="6LdCUzMcAAAAAFKeD3P-_0tL5YGSvOGf0QOycLH4"
              ref="recaptcha"
              @verify="onVerify"
              @expired="onExpired"></vue-recaptcha>
          </label>

          <button type="button" class="button button_theme_default button_size_ms">
            <span class="hidden-b-m" @click="sendForm">Отправить</span>
            <span class="hidden-a-m" @click="sendForm">Перезвоните мне</span>
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <h3 style="text-align: center;">Ваше обращение принято!</h3>

      <p style="text-align: center;">
        Наши менеджеры свяжутся с вам в ближайшее время
      </p>
    </div>
  `
})