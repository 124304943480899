import Swiper from 'swiper/dist/js/swiper.min.js';

function article() {
    function initSliderTour() {
        let $sliders = $('.js-article-slider');

        $sliders.each(function(i, slider) {
            let $slider = $(slider),
                $sliderContainer = $slider.find('.swiper-container'),
                $sliderArrowPrev = $slider.find('.swiper-button-prev'),
                $sliderArrowNext = $slider.find('.swiper-button-next'),
                $sliderPagination = $slider.find('.swiper-pagination-custom');

            const actionsPromoSliderObj = new Swiper($sliderContainer, {
                freeMode: true,
                freeModeSticky: false,
                navigation: {
                    nextEl: $sliderArrowNext,
                    prevEl: $sliderArrowPrev,
                },
                pagination: {
                    el: $sliderPagination,
                    dynamicBullets: true,
                },
                watchOverflow: true,
                breakpointsInverse: true,
                slidesPerView: 1.06,
                spaceBetween: 10,
                breakpoints: {
                    600: {
                        slidesPerView: 2.06,
                    },
                    768: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                    },
                    1025: {
                        slidesPerView: 3,
                        spaceBetween: 168,
                    },
                },
            });
        });
    }

    initSliderTour();
}

export default article;
