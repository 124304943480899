import Swiper from 'swiper/dist/js/swiper.min.js';

function accessorizeSlider() {
    let $accessorizeSlider = $('.js-accessorize-slider');

    $accessorizeSlider.each(function(i, slider) {
        let $slider = $(slider),
            $sliderContainer = $slider.find('.swiper-container'),
            $sliderArrowPrev = $slider.find('.swiper-button-prev'),
            $sliderArrowNext = $slider.find('.swiper-button-next');

        const accessorizeSliderObj = new Swiper($sliderContainer, {
            slidesPerView: 'auto',
            spaceBetween: 0,
            navigation: {
                nextEl: $sliderArrowNext,
                prevEl: $sliderArrowPrev,
            },
            watchOverflow: true,
        });

    });
}

export default accessorizeSlider;