import Vue from 'vue';
//import mobileDropNavigation from '../components/mobileDropNavigation';

Vue.component('mobile-panel', {
    data() {
        return {
            m_mobilePanelShow: false,
            openNavStep: false,
        };
    },
    mounted() {
        this.$root.$on('m_openMobilePanel', () => {
            this.render();
        });
    },
    methods: {
        render() {
            let html = document.querySelector('html');

            this.m_mobilePanelShow = true;

            html.classList.add('is-open-mobile-panel');
            html.dataset.animation = 'slideOutRight';

            //mobileDropNavigation();
        },
        close() {
            let _this = this,
                html = document.querySelector('html');

            this.m_mobilePanelShow = false;

            html.classList.remove('is-open-mobile-panel');
            html.dataset.animation = 'slideInRight';

            setTimeout(function() {
                html.removeAttribute('data-animation');
            }, 1000);
        },
        openCatalog() {
            console.log('Foo bar')
        }
    },
    template:
    `<transition name="slide-right" enter-active-class='is-animated slideInLeft' leave-active-class='is-animated slideOutLeft'>
        <div v-if="m_mobilePanelShow" class="mobile-panel" style="z-index: 200;">
            <div class="mobile-panel__content">
                <div class="mobile-panel__close" @click="close()">
                    <svg width="13" height="13" class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg>
                </div>
                
                <slot name="body"></slot>
            </div>
        </div>
    </transition>`,
});