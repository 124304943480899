<template>
  <div class="new-feedback">
    <div class="success-display" v-if="success">
      <h2>Ваше обращение принято!</h2>

      <h3>Наши менеджеры свяжутся с Вами в ближайшее время</h3>
    </div>
    <div class="new-feedback__container" v-else>
      <div class="page-header page-header_single page-header_min feedback-header__container">
        <div class="page-header__container custom-container">
          <h1 class="page-header__heading">Обратная связь</h1>
        </div>
      </div>
      <div class="custom-container">
        <p class="feedback-message">
          Введите интересующий Вас вопрос, отзыв или предложение<br/>
          Операторы интернет-магазина свяжутся с Вами по будним дням с 10:00 до 19:00
        </p>
      </div>
      <div class="custom-container">
        <div class="feedback__form">
          <div class="feedback__form-fieldset">
            <div class="feedback__form-fieldset-message">
              <div class="form-field">
                <label class="custom-label">Сообщение</label>
                <textarea name="" class="custom-textarea theme-default" v-model="feedback.message"></textarea>
                <span v-for="error in errors.message" style="color: red;">{{error}}</span>
              </div>
            </div>
            <div class="feedback__form-fieldset-info">
              <div class="form-field">
                <label class="custom-label">Контактный телефон</label>
                <input type="tel" class="custom-input theme-default" v-model='feedback.phone' placeholder="+7">
                <span v-for="error in errors.phone" style="color: red;">{{error}}</span>
              </div>

              <div class="form-field">
                <label class="custom-label">Ваш e-mail</label>
                <input type="tel" class="custom-input theme-default" v-model='feedback.email'>
                <span v-for="error in errors.email" style="color: red;">{{error}}</span>
              </div>

              <div class="form-field">
                <label class="custom-label">Ваше Имя</label>
                <input type="text" class="custom-input theme-default" v-model='feedback.name'>
                <span v-for="error in errors.name" style="color: red;">{{error}}</span>
              </div>
            </div>
          </div>
          <div class="feedback__form-submit">
            <label class="custom-checkbox">
              <input type="checkbox" value="Y" name="RULE_FEEDBACK_QUESTION" class="custom-checkbox__input" v-model='agree'>
              <span class="custom-checkbox__value">
                <span class="custom-checkbox__icon"></span>
                Я подтверждаю, что ознакомился с Пользовательским соглашением, и даю своё согласие на обработку моих персональных данных в соответствии c Федеральным законом "О персональных данных" от 27.07.2006 N 152-ФЗ
              </span>
              <span style="color: red;">{{ agree_error }}</span>
            </label>

            <label class="custom-checkbox">
              <vue-recaptcha 
                sitekey="6LdCUzMcAAAAAFKeD3P-_0tL5YGSvOGf0QOycLH4"
                ref="recaptcha"
                @verify="onVerify"
                @expired="onExpired"></vue-recaptcha>
            </label>

            <button type="button" class="button button_theme_default button_size_ms">
              <span class="hidden-b-m" @click="sendForm">Отправить</span>
              <span class="hidden-a-m" @click="sendForm">Перезвоните мне</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import VueRecaptcha from 'vue-recaptcha';

export default {
  data: function() {
    return {
      feedback: {
        department: 1,
        name: '',
        phone: '',
        email: '',
        message: ''
      },
      captcha_token: '',
      agree: false,
      agree_error: '',
      errors: {},
      success: false
    }
  },
  components: {
    'vue-recaptcha': VueRecaptcha
  },
  methods: {
    sendForm: function() {
      if (!this.agree) { 
        this.agree_error = 'Нужно согласиться на обработку персональных даннных'
        return
      }

      if (this.captcha_token == '') {
        alert('Вы не разгадали капчу')
        return
      }

      axios.post('/feedbacks', {
        feedback: this.feedback,
        captcha_token: this.captcha_token
      }).then((response) => {
        this.success = true
      }).catch((errors) => {
        this.errors = errors.response.data.errors
        this.resetRecaptcha()
      })
    },

    onVerify: function (response) {
      this.captcha_token = response
    },

    onExpired: function () {
      this.captcha_token = ''
    },

    resetRecaptcha () {
      this.$refs.recaptcha.reset() // Direct call reset method
    },
  }
}
</script>