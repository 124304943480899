//import sliderFilterTypes from "../components/sliderFilterTypes";
import Swiper from 'swiper/dist/js/swiper.min.js';
import Vue from 'vue';

Vue.component('slider-filter-types', {
    data() {
        return {
            offerActive: false,
            offerHover: false,
            offerData: false,
            sliderFilterTypesThumbsObj: false,
        };
    },
    mounted() {
        this.initSlider();
    },
    methods: {
        render() {

        },
        hoverOffer(pic) {
            this.offerHover = pic;

            $('.js-hover-pic').find('img').attr('src', pic);//.stop(true,true).hide().fadeIn();
        },
        activeOffer(data) {
            this.offerData = data;

            $('.js-hover-pic').find('img').attr('src', data.img);//.stop(true,true).hide().fadeIn();

            this.offerActive = data.img;
        },
        initSlider() {
            let _this = this,
                $sliderFilterTypes = $(_this.$el),
                $sliderMain = $sliderFilterTypes.find('[data-slider="main"]'),
                $sliderMainContainer = $sliderMain.find('.swiper-container'),
                $sliderThumb = $sliderFilterTypes.find('[data-slider="thumbs"]'),
                $sliderThumbContainer = $sliderThumb.find('.swiper-container'),
                $sliderArrowPrev = $sliderThumb.find('.swiper-button-prev'),
                $sliderArrowNext = $sliderThumb.find('.swiper-button-next');

            _this.sliderFilterTypesThumbsObj = new Swiper($sliderThumbContainer, {
                slidesPerView: 'auto',
                spaceBetween: 0,
                freeMode: true,
                freeModeSticky: false,
            });

            const sliderFilterTypesMainObj = new Swiper($sliderMainContainer, {
                slidesPerView: 1,
                spaceBetween: 0,
                speed: 700,
                effect: 'fade',
                simulateTouch: false,
                noSwiping: true,
                thumbs: {
                    swiper: _this.sliderFilterTypesThumbsObj,
                },
                navigation: {
                    nextEl: $sliderArrowNext,
                    prevEl: $sliderArrowPrev,
                },
                watchOverflow: true,
                on: {
                    slideChange() {
                        let activeSlide = _this.sliderFilterTypesThumbsObj.slides[this.activeIndex],
                            data = $(activeSlide).data('obj');

                        _this.activeOffer(data);
                    },
                },
            });

            _this.offerActive = $(_this.sliderFilterTypesThumbsObj.slides[_this.sliderFilterTypesThumbsObj.activeIndex]).find('img').attr('src');
        },
    },
    template:
        `<div class="product-detail-summary__filter-types slider-filter-types js-slider-filter-types">
            <div class="slider-filter-types__current-color">
                Цвет:
                
                <div data-slider="main" class="slider-filter-types__current-color-slider">
                    <div class="slider-filter-types__current-color-slider-container swiper-container">
                        <div class="slider-filter-types__current-color-slider-wrapper swiper-wrapper">
                            <div class="slider-filter-types__current-color-slider-slide swiper-slide">Цвет 1</div>
                            <div class="slider-filter-types__current-color-slider-slide swiper-slide">Цвет 2</div>
                            <div class="slider-filter-types__current-color-slider-slide swiper-slide">Цвет 3</div>
                            <div class="slider-filter-types__current-color-slider-slide swiper-slide">Цвет 4</div>
                            <div class="slider-filter-types__current-color-slider-slide swiper-slide">Цвет 5</div>
                            <div class="slider-filter-types__current-color-slider-slide swiper-slide">Цвет 6</div>
                            <div class="slider-filter-types__current-color-slider-slide swiper-slide">Цвет 7</div>
                        </div>
                    </div>
                </div>
            </div>

            <div data-slider="thumbs" class="slider-filter-types__slider">
                <div class="slider-filter-types__container swiper-container">
                    <div class="slider-filter-types__wrapper swiper-wrapper">
                        <div @mouseenter="hoverOffer('img/contant/446621-800xx800.jpg')" @mouseleave="hoverOffer(offerActive)" 
                             @click='activeOffer({"img": "img/contant/446621-800xx800.jpg", "price": "80 480", "type": "Цвет 1"})' 
                             data-obj='{"img": "img/contant/446621-800xx800.jpg", "price": "80 480", "type": "Цвет 0"}'
                             class="slider-filter-types__slide swiper-slide">
                            <span class="slider-filter-types__slide-pic">
                                <img src="img/contant/446621-800xx800.jpg" alt=""/>
                            </span>
                        </div>
                        <div @mouseenter="hoverOffer('img/contant/446623-800xx800.jpg')" @mouseleave="hoverOffer(offerActive)" 
                             @click='activeOffer({"img": "img/contant/446623-800xx800.jpg", "price": "80 481", "type": "Цвет 2"})' 
                             data-obj='{"img": "img/contant/446623-800xx800.jpg", "price": "80 481", "type": "Цвет 2"}'
                             class="slider-filter-types__slide swiper-slide">
                            <span class="slider-filter-types__slide-pic">
                                <img src="img/contant/446623-800xx800.jpg" alt=""/>
                            </span>
                        </div>
                        <div @mouseenter="hoverOffer('img/contant/446625-800xx800.jpg')" @mouseleave="hoverOffer(offerActive)" 
                             @click='activeOffer({"img": "img/contant/446625-800xx800.jpg", "price": "80 482", "type": "Цвет 3"})' 
                             data-obj='{"img": "img/contant/446625-800xx800.jpg", "price": "80 482", "type": "Цвет 3"}'
                             class="slider-filter-types__slide swiper-slide">
                            <span class="slider-filter-types__slide-pic">
                                <img src="img/contant/446625-800xx800.jpg" alt=""/>
                            </span>
                        </div>
                        <div @mouseenter="hoverOffer('img/contant/446627-800xx800.jpg')" @mouseleave="hoverOffer(offerActive)" 
                             @click='activeOffer({"img": "img/contant/446627-800xx800.jpg", "price": "80 483", "type": "Цвет 4"})' 
                             data-obj='{"img": "img/contant/446627-800xx800.jpg", "price": "80 483", "type": "Цвет 4"}'
                             class="slider-filter-types__slide swiper-slide">
                            <span class="slider-filter-types__slide-pic">
                                <img src="img/contant/446627-800xx800.jpg" alt=""/>
                            </span>
                        </div>
                        <div @mouseenter="hoverOffer('img/contant/446629-800xx800.jpg')" @mouseleave="hoverOffer(offerActive)" 
                             @click='activeOffer({"img": "img/contant/446629-800xx800.jpg", "price": "80 484", "type": "Цвет 5"})' 
                             data-obj='{"img": "img/contant/446629-800xx800.jpg", "price": "80 484", "type": "Цвет 5"}'
                             class="slider-filter-types__slide swiper-slide">
                            <span class="slider-filter-types__slide-pic">
                                <img src="img/contant/446629-800xx800.jpg" alt=""/>
                            </span>
                        </div>
                        <div @mouseenter="hoverOffer('img/contant/446631-800xx800.jpg')" @mouseleave="hoverOffer(offerActive)" 
                             @click='activeOffer({"img": "img/contant/446631-800xx800.jpg", "price": "80 485", "type": "Цвет 6"})' 
                             data-obj='{"img": "img/contant/446631-800xx800.jpg", "price": "80 485", "type": "Цвет 6"}'
                             class="slider-filter-types__slide swiper-slide">
                            <span class="slider-filter-types__slide-pic">
                                <img src="img/contant/446631-800xx800.jpg" alt=""/>
                            </span>
                        </div>
                        <div @mouseenter="hoverOffer('img/contant/446633-800xx800.jpg')" @mouseleave="hoverOffer(offerActive)" 
                             @click='activeOffer({"img": "img/contant/446633-800xx800.jpg", "price": "80 486", "type": "Цвет 7"})' 
                             data-obj='{"img": "img/contant/446633-800xx800.jpg", "price": "80 486", "type": "Цвет 7"}'
                             class="slider-filter-types__slide swiper-slide">
                            <span class="slider-filter-types__slide-pic">
                                <img src="img/contant/446633-800xx800.jpg" alt=""/>
                            </span>
                        </div>
                    </div>
                </div>

                <div class="slider-filter-types__arrow slider-filter-types__arrow_prev swiper-button-min swiper-button-prev"></div>
                <div class="slider-filter-types__arrow slider-filter-types__arrow_next swiper-button-min swiper-button-next"></div>
            </div>
        </div>`,
});