function scrollAnimated() {
    let $scrollAnimatedBox = $('[data-scroll="animated"]');

    function scrollAnimate() {
        if($scrollAnimatedBox.length) {
            $scrollAnimatedBox.each(function(i, box) {
                let $scrollSection = $(box),
                    $banner = $scrollSection.find('[data-animated-banner]'),
                    scrollTop = window.scrollY,
                    windowHeight = window.innerHeight,
                    sectionBottom = box.offsetTop + box.offsetHeight;

                if(window.matchMedia('min-width: 1440px')) {
                    sectionBottom = box.offsetTop + box.offsetHeight / 2;
                }

                if(scrollTop + windowHeight >= sectionBottom) {
                    $banner.each(function(i, item){
                        $(item).addClass('animated');
                    });
                }
            });
        }
    }

    document.addEventListener("DOMContentLoaded", function () {
        scrollAnimate();
    });

    window.addEventListener('resize', function() {
        scrollAnimate();
    });

    window.addEventListener('scroll', function() {
        scrollAnimate();
    });
}

export default scrollAnimated;
