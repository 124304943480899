import ymaps from 'ymaps';
import shopSelectService from '../services/catalog/shop_select_service';

const SHOPS = gon.shops


function parseCityCoordinates() {
    let coordinates = gon.city.coordinates.split(', ').map(function(c) {
        return parseFloat(c)
    })

    return coordinates
}

let mapResolution;


// const getMapSettings = () => window.innerWidth < 768 ? [[60, 30.35], 9] : [[59.95, 30.3], 11];

function finder() {
    shopSelectService()
    const mapHolder = document.querySelector('.finder__map');

    if (!mapHolder) {
        return;
    }

    const getMapResolution = () => window.innerWidth < 768 ? 'mobile' : 'desktop';
    const getMapSettings = () => window.innerWidth < 768 ? [parseCityCoordinates(), gon.zoom ? gon.zoom : 9] : [parseCityCoordinates(), gon.zoom ? gon.zoom : 11];

    ymaps.load('https://api-maps.yandex.ru/2.1/?lang=ru_RU').then(maps => {
        mapResolution = getMapResolution();

        const [center, zoom] = getMapSettings();
        const map = new maps.Map(mapHolder, { center, zoom, controls: [] });

        const balloonLayout = maps.templateLayoutFactory.createClass(`
            <div class="finder__balloon">
                <img src="{{ properties.photo }}" width="264" height="198" alt="" />
                <div class="finder__balloon-info">
                    <div class="finder__balloon-name">
                        {{ properties.name }}
                        <br />
                        {{ properties.address }}
                    </div>
                    <div class="finder__balloon-metro">
                        <div class="finder__balloon-metro-line" style="background-color: {{ properties.metroLine }};"></div>
                        <div class="finder__balloon-metro-station">{{ properties.metroStation }}</div>
                    </div>
                    <div class="finder__balloon-working">
                        <span class="finder__balloon-working-hours">{{ properties.hours }}</span>
                        <span class="finder__balloon-working-days">{{ properties.days }}</span>
                    </div>
                    <div class="finder__balloon-phone">
                        <a href="{{ properties.company_link }}">
                            {{ properties.company_name }}
                        </a>
                    </div>
                    <div class="finder__balloon-phone">{{ properties.phone }}</div>
                </div>
            </div>
        `, {
            build: function () {
                    this.constructor.superclass.build.call(this);
                    this._$element = $('.finder__balloon', this.getParentElement());
                },
                getShape: function () {
                    if (!this._isElement(this._$element)) {
                        return MyBalloonLayout.superclass.getShape.call(this);
                    }

                    var position = this._$element.position();

                    return new maps.shape.Rectangle(new maps.geometry.pixel.Rectangle([
                        [position.left, position.top], [
                            position.left + this._$element[0].offsetWidth,
                            position.top + this._$element[0].offsetHeight
                        ]
                    ]));
                },
                _isElement: function (element) {
                    return element && element[0];
                },
            },
        );

        const balloonPanelLayout = maps.templateLayoutFactory.createClass(`
            <div class="finder__shop">
              <div class="finder__shop-main">
                <div class="finder__shop-main-address">{{ properties.name }}<br><span>{{ properties.address }}</span></div>
                <div class="finder__shop-main-metro">
                  <div class="finder__shop-main-metro-line" style="background: {{ properties.metroLine }};"></div>
                  <div class="finder__shop-main-metro-station">{{ properties.metroStation }}</div>
                </div>
              </div>
              <div class="finder__shop-hours">
                {{ properties.hours }}
                <span>{{ properties.days }}</span>
              </div>
              <div class="finder__shop-phone">
                {{ properties.phone }}
              </div>
              <div class="finder__shop-call">
                <a href="tel:{{ properties.phone }}" class="button button_size_m button_theme_ghost_dark">Позвонить</a>
              </div>
            </div>
        `);

        SHOPS.forEach(({ coords, ...balloon }) => {
            const point = new maps.Placemark(coords, balloon, {
                balloonLayout,
                balloonPanelLayout,
                balloonShadow: false,
                balloonOffset: [-282, 8],
                iconLayout: 'default#image',
                iconImageHref: mapHolder.dataset.placemark,
                iconImageSize: [40, 40],
                iconImageOffset: [-20, -40],
                iconImageClipRect: [[40, 40], [80, 0]],
                hideIconOnBalloonOpen: false,
            });

            point.events
                .add('balloonopen', e => {
                    mapHolder.classList.add('-with-balloon');
                    e.get('target').options.set('iconImageClipRect', [[60, 40], [80, 20]]);
                })
                .add('balloonclose', e => {
                    mapHolder.classList.remove('-with-balloon');
                    e.get('target').options.set('iconImageClipRect', [[40, 40], [80, 0]]);
                });

            map.geoObjects.add(point);
        });

        map.events.add('click', () => map.balloon.close());

        window.addEventListener('resize', () => {
            const nextResolution = getMapResolution();

            if (nextResolution === mapResolution) {
                return;
            }

            mapResolution = nextResolution;
            map.setCenter(...getMapSettings());
        }, false);
    });
}

export default finder;